/* unplugin-vue-components disabled */import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a835dac6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dialog-footer"
};
import { scoreLogQueryPage, scoreLogAdd, queryAllMemUserInfoList, messageQueryAppList } from "@/api/employeeManagement/index";
import { reactive, ref } from "vue";
import { messageQueryPage, messageAdd, queryPlatMerchantByAll, queryShopInfoByAll, staffBindInfoList } from "@/api/functionList/index";
export default {
  __name: 'Integral',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const dialogFormVisible = ref(false);
    const loading = ref(false);
    const formRef = ref();
    const btnLoading = ref(false);
    const total = ref(0);
    const title = ref("新增积分");
    const formType = ref(null);
    const memAppInfoList = ref([]);
    const memUserInfoList = ref([]);
    const ruleForm = reactive({
      appCode: "",
      userCode: "",
      scene: "add",
      score: 1,
      remark: "",
      merchantCode: "",
      shopCode: "",
      remark: ""
    });
    const data = reactive({
      tableData: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        phone: null
      },
      optionsPosition: [],
      merchantCodePosition: [],
      shopCodePosition: [],
      // 表单校验
      ruleFormRule: {
        appCode: [{
          required: true,
          message: "应用名称不能为空",
          trigger: "blur"
        }],
        userCode: [{
          required: true,
          message: "请选择用户名称",
          trigger: "blur"
        }],
        merchantCode: [{
          required: true,
          message: "请选择商户",
          trigger: ["blur"]
        }],
        shopCode: [{
          required: true,
          message: "请选择门店",
          trigger: ["blur"]
        }],
        scene: [{
          required: true,
          message: "积分变动方式不能为空",
          trigger: "blur"
        }],
        score: [{
          required: true,
          message: "积分值不能为空",
          trigger: "blur"
        }]
      },
      variableModeOptions: [{
        value: "add",
        label: "加积分"
      }, {
        value: "decrease",
        label: "减积分"
      }]
    });
    const {
      tableData,
      queryParams,
      ruleFormRule,
      optionsPosition,
      merchantCodePosition,
      shopCodePosition,
      variableModeOptions
    } = toRefs(data);
    const handleAdd = () => {
      formType.value = "add";
      dialogFormVisible.value = true;
      reset();
    };
    // 查询商户列表
    const getMerchantCode = () => {
      queryPlatMerchantByAll({}).then(res => {
        try {
          merchantCodePosition.value = res.data;
        } catch (error) {}
      });
    };
    // 查询门店列表
    const getShopCode = () => {
      ruleForm.shopCode = "";
      queryShopInfoByAll({
        merchantCode: ruleForm.merchantCode
      }).then(res => {
        try {
          shopCodePosition.value = res.data;
        } catch (error) {}
      });
    };
    // 会员消息列表
    const getPeople = () => {
      staffBindInfoList({
        merchantCode: ruleForm.merchantCode,
        shopCode: ruleForm.shopCode
      }).then(res => {
        try {
          optionsPosition.value = res.data.filter(item => item.userInfoVo).map(i => {
            return {
              name: i.userInfoVo.nickName,
              code: i.userInfoVo.code
            };
          });
        } catch (error) {}
      });
    };
    const cancel = () => {
      dialogFormVisible.value = false;
      reset();
    };
    const handleQuery = () => {
      queryParams.value.pageNum = 1;
      getList();
    };
    const submit = async formEl => {
      if (!formEl) return;
      await formEl.validate(valid => {
        if (valid) {
          // 新增
          addUser();
        }
      });
    };

    // 新增积分
    const addUser = () => {
      scoreLogAdd(ruleForm).then(() => {
        dialogFormVisible.value = false;
        getList();
        reset();
        proxy.$modal.msgSuccess("新增成功");
      });
    };
    // 应用list
    const getAppList = () => {
      messageQueryAppList({}).then(res => {
        memAppInfoList.value = res.data;
      });
    };
    // 会员list
    const getUserList = () => {
      queryAllMemUserInfoList({}).then(res => {
        memUserInfoList.value = res.data;
      });
    };

    // 关闭弹窗
    const closeDialog = () => {
      dialogFormVisible.value = false;
      reset();
    };

    // 查询积分列表
    const getList = () => {
      loading.value = true;
      scoreLogQueryPage(queryParams.value).then(res => {
        try {
          tableData.value = res.data;
          total.value = res.total;
        } catch (error) {}
        loading.value = false;
      });
    };

    // 重置表单
    const reset = () => {
      formRef.value?.resetFields();
    };
    getList();
    getAppList();
    getUserList();
    getMerchantCode();
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_tag = _resolveComponent("el-tag");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _component_el_input_number = _resolveComponent("el-input-number");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, {
        gutter: 2
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(queryParams),
          ref: "queryForm",
          inline: true
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "用户名称"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              clearable: "",
              modelValue: _unref(queryParams).userCode,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).userCode = $event),
              placeholder: "请选择"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(memUserInfoList.value, item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.code,
                  label: item.nickName || item.phone,
                  value: item.code
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "应用名称"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              clearable: "",
              modelValue: _unref(queryParams).appCode,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).appCode = $event),
              placeholder: "请选择"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(memAppInfoList.value, item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.key,
                  label: item.value,
                  value: item.key
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: "Search",
              plain: "",
              type: "primary",
              onClick: handleQuery
            }, {
              default: _withCtx(() => [_createTextVNode("搜索")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }), _createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            icon: "Plus",
            plain: "",
            type: "primary",
            onClick: handleAdd
          }, {
            default: _withCtx(() => [_createTextVNode("新增积分")]),
            _: 1
          })]),
          _: 1
        }, 8, ["span"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData),
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          label: "商户名称",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "merchantName"
        }), _createVNode(_component_el_table_column, {
          label: "门店名称",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "shopName"
        }), _createVNode(_component_el_table_column, {
          label: "应用名称",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "appName"
        }), _createVNode(_component_el_table_column, {
          label: "用户名称",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "userName"
        }), _createVNode(_component_el_table_column, {
          label: "积分动作",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "scene"
        }, {
          default: _withCtx(scope => [scope.row.scene == 'decrease' ? (_openBlock(), _createBlock(_component_el_tag, {
            key: 0,
            type: "danger"
          }, {
            default: _withCtx(() => [_createTextVNode("减积分")]),
            _: 1
          })) : (_openBlock(), _createBlock(_component_el_tag, {
            key: 1,
            type: "success"
          }, {
            default: _withCtx(() => [_createTextVNode("加积分")]),
            _: 1
          }))]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "积分",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "score"
        }), _createVNode(_component_el_table_column, {
          label: "备注",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "remark"
        }), _createVNode(_component_el_table_column, {
          label: "变动时间",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "createdTime"
        }, {
          default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.createdTime), 1)]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, loading.value]]), _withDirectives(_createVNode(_component_pagination, {
        total: total.value,
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[2] || (_cache[2] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[3] || (_cache[3] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, total.value > 0]]), _createVNode(_component_el_dialog, {
        modelValue: dialogFormVisible.value,
        "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => dialogFormVisible.value = $event),
        "before-close": closeDialog,
        title: title.value
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          onClick: _cache[11] || (_cache[11] = $event => cancel(formRef.value))
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          loading: btnLoading.value,
          onClick: _cache[12] || (_cache[12] = $event => submit(formRef.value))
        }, {
          default: _withCtx(() => [_createTextVNode("提交")]),
          _: 1
        }, 8, ["loading"])])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "formRef",
          ref: formRef,
          model: ruleForm,
          rules: _unref(ruleFormRule),
          "label-position": "top"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "商户",
            prop: "merchantCode"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: ruleForm.merchantCode,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ruleForm.merchantCode = $event),
              style: {
                "width": "100%"
              },
              class: "m-2",
              placeholder: "请选择商户",
              onChange: getShopCode
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(merchantCodePosition), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.code,
                  label: item.accountName,
                  value: item.code
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "门店",
            prop: "shopCode"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: ruleForm.shopCode,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ruleForm.shopCode = $event),
              class: "m-2",
              placeholder: "请选择门店",
              onChange: getPeople
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(shopCodePosition), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.code,
                  label: item.name,
                  value: item.code
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "用户名称",
            prop: "userCode"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: ruleForm.userCode,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ruleForm.userCode = $event),
              class: "m-2",
              placeholder: "请选择接收人"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(optionsPosition), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.code,
                  label: item.name,
                  value: item.code
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "应用名称",
            prop: "appCode"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              clearable: "",
              modelValue: ruleForm.appCode,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ruleForm.appCode = $event),
              placeholder: "请选择"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(memAppInfoList.value, item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.key,
                  label: item.value,
                  value: item.key
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "积分变动方式",
            prop: "scene"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: ruleForm.scene,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ruleForm.scene = $event),
              placeholder: "请选择"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(variableModeOptions), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.value,
                  label: item.label,
                  value: item.value
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "积分数",
            prop: "score"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input_number, {
              modelValue: ruleForm.score,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ruleForm.score = $event),
              modelModifiers: {
                number: true
              },
              "controls-position": "right",
              min: 1,
              max: 9999
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "备注"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "textarea",
              placeholder: "请输入备注",
              modelValue: ruleForm.remark,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ruleForm.remark = $event),
              "controls-position": "right",
              maxlength: "100"
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};