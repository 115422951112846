/* unplugin-vue-components disabled */import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-777a3331"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "navbar"
};
const _hoisted_2 = {
  class: "right-menu"
};
import { ElMessageBox } from 'element-plus';
import Breadcrumb from '@/components/Breadcrumb';
import TopNav from '@/components/TopNav';
import Hamburger from '@/components/Hamburger';
import Screenfull from '@/components/Screenfull';
import SizeSelect from '@/components/SizeSelect';
import HeaderSearch from '@/components/HeaderSearch';
import RuoYiGit from '@/components/RuoYi/Git';
import RuoYiDoc from '@/components/RuoYi/Doc';
import useAppStore from '@/store/modules/app';
import useUserStore from '@/store/modules/user';
import useSettingsStore from '@/store/modules/settings';
import useTagsViewStore from '@/store/modules/tagsView';
import { SwitchButton, Refresh } from "@element-plus/icons-vue";
export default {
  __name: 'Navbar',
  emits: ['setLayout'],
  setup(__props, {
    emit: emits
  }) {
    const {
      proxy
    } = getCurrentInstance();
    const appStore = useAppStore();
    const userStore = useUserStore();
    const userRouter = useRouter();
    const settingsStore = useSettingsStore();
    const visitedViews = computed(() => useTagsViewStore().visitedViews);
    const refresh = () => {
      console.log(visitedViews);
      console.log(userRouter.currentRoute.value, 'userRouter.fullPath');
      proxy.$tab.refreshPage(userRouter.currentRoute.value);
    };
    function toggleSideBar() {
      appStore.toggleSideBar();
    }
    function handleCommand(command) {
      switch (command) {
        case "setLayout":
          setLayout();
          break;
        case "logout":
          logout();
          break;
        default:
          break;
      }
    }
    function logout() {
      ElMessageBox.confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        userStore.logOut().then(() => {
          location.href = '/index';
        });
      }).catch(() => {});
    }
    function setLayout() {
      emits('setLayout');
    }
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(Hamburger), {
        id: "hamburger-container",
        "is-active": _unref(appStore).sidebar.opened,
        class: "hamburger-container",
        onToggleClick: toggleSideBar
      }, null, 8, ["is-active"]), _unref(settingsStore).topNav ? (_openBlock(), _createBlock(_unref(TopNav), {
        key: 0,
        id: "topmenu-container",
        class: "topmenu-container"
      })) : _createCommentVNode("", true), _createVNode(_component_el_button, {
        link: "",
        type: "primary",
        style: {
          "height": "100%"
        },
        icon: _unref(Refresh),
        onClick: refresh
      }, {
        default: _withCtx(() => [_createTextVNode("刷新")]),
        _: 1
      }, 8, ["icon"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
        link: "",
        type: "primary",
        icon: _unref(SwitchButton),
        onClick: _cache[0] || (_cache[0] = $event => logout())
      }, {
        default: _withCtx(() => [_createTextVNode("退出")]),
        _: 1
      }, 8, ["icon"])])]);
    };
  }
};