import request from "@/utils/request";
// 视频上传
export function UploadVideo(data) {
  return request({
    url: "/api/Common/UploadVideo",
    method: "post",
    data: data,
    headers: {
      repeatSubmit: false,
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  });
}
// 视频list
export function VideoList(data) {
  return request({
    url: "/api/Common/VideoList",
    method: "get",
    params: data,
    headers: {
      repeatSubmit: false,
    },
  });
}
// 视频delete
export function VideoDelete(data) {
  return request({
    url: "/api/Common/VideoDelete",
    method: "post",
    data: data,
    headers: {
      repeatSubmit: false,
    },
  });
}
// TagList
export function TagList(data) {
  return request({
    url: "/api/Common/TagList",
    method: "get",
    params: data,
    headers: {
      repeatSubmit: false,
    },
  });
}
// TagList add
export function AddTag(data) {
  return request({
    url: "/api/Common/AddTag",
    method: "post",
    data: data,
    headers: {
      repeatSubmit: false,
    },
  });
}
