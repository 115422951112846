import request from "@/utils/request";
// 查询宠医宝会员列表
export function queryMemUserInfoPageList(data) {
  return request({
    url: "/yg-plat-manage/user/queryPage",
    method: "post",
    data: data,
    headers: {
      repeatSubmit: false,
    },
  });
}

// 查询宠医宝会员全部列表
export function queryAllMemUserInfoList(data) {
  return request({
    url: "/yg-plat-manage/user/queryList",
    method: "post",
    data: data,
    headers: {
      repeatSubmit: false,
    },
  });
}

// 查询宠医宝单个会员列表
export function findMemUserInfo(data) {
  return request({
    url: "/yg-plat-manage/user/findByPhone",
    method: "get",
    params: data,
    headers: {
      repeatSubmit: false,
    },
  });
}

// 查询门店列表
export function queryShopList() {
  return request({
    url: "/yg-plat-manage/user/queryShopList",
    method: "get",
    headers: {
      repeatSubmit: false,
    },
  });
}

// 新增会员
export function addMemUserInfo(data) {
  return request({
    url: "/yg-plat-manage/user/add",
    method: "post",
    data: data,
    headers: {
      repeatSubmit: true,
    },
  });
}
// 编辑会员
export function updateMemUserInfo(data) {
  return request({
    url: "/yg-plat-manage/user/update",
    method: "post",
    data: data,
  });
}

// 会员重置密码
export function resetMemUserPwd(data) {
  return request({
    url: "/yg-plat-manage/user/resetPwd",
    method: "get",
    params: data,
  });
}

// 删除会员
export function deleteMemUserInfo(data) {
  return request({
    url: "/yg-plat-manage/user/delete",
    method: "post",
    data: data,
  });
}

// 查询平台类型
export function queryAppCategorySourceType() {
  return request({
    url: "/yg-plat-manage/app/queryAppCategorySourceType",
    method: "get",
  });
}

// 积分新增
export function scoreLogAdd(data) {
  return request({
    url: "/yg-plat-manage/scoreLog/add",
    method: "post",
    data: data,
  });
}
// 积分list
export function scoreLogQueryPage(data) {
  return request({
    url: "/yg-plat-manage/scoreLog/queryPage",
    method: "post",
    data: data,
  });
}
//应用管理全部列表
export function queryAllMemAppInfoList(data) {
  return request({
    url: "/yg-plat-manage/app/queryList",
    method: "post",
    data,
    headers: {
      repeatSubmit: false,
    },
  });
}
//积分商品
export function appGoodsInfoQueryPage(data) {
  return request({
    url: "/yg-plat-manage/appGoodsInfo/queryPage",
    method: "post",
    data,
  });
}
//积分商品添加
export function appGoodsInfoAdd(data) {
  return request({
    url: "/yg-plat-manage/appGoodsInfo/add",
    method: "post",
    data,
  });
}
//积分商品修改
export function appGoodsInfoUpdate(data) {
  return request({
    url: "/yg-plat-manage/appGoodsInfo/update",
    method: "post",
    data,
  });
}
//积分商品删除
export function appGoodsInfoDelete(data) {
  return request({
    url: "/yg-plat-manage/appGoodsInfo/delete",
    method: "post",
    data,
  });
}
//积分商品详情
export function appGoodsInfoFind(data) {
  return request({
    url: "/yg-plat-manage/appGoodsInfo/find",
    method: "get",
    params: data,
  });
}
//积分商品兑换记录
export function appGoodsExchangeLog(data) {
  return request({
    url: "/yg-plat-manage/appGoodsExchangeLog/queryPage",
    method: "post",
    data,
  });
}
//积分可添加的应用
export function messageQueryAppList(data) {
  return request({
    url: "/yg-plat-manage/message/queryAppList",
    method: "post",
    data,
  });
}
//应用
export function queryAppList(data) {
  return request({
    url: "/yg-plat-manage/app/queryList",
    method: "post",
    data,
  });
}
//奖章list
export function medalConfigList(data) {
  return request({
    url: "/yg-plat-manage/medalConfig/queryPage",
    method: "post",
    data,
  });
}
//奖章add
export function medalConfigAdd(data) {
  return request({
    url: "/yg-plat-manage/medalConfig/add",
    method: "post",
    data,
  });
}
//奖章update
export function medalConfigUpdata(data) {
  return request({
    url: "/yg-plat-manage/medalConfig/update",
    method: "post",
    data,
  });
}
//奖章delete
export function medalConfigDelete(data) {
  return request({
    url: "/yg-plat-manage/medalConfig/delete",
    method: "post",
    data,
  });
}
//奖章find
export function medalConfigFind(data) {
  return request({
    url: "/yg-plat-manage/medalConfig/find",
    method: "get",
    params: data,
  });
}
//奖章全部queryList
export function medalConfigQueryList(data) {
  return request({
    url: "/yg-plat-manage/medalConfig/queryList",
    method: "post",
    data,
  });
}
//奖章获取记录
export function medalLogQueryList(data) {
  return request({
    url: "/yg-plat-manage/medalLog/queryList",
    method: "post",
    data,
  });
}
//收入分类list
export function appIncomeCategoryInfoList(data) {
  return request({
    url: "/yg-plat-manage/appIncomeCategoryInfo/queryPage",
    method: "post",
    data,
  });
}
//收入分类add
export function appIncomeCategoryInfoAdd(data) {
  return request({
    url: "/yg-plat-manage/appIncomeCategoryInfo/add",
    method: "post",
    data,
  });
}
//收入分类update
export function appIncomeCategoryInfoUpdata(data) {
  return request({
    url: "/yg-plat-manage/appIncomeCategoryInfo/update",
    method: "post",
    data,
  });
}
//收入分类delete
export function appIncomeCategoryInfoDelete(data) {
  return request({
    url: "/yg-plat-manage/appIncomeCategoryInfo/delete",
    method: "post",
    data,
  });
}
//收入分类find
export function appIncomeCategoryInfoFind(data) {
  return request({
    url: "/yg-plat-manage/appIncomeCategoryInfo/findByCode",
    method: "get",
    params: data,
  });
}
//岗位配置list
export function appPositionInfoList(data) {
  return request({
    url: "/yg-plat-manage/appPositionInfo/queryPage",
    method: "post",
    data,
  });
}
//岗位配置add
export function appPositionInfoAdd(data) {
  return request({
    url: "/yg-plat-manage/appPositionInfo/add",
    method: "post",
    data,
  });
}
//岗位配置update
export function appPositionInfoUpdata(data) {
  return request({
    url: "/yg-plat-manage/appPositionInfo/update",
    method: "post",
    data,
  });
}
//岗位配置delete
export function appPositionInfoDelete(data) {
  return request({
    url: "/yg-plat-manage/appPositionInfo/delete",
    method: "post",
    data,
  });
}
//岗位配置find
export function appPositionInfoFind(data) {
  return request({
    url: "/yg-plat-manage/appPositionInfo/findByCode",
    method: "get",
    params: data,
  });
}
//会员标签list
export function userTagList(data) {
  return request({
    url: "/yg-plat-manage/userTag/queryPage",
    method: "post",
    data,
  });
}
//会员标签add
export function userTagAdd(data) {
  return request({
    url: "/yg-plat-manage/userTag/add",
    method: "post",
    data,
  });
}
//会员标签update
export function userTagUpdata(data) {
  return request({
    url: "/yg-plat-manage/userTag/update",
    method: "post",
    data,
  });
}
//会员标签delete
export function userTagDelete(data) {
  return request({
    url: "/yg-plat-manage/userTag/delete",
    method: "post",
    data,
  });
}
//会员标签find
export function userTagFind(data) {
  return request({
    url: "/yg-plat-manage/userTag/find",
    method: "get",
    params: data,
  });
}
//会员标签全部queryList
export function userTagQueryList(data) {
  return request({
    url: "/yg-plat-manage/userTag/queryList",
    method: "post",
    data,
  });
}
const baseURL = "http://192.168.6.180:20100/";
