/* unplugin-vue-components disabled */import __unplugin_components_1 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/RightToolbar/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, isRef as _isRef, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "app-container"
};
import { listTable, previewTable, delTable, genCode, synchDb } from "@/api/tool/gen";
import router from "@/router";
import importTable from "./importTable";
export default {
  __name: 'index',
  setup(__props) {
    const route = useRoute();
    const {
      proxy
    } = getCurrentInstance();
    const tableList = ref([]);
    const loading = ref(true);
    const showSearch = ref(true);
    const ids = ref([]);
    const single = ref(true);
    const multiple = ref(true);
    const total = ref(0);
    const tableNames = ref([]);
    const dateRange = ref([]);
    const uniqueId = ref("");
    const data = reactive({
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        tableName: undefined,
        tableComment: undefined
      },
      preview: {
        open: false,
        title: "代码预览",
        data: {},
        activeName: "domain.java"
      }
    });
    const {
      queryParams,
      preview
    } = toRefs(data);
    onActivated(() => {
      const time = route.query.t;
      if (time != null && time != uniqueId.value) {
        uniqueId.value = time;
        queryParams.value.pageNum = Number(route.query.pageNum);
        dateRange.value = [];
        proxy.resetForm("queryForm");
        getList();
      }
    });

    /** 查询表集合 */
    function getList() {
      loading.value = true;
      listTable(proxy.addDateRange(queryParams.value, dateRange.value)).then(response => {
        tableList.value = response.rows;
        total.value = response.total;
        loading.value = false;
      });
    }
    /** 搜索按钮操作 */
    function handleQuery() {
      queryParams.value.pageNum = 1;
      getList();
    }
    /** 生成代码操作 */
    function handleGenTable(row) {
      const tbNames = row.tableName || tableNames.value;
      if (tbNames == "") {
        proxy.$modal.msgError("请选择要生成的数据");
        return;
      }
      if (row.genType === "1") {
        genCode(row.tableName).then(response => {
          proxy.$modal.msgSuccess("成功生成到自定义路径：" + row.genPath);
        });
      } else {
        proxy.$download.zip("/tool/gen/batchGenCode?tables=" + tbNames, "ruoyi");
      }
    }
    /** 同步数据库操作 */
    function handleSynchDb(row) {
      const tableName = row.tableName;
      proxy.$modal.confirm('确认要强制同步"' + tableName + '"表结构吗？').then(function () {
        return synchDb(tableName);
      }).then(() => {
        proxy.$modal.msgSuccess("同步成功");
      }).catch(() => {});
    }
    /** 打开导入表弹窗 */
    function openImportTable() {
      proxy.$refs["importRef"].show();
    }
    /** 重置按钮操作 */
    function resetQuery() {
      dateRange.value = [];
      proxy.resetForm("queryRef");
      handleQuery();
    }
    /** 预览按钮 */
    function handlePreview(row) {
      previewTable(row.tableId).then(response => {
        preview.value.data = response.data;
        preview.value.open = true;
        preview.value.activeName = "domain.java";
      });
    }
    /** 复制代码成功 */
    function copyTextSuccess() {
      proxy.$modal.msgSuccess("复制成功");
    }
    // 多选框选中数据
    function handleSelectionChange(selection) {
      ids.value = selection.map(item => item.tableId);
      tableNames.value = selection.map(item => item.tableName);
      single.value = selection.length != 1;
      multiple.value = !selection.length;
    }
    /** 修改按钮操作 */
    function handleEditTable(row) {
      const tableId = row.tableId || ids.value[0];
      router.push({
        path: "/tool/gen-edit/index/" + tableId,
        query: {
          pageNum: queryParams.value.pageNum
        }
      });
    }
    /** 删除按钮操作 */
    function handleDelete(row) {
      const tableIds = row.tableId || ids.value;
      proxy.$modal.confirm('是否确认删除表编号为"' + tableIds + '"的数据项？').then(function () {
        return delTable(tableIds);
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    }
    getList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_date_picker = _resolveComponent("el-date-picker");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_col = _resolveComponent("el-col");
      const _component_right_toolbar = __unplugin_components_0;
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_tooltip = _resolveComponent("el-tooltip");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_1;
      const _component_el_link = _resolveComponent("el-link");
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");
      const _component_el_tabs = _resolveComponent("el-tabs");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_hasPermi = _resolveDirective("hasPermi");
      const _directive_loading = _resolveDirective("loading");
      const _directive_copyText = _resolveDirective("copyText");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_el_form, {
        model: _unref(queryParams),
        ref: "queryRef",
        inline: true,
        "label-width": "68px"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "表名称",
          prop: "tableName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).tableName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).tableName = $event),
            placeholder: "请输入表名称",
            clearable: "",
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "表描述",
          prop: "tableComment"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).tableComment,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).tableComment = $event),
            placeholder: "请输入表描述",
            clearable: "",
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "创建时间",
          style: {
            "width": "308px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_date_picker, {
            modelValue: _unref(dateRange),
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _isRef(dateRange) ? dateRange.value = $event : null),
            "value-format": "YYYY-MM-DD",
            type: "daterange",
            "range-separator": "-",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            icon: "Search",
            onClick: handleQuery
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          }), _createVNode(_component_el_button, {
            icon: "Refresh",
            onClick: resetQuery
          }, {
            default: _withCtx(() => [_createTextVNode("重置")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), [[_vShow, _unref(showSearch)]]), _createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            plain: "",
            icon: "Download",
            onClick: handleGenTable
          }, {
            default: _withCtx(() => [_createTextVNode("生成")]),
            _: 1
          })), [[_directive_hasPermi, ['tool:gen:code']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "info",
            plain: "",
            icon: "Upload",
            onClick: openImportTable
          }, {
            default: _withCtx(() => [_createTextVNode("导入")]),
            _: 1
          })), [[_directive_hasPermi, ['tool:gen:import']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "success",
            plain: "",
            icon: "Edit",
            disabled: _unref(single),
            onClick: handleEditTable
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 1
          }, 8, ["disabled"])), [[_directive_hasPermi, ['tool:gen:edit']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "danger",
            plain: "",
            icon: "Delete",
            disabled: _unref(multiple),
            onClick: handleDelete
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 1
          }, 8, ["disabled"])), [[_directive_hasPermi, ['tool:gen:remove']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_right_toolbar, {
          showSearch: _unref(showSearch),
          "onUpdate:showSearch": _cache[3] || (_cache[3] = $event => _isRef(showSearch) ? showSearch.value = $event : null),
          onQueryTable: getList
        }, null, 8, ["showSearch"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: _unref(tableList),
        onSelectionChange: handleSelectionChange
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          type: "selection",
          align: "center",
          width: "55"
        }), _createVNode(_component_el_table_column, {
          label: "序号",
          type: "index",
          width: "50",
          align: "center"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString((_unref(queryParams).pageNum - 1) * _unref(queryParams).pageSize + scope.$index + 1), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "表名称",
          align: "center",
          prop: "tableName",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "表描述",
          align: "center",
          prop: "tableComment",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "实体",
          align: "center",
          prop: "className",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "创建时间",
          align: "center",
          prop: "createTime",
          width: "160"
        }), _createVNode(_component_el_table_column, {
          label: "更新时间",
          align: "center",
          prop: "updateTime",
          width: "160"
        }), _createVNode(_component_el_table_column, {
          label: "操作",
          align: "center",
          width: "330",
          "class-name": "small-padding fixed-width"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_tooltip, {
            content: "预览",
            placement: "top"
          }, {
            default: _withCtx(() => [_withDirectives(_createVNode(_component_el_button, {
              type: "primary",
              link: "",
              icon: "View",
              onClick: $event => handlePreview(scope.row)
            }, null, 8, ["onClick"]), [[_directive_hasPermi, ['tool:gen:preview']]])]),
            _: 2
          }, 1024), _createVNode(_component_el_tooltip, {
            content: "编辑",
            placement: "top"
          }, {
            default: _withCtx(() => [_withDirectives(_createVNode(_component_el_button, {
              type: "primary",
              link: "",
              icon: "Edit",
              onClick: $event => handleEditTable(scope.row)
            }, null, 8, ["onClick"]), [[_directive_hasPermi, ['tool:gen:edit']]])]),
            _: 2
          }, 1024), _createVNode(_component_el_tooltip, {
            content: "删除",
            placement: "top"
          }, {
            default: _withCtx(() => [_withDirectives(_createVNode(_component_el_button, {
              type: "primary",
              link: "",
              icon: "Delete",
              onClick: $event => handleDelete(scope.row)
            }, null, 8, ["onClick"]), [[_directive_hasPermi, ['tool:gen:remove']]])]),
            _: 2
          }, 1024), _createVNode(_component_el_tooltip, {
            content: "同步",
            placement: "top"
          }, {
            default: _withCtx(() => [_withDirectives(_createVNode(_component_el_button, {
              type: "primary",
              link: "",
              icon: "Refresh",
              onClick: $event => handleSynchDb(scope.row)
            }, null, 8, ["onClick"]), [[_directive_hasPermi, ['tool:gen:edit']]])]),
            _: 2
          }, 1024), _createVNode(_component_el_tooltip, {
            content: "生成代码",
            placement: "top"
          }, {
            default: _withCtx(() => [_withDirectives(_createVNode(_component_el_button, {
              type: "primary",
              link: "",
              icon: "Download",
              onClick: $event => handleGenTable(scope.row)
            }, null, 8, ["onClick"]), [[_directive_hasPermi, ['tool:gen:code']]])]),
            _: 2
          }, 1024)]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[4] || (_cache[4] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[5] || (_cache[5] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_component_el_dialog, {
        title: _unref(preview).title,
        modelValue: _unref(preview).open,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _unref(preview).open = $event),
        width: "80%",
        top: "5vh",
        "append-to-body": "",
        "custom-class": "scrollbar"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tabs, {
          modelValue: _unref(preview).activeName,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _unref(preview).activeName = $event)
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(preview).data, (value, key) => {
            return _openBlock(), _createBlock(_component_el_tab_pane, {
              label: key.substring(key.lastIndexOf('/') + 1, key.indexOf('.vm')),
              name: key.substring(key.lastIndexOf('/') + 1, key.indexOf('.vm')),
              key: value
            }, {
              default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_link, {
                underline: false,
                icon: "DocumentCopy",
                style: {
                  "float": "right"
                }
              }, {
                default: _withCtx(() => [_createTextVNode(" 复制")]),
                _: 2
              }, 1024)), [[_directive_copyText, value], [_directive_copyText, copyTextSuccess, "callback"]]), _createElementVNode("pre", null, _toDisplayString(value), 1)]),
              _: 2
            }, 1032, ["label", "name"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }, 8, ["title", "modelValue"]), _createVNode(_unref(importTable), {
        ref: "importRef",
        onOk: handleQuery
      }, null, 512)]);
    };
  }
};