/* unplugin-vue-components disabled */import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8944f626"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "job"
};
import { appRecruitDetail } from "@/api/job/index";
import { reactive, ref } from "vue";
import { queryPlatMerchantByAll, queryShopInfoByAll } from "@/api/functionList/index";
export default {
  __name: 'index',
  setup(__props) {
    const loading = ref(false);
    const total = ref(0);
    const data = reactive({
      tableData: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        merchantCode: null,
        shopCode: null
      },
      merchantCodePosition: [],
      shopCodePosition: []
    });
    const {
      tableData,
      queryParams,
      merchantCodePosition,
      shopCodePosition,
      ruleForm
    } = toRefs(data);

    // 查询商户列表
    const getMerchantCode = () => {
      queryPlatMerchantByAll({}).then(res => {
        try {
          merchantCodePosition.value = res.data;
        } catch (error) {}
      });
    };
    // 查询门店列表
    const getShopCode = () => {
      queryShopInfoByAll({
        merchantCode: queryParams.value.merchantCode
      }).then(res => {
        try {
          shopCodePosition.value = res.data;
        } catch (error) {}
      });
    };
    const changeMerchant = () => {
      getShopCode();
    };
    const handleQuery = () => {
      queryParams.value.pageNum = 1;
      getList();
    };

    // 查询积分列表
    const getList = () => {
      loading.value = true;
      appRecruitDetail(queryParams.value).then(res => {
        try {
          tableData.value = res.data;
          total.value = res.total;
        } catch (error) {}
        loading.value = false;
      });
    };
    getList();
    getMerchantCode();
    getShopCode();
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
        gutter: 2
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(queryParams),
          ref: "queryForm",
          inline: true
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "商户名称"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              clearable: "",
              modelValue: _unref(queryParams).merchantCode,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).merchantCode = $event),
              placeholder: "请选择",
              filterable: "",
              onChange: changeMerchant
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(merchantCodePosition), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.code,
                  label: item.accountName,
                  value: item.code
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "门店名称"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              clearable: "",
              modelValue: _unref(queryParams).shopCode,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).shopCode = $event),
              placeholder: "请选择",
              filterable: ""
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(shopCodePosition), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.code,
                  label: item.name,
                  value: item.code
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: "Search",
              plain: "",
              type: "primary",
              onClick: handleQuery
            }, {
              default: _withCtx(() => [_createTextVNode("搜索")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData),
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          label: "商户名称",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "merchantName"
        }), _createVNode(_component_el_table_column, {
          label: "门店名称",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "shopName"
        }), _createVNode(_component_el_table_column, {
          label: "医院名称",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "hospitalName"
        }), _createVNode(_component_el_table_column, {
          label: "需求部门",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "departmentName"
        }), _createVNode(_component_el_table_column, {
          label: "需求岗位",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "postName"
        }), _createVNode(_component_el_table_column, {
          label: "需求人数",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "recruitNum"
        }), _createVNode(_component_el_table_column, {
          label: "申请增补理由",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "reasonForApplication"
        }), _createVNode(_component_el_table_column, {
          label: "希望到岗日期",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "expectedOnboardingTime"
        }), _createVNode(_component_el_table_column, {
          label: "岗位所需资格条件",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "userName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            label: "性别要求",
            align: "center",
            "show-overflow-tooltip": "",
            prop: "userName"
          }, {
            default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.gender == "male" ? "男" : "女"), 1)]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "年龄要求",
            align: "center",
            "show-overflow-tooltip": "",
            prop: "age"
          }), _createVNode(_component_el_table_column, {
            label: "专业知识要求",
            align: "center",
            "show-overflow-tooltip": "",
            prop: "expertiseDesc"
          }), _createVNode(_component_el_table_column, {
            label: "工作经验要求",
            align: "center",
            "show-overflow-tooltip": "",
            prop: "workExperienceDesc"
          }), _createVNode(_component_el_table_column, {
            label: "所需岗位职责说明",
            align: "center",
            "show-overflow-tooltip": "",
            prop: "responsibilityDesc"
          }), _createVNode(_component_el_table_column, {
            label: "试用薪资及转正薪资说明",
            align: "center",
            "show-overflow-tooltip": "",
            prop: "salaryDesc"
          }), _createVNode(_component_el_table_column, {
            label: "工作地点说明",
            align: "center",
            "show-overflow-tooltip": "",
            prop: "address"
          }), _createVNode(_component_el_table_column, {
            label: "其它",
            align: "center",
            "show-overflow-tooltip": "",
            prop: "otherDesc"
          })]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "招聘途径",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "recruitmentType"
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, loading.value]]), _withDirectives(_createVNode(_component_pagination, {
        total: total.value,
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[2] || (_cache[2] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[3] || (_cache[3] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, total.value > 0]])]);
    };
  }
};