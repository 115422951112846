import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-210a1e1e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "merchant-container"
};
import merchant from "./components/merchant.vue";
export default {
  __name: 'index',
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(merchant)]);
    };
  }
};