/* unplugin-vue-components disabled */import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, isRef as _isRef } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
import { getSMS, addSMS, editSMS, delSMS } from "@/api/sms/index";
import { deepClone } from "@/utils/index";
export default {
  __name: 'Component1',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const total = ref(0);
    const loading = ref(false);
    const btnLoading = ref(false);
    const queryParams = ref({
      pageNum: 1,
      pageSize: 10
    });
    const dialogFormVisible = ref(false);
    const tableData = ref([]);
    const title = ref("");
    const data = reactive({
      form: {},
      rules: {
        name: [{
          required: true,
          message: "套餐名称不能为空",
          trigger: "blur"
        }],
        num: [{
          required: true,
          message: "充值数量不能为空",
          trigger: "blur"
        }],
        donateNum: [{
          required: true,
          message: "赠送数量不能为空",
          trigger: "blur"
        }],
        amount: [{
          required: true,
          message: "套餐价格不能为空",
          trigger: "blur"
        }]
      }
    });
    const {
      form,
      rules
    } = toRefs(data);
    const hero = reactive({
      name: "zhangsan",
      age: 23,
      family: {
        mather: "dd"
      }
    });
    hero.fullName = computed(() => {
      return hero.name + hero.age;
    });
    const name = toRef(hero, "name");
    const namgChange = () => {
      hero.name = "lisi";
    };
    const handleEdit = row => {
      console.log(row);
      form.value = deepClone(row);
      title.value = "编辑短信套餐";
      dialogFormVisible.value = true;
      console.log("click");
    };
    const handleAdd = () => {
      //   reset();
      title.value = "新增短信套餐";
      dialogFormVisible.value = true;
    };

    // 重置表单
    const reset = () => {
      form.value = {
        name: null,
        num: null,
        donateNum: null,
        amount: null,
        id: null
      };
      proxy.resetForm("userRef");
      // proxy.$refs['form'].resetFields();
    };
    // 取消
    const cancel = () => {
      dialogFormVisible.value = false;
      reset();
    };
    const closeDialog = () => {
      // alert(1)
      dialogFormVisible.value = false;
      reset();
    };
    const submit = () => {
      proxy.$refs['formRef'].validate(valid => {
        if (valid) {
          btnLoading.value = true;
          if (form.value.id != null) {
            editSMS(form.value).then(res => {
              dialogFormVisible.value = false;
              proxy.$modal.msgSuccess("编辑成功");
              reset();
              getList();
              btnLoading.value = false;
            }).catch(err => {
              btnLoading.value = false;
            });
          } else {
            addSMS(form.value).then(res => {
              dialogFormVisible.value = false;
              proxy.$modal.msgSuccess("新增成功");
              reset();
              btnLoading.value = false;
              getList();
            }).catch(err => {
              btnLoading.value = false;
            });
          }
        }
      });
      console.log(form.value, 'form');
    };
    const getList = () => {
      loading.value = true;
      getSMS(queryParams.value).then(res => {
        tableData.value = res.data;
        loading.value = false;
      }).catch(err => {
        loading.value = false;
      });
    };
    const handleDelete = row => {
      const {
        id
      } = row;
      proxy.$modal.confirm('是否确认删除?').then(function () {
        return delSMS({
          id
        });
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    };
    console.log(route.query, "route");
    console.log(router, "router");
    getList();
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            icon: "Plus",
            plain: "",
            type: "primary",
            onClick: handleAdd
          }, {
            default: _withCtx(() => [_createTextVNode("新增")]),
            _: 1
          })]),
          _: 1
        }, 8, ["span"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData),
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "id",
          label: "id",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "name",
          label: "套餐名称",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "num",
          label: "充值数量",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "donateNum",
          label: "赠送数量",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "amount",
          label: "套餐价格",
          width: ""
        }), _createVNode(_component_el_table_column, {
          fixed: "right",
          label: "操作",
          width: "120"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleEdit(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleDelete(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[0] || (_cache[0] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[1] || (_cache[1] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_component_el_dialog, {
        modelValue: _unref(dialogFormVisible),
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _isRef(dialogFormVisible) ? dialogFormVisible.value = $event : null),
        "before-close": closeDialog,
        title: _unref(title)
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          onClick: cancel
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          loading: _unref(btnLoading),
          type: "primary",
          onClick: submit
        }, {
          default: _withCtx(() => [!_unref(btnLoading) ? (_openBlock(), _createElementBlock("span", _hoisted_2, "提交")) : (_openBlock(), _createElementBlock("span", _hoisted_3, "加载中"))]),
          _: 1
        }, 8, ["loading"])])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(form),
          ref: "formRef",
          rules: _unref(rules)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "套餐名称",
            prop: "name"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).name,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(form).name = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "充值数量",
            prop: "num"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).num,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _unref(form).num = $event),
              type: "number",
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "赠送数量",
            prop: "donateNum"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).donateNum,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _unref(form).donateNum = $event),
              type: "number",
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "套餐价格",
            prop: "amount"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).amount,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _unref(form).amount = $event),
              type: "number",
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};