/* unplugin-vue-components disabled */import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4ba4fbc6"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["index"];
export default {
  __name: 'index',
  props: {
    // 数据
    options: {
      type: Array,
      default: null
    },
    // 当前的值
    value: [Number, String, Array]
  },
  setup(__props) {
    const props = __props;
    const values = computed(() => {
      if (props.value !== null && typeof props.value !== 'undefined') {
        return Array.isArray(props.value) ? props.value : [String(props.value)];
      } else {
        return [];
      }
    });
    return (_ctx, _cache) => {
      const _component_el_tag = _resolveComponent("el-tag");
      return _openBlock(), _createElementBlock("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (item, index) => {
        return _openBlock(), _createElementBlock(_Fragment, null, [_unref(values).includes(item.value) ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 0
        }, [item.elTagType == 'default' || item.elTagType == '' ? (_openBlock(), _createElementBlock("span", {
          key: item.value,
          index: index,
          class: _normalizeClass(item.elTagClass)
        }, _toDisplayString(item.label), 11, _hoisted_1)) : (_openBlock(), _createBlock(_component_el_tag, {
          "disable-transitions": true,
          key: item.value + '',
          index: index,
          type: item.elTagType === 'primary' ? '' : item.elTagType,
          class: _normalizeClass(item.elTagClass)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.label), 1)]),
          _: 2
        }, 1032, ["index", "type", "class"]))], 64)) : _createCommentVNode("", true)], 64);
      }), 256))]);
    };
  }
};