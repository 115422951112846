/* unplugin-vue-components disabled */import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow, resolveDynamicComponent as _resolveDynamicComponent, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, isRef as _isRef, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4e04925d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dialog-footer"
};
const _hoisted_2 = {
  class: "dialog-footer"
};
import { queryShopInfoListByPage, noteNumAdjustAdd, noteNumAdjustSub } from "@/api/sms/index";
import Rechargerecord from "./Rechargerecord.vue";
import Sendingrecord from "./Sendingrecord.vue";
import { onMounted } from "vue";
export default {
  __name: 'Component2',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const total = ref(0);
    const loading = ref(false);
    const queryParams = ref({
      pageNum: 1,
      pageSize: 10
    });
    const dialogFormVisible = ref(false);
    const componentName = shallowRef(Rechargerecord);
    const title = ref("");
    const list = ref({});
    const accountVisible = ref(false);
    const accountFormRef = ref();
    const btnLoading = ref(false);
    const tableData = ref([]);
    const data = reactive({
      form: {},
      rules: {
        name1: [{
          required: true,
          message: "套餐名称不能为空",
          trigger: "blur"
        }],
        name2: [{
          required: true,
          message: "套餐名称不能为空",
          trigger: "blur"
        }],
        name3: [{
          required: true,
          message: "套餐名称不能为空",
          trigger: "blur"
        }],
        name4: [{
          required: true,
          message: "套餐名称不能为空",
          trigger: "blur"
        }]
      },
      options: [{
        value: "add",
        label: "增加条数"
      }, {
        value: "del",
        label: "减少条数"
      }],
      accountForm: {
        type: 'add',
        num: null,
        merchantCode: null,
        shopCode: null
      },
      accountFormRules: {
        type: [{
          required: true,
          message: "套餐名称不能为空",
          trigger: "blur"
        }],
        num: [{
          required: true,
          message: "套餐名称不能为空",
          trigger: "blur"
        }]
      }
    });
    const {
      form,
      rules,
      options,
      accountForm,
      accountFormRules
    } = toRefs(data);
    const hero = reactive({
      name: "zhangsan",
      age: 23,
      family: {
        mather: "dd"
      }
    });
    onMounted(() => {
      componentName.value = Rechargerecord;
    });
    hero.fullName = computed(() => {
      return hero.name + hero.age;
    });
    const name = toRef(hero, "name");
    const namgChange = () => {
      hero.name = "lisi";
    };
    const handleQuery = () => {
      queryParams.value.pageNum = 1;
      getList();
    };
    const handleClose = obj => {
      console.log(obj);
      list.value = obj;
    };
    const handleClick = (row, name) => {
      console.log(row);
      const {
        merchantCode,
        code
      } = row;
      console.log("click");
      list.value = {
        merchantCode,
        shopCode: code
      };
      if (name == "recharge") {
        // 充值记录

        title.value = "充值记录";
        componentName.value = Rechargerecord;
      } else {
        title.value = "发送记录";
        componentName.value = Sendingrecord;
      }
      dialogFormVisible.value = true;
    };
    const handleAdd = () => {
      //   reset();
      dialogFormVisible.value = true;
    };

    // 重置表单
    const reset = () => {
      form.value = {
        name1: null,
        name2: null,
        name3: null,
        name4: null
      };
      proxy.resetForm("userRef");
      // proxy.$refs['form'].resetFields();
    };

    // 调账表单重置
    const resetAccountForm = () => {
      accountForm.value = {
        type: 'add',
        num: null,
        merchantCode: null,
        shopCode: null
      };
      proxy.resetForm("accountFormRef");
    };

    // 取消
    const cancel = () => {
      dialogFormVisible.value = false;
      reset();
    };
    const closeFormDialog = () => {
      // alert(1)
      dialogFormVisible.value = false;
      reset();
    };
    const closeAccountDialog = () => {
      accountVisible.value = false;
    };
    const submitForm = async formEl => {
      if (!formEl) return;
      await formEl.validate((valid, fileds) => {
        if (valid) {
          btnLoading.value = true;
          if (accountForm.value.type == 'add') {
            noteNumAdjustAdd(accountForm.value).then(res => {
              proxy.$modal.msgSuccess("调账成功");
              accountVisible.value = false;
              btnLoading.value = false;
              resetAccountForm();
              getList();
            });
          } else {
            noteNumAdjustSub(accountForm.value).then(res => {
              proxy.$modal.msgSuccess("调账成功");
              accountVisible.value = false;
              btnLoading.value = false;
              resetAccountForm();
              getList();
            });
          }
          console.log("submit");
        } else {
          console.log("error submit");
        }
      });
    };
    const resetForm = formEl => {
      if (!formEl) return;
      formEl.resetFields();
      accountVisible.value = false;
    };
    const submit = () => {
      loading.value = true;
      console.log(form.value, "form");
      getSMS(form.value).then(res => {}).catch(err => {
        loading.value = false;
      });
    };
    const getList = () => {
      loading.value = true;
      queryShopInfoListByPage(queryParams.value).then(res => {
        tableData.value = res.data;
        loading.value = false;
        total.value = res.total;
      }).catch(err => {
        loading.value = false;
      });
    };
    console.log(route.query, "route");
    console.log(router, "router");
    // 调账
    const chageAccount = row => {
      const {
        code,
        merchantCode
      } = row;
      accountForm.value.merchantCode = merchantCode;
      accountForm.value.shopCode = code;
      accountVisible.value = true;
    };
    getList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_form, {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
        model: _unref(queryParams),
        ref: "queryRef",
        inline: true
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "商家名称",
          prop: "name"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).name = $event),
            placeholder: "请输入商家名称",
            clearable: "",
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            plain: "",
            icon: "Search",
            onClick: handleQuery
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData),
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "id",
          label: "id",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "name",
          label: "商家名称",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "shortMessageSurplusNum",
          label: "短信余量",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "shortMessageSendNum",
          label: "短信发送量",
          width: ""
        }), _createVNode(_component_el_table_column, {
          fixed: "right",
          align: "center",
          label: "操作",
          width: "120"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            class: "operation",
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleClick(scope.row, 'recharge')
          }, {
            default: _withCtx(() => [_createTextVNode("查看充值记录")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleClick(scope.row, 'sendRecord')
          }, {
            default: _withCtx(() => [_createTextVNode("查看发送记录")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => chageAccount(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("短信余量调账")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[2] || (_cache[2] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[3] || (_cache[3] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_component_el_dialog, {
        width: "80%",
        modelValue: _unref(dialogFormVisible),
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _isRef(dialogFormVisible) ? dialogFormVisible.value = $event : null),
        "before-close": closeFormDialog,
        title: _unref(title)
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          onClick: _cache[4] || (_cache[4] = $event => dialogFormVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_unref(dialogFormVisible) ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(componentName)), {
          key: 0,
          list: _unref(list),
          onHandleClose: handleClose
        }, null, 40, ["list"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["modelValue", "title"]), _createVNode(_component_el_dialog, {
        width: "30%",
        modelValue: _unref(accountVisible),
        "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _isRef(accountVisible) ? accountVisible.value = $event : null),
        "before-close": closeAccountDialog,
        title: _unref(title)
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_2, [_createVNode(_component_el_button, {
          onClick: _cache[8] || (_cache[8] = $event => resetForm(_unref(accountFormRef)))
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          loading: _unref(btnLoading),
          type: "primary",
          onClick: _cache[9] || (_cache[9] = $event => submitForm(_unref(accountFormRef)))
        }, {
          default: _withCtx(() => [_createTextVNode("提交")]),
          _: 1
        }, 8, ["loading"])])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "accountFormRef",
          ref: accountFormRef,
          model: _unref(accountForm),
          rules: _unref(accountFormRules),
          "label-width": "80px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "调账类型",
            prop: "type"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: _unref(accountForm).type,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _unref(accountForm).type = $event),
              placeholder: "调账类型",
              clearable: ""
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(options), dict => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: dict.value,
                  label: dict.label,
                  value: dict.value
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "数量",
            prop: "num"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(accountForm).num,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _unref(accountForm).num = $event),
              placeholder: "请输入数量",
              clearable: "",
              onKeyup: _withKeys(handleQuery, ["enter"])
            }, null, 8, ["modelValue", "onKeyup"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};