/* unplugin-vue-components disabled */import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9ec7e774"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "sms-container"
};
import Component1 from "./components/Component1";
import Component2 from "./components/Component2";
import useUserStore from "@/store/modules/user";
export default {
  __name: 'index',
  setup(__props) {
    // import {} from "@/api/sms"
    const currentPage = getCurrentInstance();
    const userStore = useUserStore();
    const levelList = ref([]);
    const activeName = ref('first');
    const listValue = ref('123');
    console.log(currentPage);
    levelList.value = [{
      name: 123,
      age: 18
    }];
    onBeforeMount(() => {
      console.log('onBeforeMount');
    });
    onMounted(() => {
      console.log('onMounted');
    });
    onMounted(() => {
      console.log('onMounted1');
    });
    onBeforeUpdate(() => {
      console.log('onBeforeUpdate');
    });
    onUpdated(() => {
      console.log('onUpdated');
    });
    onBeforeUnmount(() => {
      console.log('onBeforeUnmount');
    });
    onUnmounted(() => {
      console.log('onUnmounted');
    });
    return (_ctx, _cache) => {
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");
      const _component_el_tabs = _resolveComponent("el-tabs");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_tabs, {
        type: "border-card",
        modelValue: _unref(activeName),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(activeName) ? activeName.value = $event : null)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
          label: "短信充值套餐管理",
          name: "first"
        }, {
          default: _withCtx(() => [_unref(activeName) == 'first' ? (_openBlock(), _createBlock(_unref(Component1), {
            key: 0
          })) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_tab_pane, {
          label: "商家短信管理",
          name: "second"
        }, {
          default: _withCtx(() => [_unref(activeName) == 'second' ? (_openBlock(), _createBlock(_unref(Component2), {
            key: 0
          })) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};