/* unplugin-vue-components disabled */import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export default {
  __name: 'App',
  setup(__props) {
    const isRouterAlive = ref(true);
    const reload = () => {
      console.log(111);
      isRouterAlive.value = false;
      nextTick(() => {
        isRouterAlive.value = true;
      });
    };
    provide("reload", reload);
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _unref(isRouterAlive) ? (_openBlock(), _createBlock(_component_router_view, {
        key: 0
      })) : _createCommentVNode("", true);
    };
  }
};