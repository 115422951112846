import request from "@/utils/request";
// const baseURL = "http://192.168.6.158:20100/yg-plat-manage";
//分类管理新增
export function addMemAppCategoryInfo(data) {
  return request({
    url: "/yg-plat-manage/appCategory/add",
    method: "post",
    data: data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//分类管理修改
export function updateMemAppCategoryInfo(data) {
  return request({
    url: "/yg-plat-manage/appCategory/update",
    method: "post",
    data: data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//分类管理删除
export function deleteMemAppCategoryInfo(data) {
  return request({
    url: "/yg-plat-manage/appCategory/delete",
    method: "post",
    data: data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//分类管理分页列表
export function queryMemAppCategoryInfoPageList(data) {
  return request({
    url: "/yg-plat-manage/appCategory/queryPage",
    method: "post",
    data: data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//分类管理全部列表
export function queryAllMemAppCategoryInfoList(data) {
  return request({
    url: "/yg-plat-manage/appCategory/queryList",
    method: "post",
    data: data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//分类管理列表详情
export function findMemAppCategoryInfo(data) {
  return request({
    url: "/yg-plat-manage/appCategory/find",
    method: "get",
    params: data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//应用管理列表详情
export function findMemAppInfo(data) {
  return request({
    url: "/yg-plat-manage/app/find",
    method: "get",
    params: data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//应用管理新增
export function addMemAppInfo(data) {
  return request({
    url: "/yg-plat-manage/app/add",
    method: "post",
    data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//应用管理修改
export function updateMemAppInfo(data) {
  return request({
    url: "/yg-plat-manage/app/update",
    method: "post",
    data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//应用管理删除
export function deleteMemAppInfo(data) {
  return request({
    url: "/yg-plat-manage/app/delete",
    method: "post",
    data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//应用管理全部列表
export function queryAllMemAppInfoList(data) {
  return request({
    url: "/yg-plat-manage/app/queryList",
    method: "post",
    data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//应用管理分页列表
export function queryMemAppInfoPageList(data) {
  return request({
    url: "/yg-plat-manage/app/queryPage",
    method: "post",
    data,
    headers: {
      repeatSubmit: false,
    },
  });
}
