/* unplugin-vue-components disabled */import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow, createElementVNode as _createElementVNode, isRef as _isRef, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
import { queryPlatOpenInvoiceDetailPageList } from "@/api/invoice/index";
export default {
  __name: 'Invoiced',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const total = ref(0);
    const loading = ref(false);
    const dialogFormVisible = ref(false);
    const title = ref("");
    const data = reactive({
      tableData: [{}],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        status: 'finish_invoiced'
      },
      form: {
        url: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg'
      },
      rules: {}
    });
    const {
      tableData,
      queryParams,
      form,
      rules
    } = toRefs(data);
    const viewDetails = row => {
      const {
        invoiceUrl
      } = row;
      title.value = "发票信息";
      form.value = {
        url: invoiceUrl
      };
      dialogFormVisible.value = true;
    };

    // 查询已开发票列表
    const getList = () => {
      queryPlatOpenInvoiceDetailPageList(queryParams.value).then(res => {
        console.log(res);
        tableData.value = res.data;
        total.value = res.total;
      });
    };

    // 关闭查看发票回调

    const closeDialogForm = () => {
      dialogFormVisible.value = false;
    };
    getList();
    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", null, [_withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData),
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "id",
          label: "id",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "shopName",
          label: "商家名称",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "platInvoiceDetail.headerName",
          label: "发票抬头",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "invoicedAmount",
          label: "开票金额",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "createdTime",
          label: "申请日期",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "openInvoiceTime",
          label: "开票日期",
          width: ""
        }), _createVNode(_component_el_table_column, {
          fixed: "right",
          align: "center",
          label: "操作",
          width: "200"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => viewDetails(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("查看发票")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[0] || (_cache[0] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[1] || (_cache[1] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_component_el_dialog, {
        modelValue: _unref(dialogFormVisible),
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _isRef(dialogFormVisible) ? dialogFormVisible.value = $event : null),
        "before-close": closeDialogForm,
        title: _unref(title)
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          onClick: _cache[2] || (_cache[2] = $event => dialogFormVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(form),
          ref: "formRef",
          rules: _unref(rules),
          "label-width": "96"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "",
            prop: "name1"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_image, {
              style: {
                "width": "100%"
              },
              src: _unref(form).url,
              "zoom-rate": 1.2,
              "preview-src-list": [_unref(form).url],
              fit: "cover"
            }, null, 8, ["src", "zoom-rate", "preview-src-list"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};