/* unplugin-vue-components disabled */import __unplugin_components_2 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import __unplugin_components_1 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/DictTag/index.vue';
import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/RightToolbar/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, isRef as _isRef, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "app-container"
};
const _hoisted_2 = {
  class: "dialog-footer"
};
import { listType, getType, delType, addType, updateType, refreshCache } from "@/api/system/dict/type";
export default {
  __name: 'index',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const {
      sys_normal_disable
    } = proxy.useDict("sys_normal_disable");
    const typeList = ref([]);
    const open = ref(false);
    const loading = ref(true);
    const showSearch = ref(true);
    const ids = ref([]);
    const single = ref(true);
    const multiple = ref(true);
    const total = ref(0);
    const title = ref("");
    const dateRange = ref([]);
    const data = reactive({
      form: {},
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        dictName: undefined,
        dictType: undefined,
        status: undefined
      },
      rules: {
        dictName: [{
          required: true,
          message: "字典名称不能为空",
          trigger: "blur"
        }],
        dictType: [{
          required: true,
          message: "字典类型不能为空",
          trigger: "blur"
        }]
      }
    });
    const {
      queryParams,
      form,
      rules
    } = toRefs(data);

    /** 查询字典类型列表 */
    function getList() {
      loading.value = true;
      listType(proxy.addDateRange(queryParams.value, dateRange.value)).then(response => {
        typeList.value = response.rows;
        total.value = response.total;
        loading.value = false;
      });
    }
    /** 取消按钮 */
    function cancel() {
      open.value = false;
      reset();
    }
    /** 表单重置 */
    function reset() {
      form.value = {
        dictId: undefined,
        dictName: undefined,
        dictType: undefined,
        status: "0",
        remark: undefined
      };
      proxy.resetForm("dictRef");
    }
    /** 搜索按钮操作 */
    function handleQuery() {
      queryParams.value.pageNum = 1;
      getList();
    }
    /** 重置按钮操作 */
    function resetQuery() {
      dateRange.value = [];
      proxy.resetForm("queryRef");
      handleQuery();
    }
    /** 新增按钮操作 */
    function handleAdd() {
      reset();
      open.value = true;
      title.value = "添加字典类型";
    }
    /** 多选框选中数据 */
    function handleSelectionChange(selection) {
      ids.value = selection.map(item => item.dictId);
      single.value = selection.length != 1;
      multiple.value = !selection.length;
    }
    /** 修改按钮操作 */
    function handleUpdate(row) {
      reset();
      const dictId = row.dictId || ids.value;
      getType(dictId).then(response => {
        form.value = response.data;
        open.value = true;
        title.value = "修改字典类型";
      });
    }
    /** 提交按钮 */
    function submitForm() {
      proxy.$refs["dictRef"].validate(valid => {
        if (valid) {
          if (form.value.dictId != undefined) {
            updateType(form.value).then(response => {
              proxy.$modal.msgSuccess("修改成功");
              open.value = false;
              getList();
            });
          } else {
            addType(form.value).then(response => {
              proxy.$modal.msgSuccess("新增成功");
              open.value = false;
              getList();
            });
          }
        }
      });
    }
    /** 删除按钮操作 */
    function handleDelete(row) {
      const dictIds = row.dictId || ids.value;
      proxy.$modal.confirm('是否确认删除字典编号为"' + dictIds + '"的数据项？').then(function () {
        return delType(dictIds);
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    }
    /** 导出按钮操作 */
    function handleExport() {
      proxy.download("system/dict/type/export", {
        ...queryParams.value
      }, `dict_${new Date().getTime()}.xlsx`);
    }
    /** 刷新缓存按钮操作 */
    function handleRefreshCache() {
      refreshCache().then(() => {
        proxy.$modal.msgSuccess("刷新成功");
      });
    }
    getList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_date_picker = _resolveComponent("el-date-picker");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_col = _resolveComponent("el-col");
      const _component_right_toolbar = __unplugin_components_0;
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_router_link = _resolveComponent("router-link");
      const _component_dict_tag = __unplugin_components_1;
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_2;
      const _component_el_radio = _resolveComponent("el-radio");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_hasPermi = _resolveDirective("hasPermi");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_el_form, {
        model: _unref(queryParams),
        ref: "queryRef",
        inline: true,
        "label-width": "68px"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "字典名称",
          prop: "dictName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).dictName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).dictName = $event),
            placeholder: "请输入字典名称",
            clearable: "",
            style: {
              "width": "240px"
            },
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "字典类型",
          prop: "dictType"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).dictType,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).dictType = $event),
            placeholder: "请输入字典类型",
            clearable: "",
            style: {
              "width": "240px"
            },
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "状态",
          prop: "status"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: _unref(queryParams).status,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(queryParams).status = $event),
            placeholder: "字典状态",
            clearable: "",
            style: {
              "width": "240px"
            }
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sys_normal_disable), dict => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: dict.value,
                label: dict.label,
                value: dict.value
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "创建时间",
          style: {
            "width": "308px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_date_picker, {
            modelValue: _unref(dateRange),
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _isRef(dateRange) ? dateRange.value = $event : null),
            "value-format": "YYYY-MM-DD",
            type: "daterange",
            "range-separator": "-",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            icon: "Search",
            onClick: handleQuery
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          }), _createVNode(_component_el_button, {
            icon: "Refresh",
            onClick: resetQuery
          }, {
            default: _withCtx(() => [_createTextVNode("重置")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), [[_vShow, _unref(showSearch)]]), _createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            plain: "",
            icon: "Plus",
            onClick: handleAdd
          }, {
            default: _withCtx(() => [_createTextVNode("新增")]),
            _: 1
          })), [[_directive_hasPermi, ['system:dict:add']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "success",
            plain: "",
            icon: "Edit",
            disabled: _unref(single),
            onClick: handleUpdate
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 1
          }, 8, ["disabled"])), [[_directive_hasPermi, ['system:dict:edit']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "danger",
            plain: "",
            icon: "Delete",
            disabled: _unref(multiple),
            onClick: handleDelete
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 1
          }, 8, ["disabled"])), [[_directive_hasPermi, ['system:dict:remove']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "warning",
            plain: "",
            icon: "Download",
            onClick: handleExport
          }, {
            default: _withCtx(() => [_createTextVNode("导出")]),
            _: 1
          })), [[_directive_hasPermi, ['system:dict:export']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "danger",
            plain: "",
            icon: "Refresh",
            onClick: handleRefreshCache
          }, {
            default: _withCtx(() => [_createTextVNode("刷新缓存")]),
            _: 1
          })), [[_directive_hasPermi, ['system:dict:remove']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_right_toolbar, {
          showSearch: _unref(showSearch),
          "onUpdate:showSearch": _cache[4] || (_cache[4] = $event => _isRef(showSearch) ? showSearch.value = $event : null),
          onQueryTable: getList
        }, null, 8, ["showSearch"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: _unref(typeList),
        onSelectionChange: handleSelectionChange
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          type: "selection",
          width: "55",
          align: "center"
        }), _createVNode(_component_el_table_column, {
          label: "字典编号",
          align: "center",
          prop: "dictId"
        }), _createVNode(_component_el_table_column, {
          label: "字典名称",
          align: "center",
          prop: "dictName",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "字典类型",
          align: "center",
          "show-overflow-tooltip": true
        }, {
          default: _withCtx(scope => [_createVNode(_component_router_link, {
            to: '/system/dict-data/index/' + scope.row.dictId,
            class: "link-type"
          }, {
            default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(scope.row.dictType), 1)]),
            _: 2
          }, 1032, ["to"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "状态",
          align: "center",
          prop: "status"
        }, {
          default: _withCtx(scope => [_createVNode(_component_dict_tag, {
            options: _unref(sys_normal_disable),
            value: scope.row.status
          }, null, 8, ["options", "value"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "备注",
          align: "center",
          prop: "remark",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "创建时间",
          align: "center",
          prop: "createTime",
          width: "180"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(_ctx.parseTime(scope.row.createTime)), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "操作",
          align: "center",
          "class-name": "small-padding fixed-width"
        }, {
          default: _withCtx(scope => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            link: "",
            icon: "Edit",
            onClick: $event => handleUpdate(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 2
          }, 1032, ["onClick"])), [[_directive_hasPermi, ['system:dict:edit']]]), _withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            link: "",
            icon: "Delete",
            onClick: $event => handleDelete(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 2
          }, 1032, ["onClick"])), [[_directive_hasPermi, ['system:dict:remove']]])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[5] || (_cache[5] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[6] || (_cache[6] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_component_el_dialog, {
        title: _unref(title),
        modelValue: _unref(open),
        "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _isRef(open) ? open.value = $event : null),
        width: "500px",
        "append-to-body": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: submitForm
        }, {
          default: _withCtx(() => [_createTextVNode("确 定")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: cancel
        }, {
          default: _withCtx(() => [_createTextVNode("取 消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref: "dictRef",
          model: _unref(form),
          rules: _unref(rules),
          "label-width": "80px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "字典名称",
            prop: "dictName"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).dictName,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _unref(form).dictName = $event),
              placeholder: "请输入字典名称"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "字典类型",
            prop: "dictType"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).dictType,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _unref(form).dictType = $event),
              placeholder: "请输入字典类型"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "状态",
            prop: "status"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_radio_group, {
              modelValue: _unref(form).status,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _unref(form).status = $event)
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sys_normal_disable), dict => {
                return _openBlock(), _createBlock(_component_el_radio, {
                  key: dict.value,
                  label: dict.value
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(dict.label), 1)]),
                  _: 2
                }, 1032, ["label"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "备注",
            prop: "remark"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).remark,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _unref(form).remark = $event),
              type: "textarea",
              placeholder: "请输入内容"
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["title", "modelValue"])]);
    };
  }
};