import { createRouter, createWebHistory } from "vue-router";
/* Layout */
import Layout from "@/layout";

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () => import("@/views/redirect/index.vue"),
      },
    ],
  },
  {
    path: "/login",
    component: () => import("@/views/login"),
    hidden: true,
  },
  {
    path: "/register",
    component: () => import("@/views/register"),
    hidden: true,
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/error/404"),
    hidden: true,
  },
  {
    path: "/401",
    component: () => import("@/views/error/401"),
    hidden: true,
    meta: { title: "404", icon: "job" },
  },
  {
    path: "",
    component: Layout,
    // redirect: "/index",
    redirect: "./sms/smsManagement",
    hidden: true,
    children: [
      {
        path: "/index",
        component: () => import("@/views/index.vue"),
        name: "Index",
        meta: { title: "首页", icon: "dashboard", affix: true },
      },
    ],
  },
  {
    path: "/sms",
    component: Layout,
    redirect: "./sms/smsManagement",
    children: [
      {
        path: "smsManagement",
        component: () => import("@/views/sms/index.vue"),
        name: "Sms",
        meta: { title: "短信管理", icon: "dashboard" },
      },
    ],
  },
  {
    path: "/charge",
    component: Layout,
    redirect: "./charge/chargeManagement",
    children: [
      {
        path: "chargeManagement",
        component: () => import("@/views/charge/index.vue"),
        name: "ChargeManagement",
        meta: { title: "收费管理", icon: "money" },
      },
    ],
  },
  {
    path: "/invoice",
    component: Layout,
    redirect: "./invoice/invoiceManagement",
    children: [
      {
        path: "invoiceManagement",
        component: () => import("@/views/invoice/index.vue"),
        name: "InvoiceManagement",
        meta: { title: "发票管理", icon: "log" },
      },
    ],
  },
  {
    path: "/versionManagemen",
    component: Layout,
    redirect: "./versionManagemen/management",
    children: [
      {
        path: "management",
        component: () => import("@/views/versionManagemen/index.vue"),
        name: "versionManagemen",
        meta: { title: "版本管理", icon: "job" },
      },
    ],
  },
  {
    path: "/employeeManagement",
    component: Layout,
    redirect: "./employeeManagement/employee",
    children: [
      {
        path: "employee",
        component: () => import("@/views/employeeManagement/index.vue"),
        name: "employeeManagement",
        meta: { title: "从业者管理", icon: "user" },
      },
    ],
  },
  {
    path: "/applicationManagement",
    component: Layout,
    redirect: "./applicationManagement/application",
    children: [
      {
        path: "application",
        component: () => import("@/views/applicationManagement/index.vue"),
        name: "applicationManagement",
        meta: { title: "应用管理", icon: "application" },
      },
    ],
  },
  {
    path: "/functionList",
    component: Layout,
    redirect: "./functionList/functionList",
    children: [
      {
        path: "functionList",
        component: () => import("@/views/functionList/index.vue"),
        name: "functionList",
        meta: { title: "功能列表", icon: "functionList" },
      },
    ],
  },
  // {
  //   path: "/video",
  //   component: Layout,
  //   redirect: "./video/application",
  //   children: [
  //     {
  //       path: "video",
  //       component: () => import("@/views/video/index.vue"),
  //       name: "video",
  //       meta: { title: "视频列表", icon: "functionList" },
  //     },
  //   ],
  // },
  {
    path: "/menuList",
    component: Layout,
    redirect: "./menuList/menu",
    children: [
      {
        path: "menu",
        component: () => import("@/views/system/menu/index.vue"),
        name: "menu",
        meta: { title: "门店菜单", icon: "system" },
      },
    ],
  },
  {
    path: "/job",
    component: Layout,
    redirect: "./job/job",
    children: [
      {
        path: "job",
        component: () => import("@/views/job/index.vue"),
        name: "job",
        meta: { title: "企业招聘", icon: "job" },
      },
    ],
  },
  {
    path: "/merchantManagement",
    component: Layout,
    redirect: "./merchantManagement",
    children: [
      {
        path: "merchantManagement",
        component: () => import("@/views/merchantManagement/index.vue"),
        name: "merchantManagement",
        meta: { title: "商户管理", icon: "system" },
      },
    ],
  },
  // {
  //   path: "/user",
  //   component: Layout,
  //   hidden: false,
  //   redirect: "noredirect",
  //   children: [
  //     {
  //       path: "profile",
  //       component: () => import("@/views/system/user/profile/index"),
  //       name: "Profile",
  //       meta: { title: "个人中心", icon: "user" },
  //     }
  //   ],
  // },
];

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = [
  {
    path: "/system/user-auth",
    component: Layout,
    hidden: true,
    permissions: ["system:user:edit"],
    children: [
      {
        path: "role/:userId(\\d+)",
        component: () => import("@/views/system/user/authRole"),
        name: "AuthRole",
        meta: { title: "分配角色", activeMenu: "/system/user" },
      },
    ],
  },
  {
    path: "/system/role-auth",
    component: Layout,
    hidden: true,
    permissions: ["system:role:edit"],
    children: [
      {
        path: "user/:roleId(\\d+)",
        component: () => import("@/views/system/role/authUser"),
        name: "AuthUser",
        meta: { title: "分配用户", activeMenu: "/system/role" },
      },
    ],
  },
  {
    path: "/system/dict-data",
    component: Layout,
    hidden: true,
    permissions: ["system:dict:list"],
    children: [
      {
        path: "index/:dictId(\\d+)",
        component: () => import("@/views/system/dict/data"),
        name: "Data",
        meta: { title: "字典数据", activeMenu: "/system/dict" },
      },
    ],
  },
  {
    path: "/monitor/job-log",
    component: Layout,
    hidden: true,
    permissions: ["monitor:job:list"],
    children: [
      {
        path: "index",
        component: () => import("@/views/monitor/job/log"),
        name: "JobLog",
        meta: { title: "调度日志", activeMenu: "/monitor/job" },
      },
    ],
  },
  {
    path: "/tool/gen-edit",
    component: Layout,
    hidden: true,
    permissions: ["tool:gen:edit"],
    children: [
      {
        path: "index/:tableId(\\d+)",
        component: () => import("@/views/tool/gen/editTable"),
        name: "GenEdit",
        meta: { title: "修改生成配置", activeMenu: "/tool/gen" },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE),
  routes: constantRoutes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
