/* unplugin-vue-components disabled */import __unplugin_components_1 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/RightToolbar/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, isRef as _isRef, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "app-container"
};
const _hoisted_2 = {
  class: "dialog-footer"
};
const _hoisted_3 = {
  class: "dialog-footer"
};
import { addRole, changeRoleStatus, dataScope, delRole, getRole, listRole, updateRole } from "@/api/system/role";
import { roleMenuTreeselect, treeselect as menuTreeselect } from "@/api/system/menu";
import { treeselect as deptTreeselect, roleDeptTreeselect } from "@/api/system/dept";
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const {
      proxy
    } = getCurrentInstance();
    const {
      sys_normal_disable
    } = proxy.useDict("sys_normal_disable");
    const roleList = ref([]);
    const open = ref(false);
    const loading = ref(true);
    const showSearch = ref(true);
    const ids = ref([]);
    const single = ref(true);
    const multiple = ref(true);
    const total = ref(0);
    const title = ref("");
    const dateRange = ref([]);
    const menuOptions = ref([]);
    const menuExpand = ref(false);
    const menuNodeAll = ref(false);
    const deptExpand = ref(true);
    const deptNodeAll = ref(false);
    const deptOptions = ref([]);
    const openDataScope = ref(false);
    const menuRef = ref(null);
    const deptRef = ref(null);

    /** 数据范围选项*/
    const dataScopeOptions = ref([{
      value: "1",
      label: "全部数据权限"
    }, {
      value: "2",
      label: "自定数据权限"
    }, {
      value: "3",
      label: "本部门数据权限"
    }, {
      value: "4",
      label: "本部门及以下数据权限"
    }, {
      value: "5",
      label: "仅本人数据权限"
    }]);
    const data = reactive({
      form: {},
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        roleName: undefined,
        roleKey: undefined,
        status: undefined
      },
      rules: {
        roleName: [{
          required: true,
          message: "角色名称不能为空",
          trigger: "blur"
        }],
        roleKey: [{
          required: true,
          message: "权限字符不能为空",
          trigger: "blur"
        }],
        roleSort: [{
          required: true,
          message: "角色顺序不能为空",
          trigger: "blur"
        }]
      }
    });
    const {
      queryParams,
      form,
      rules
    } = toRefs(data);

    /** 查询角色列表 */
    function getList() {
      loading.value = true;
      listRole(proxy.addDateRange(queryParams.value, dateRange.value)).then(response => {
        roleList.value = response.rows;
        total.value = response.total;
        loading.value = false;
      });
    }
    /** 搜索按钮操作 */
    function handleQuery() {
      queryParams.value.pageNum = 1;
      getList();
    }
    /** 重置按钮操作 */
    function resetQuery() {
      dateRange.value = [];
      proxy.resetForm("queryRef");
      handleQuery();
    }
    /** 删除按钮操作 */
    function handleDelete(row) {
      const roleIds = row.roleId || ids.value;
      proxy.$modal.confirm('是否确认删除角色编号为"' + roleIds + '"的数据项?').then(function () {
        return delRole(roleIds);
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    }
    /** 导出按钮操作 */
    function handleExport() {
      proxy.download("system/role/export", {
        ...queryParams.value
      }, `role_${new Date().getTime()}.xlsx`);
    }
    /** 多选框选中数据 */
    function handleSelectionChange(selection) {
      ids.value = selection.map(item => item.roleId);
      single.value = selection.length != 1;
      multiple.value = !selection.length;
    }
    /** 角色状态修改 */
    function handleStatusChange(row) {
      let text = row.status === "0" ? "启用" : "停用";
      proxy.$modal.confirm('确认要"' + text + '""' + row.roleName + '"角色吗?').then(function () {
        return changeRoleStatus(row.roleId, row.status);
      }).then(() => {
        proxy.$modal.msgSuccess(text + "成功");
      }).catch(function () {
        row.status = row.status === "0" ? "1" : "0";
      });
    }
    /** 更多操作 */
    function handleCommand(command, row) {
      switch (command) {
        case "handleDataScope":
          handleDataScope(row);
          break;
        case "handleAuthUser":
          handleAuthUser(row);
          break;
        default:
          break;
      }
    }
    /** 分配用户 */
    function handleAuthUser(row) {
      router.push("/system/role-auth/user/" + row.roleId);
    }
    /** 查询菜单树结构 */
    function getMenuTreeselect() {
      menuTreeselect().then(response => {
        menuOptions.value = response.data;
      });
    }
    /** 所有部门节点数据 */
    function getDeptAllCheckedKeys() {
      // 目前被选中的部门节点
      let checkedKeys = deptRef.value.getCheckedKeys();
      // 半选中的部门节点
      let halfCheckedKeys = deptRef.value.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    }
    /** 重置新增的表单以及其他数据  */
    function reset() {
      if (menuRef.value != undefined) {
        menuRef.value.setCheckedKeys([]);
      }
      menuExpand.value = false;
      menuNodeAll.value = false;
      deptExpand.value = true;
      deptNodeAll.value = false;
      form.value = {
        roleId: undefined,
        roleName: undefined,
        roleKey: undefined,
        roleSort: 0,
        status: "0",
        menuIds: [],
        deptIds: [],
        menuCheckStrictly: true,
        deptCheckStrictly: true,
        remark: undefined
      };
      proxy.resetForm("roleRef");
    }
    /** 添加角色 */
    function handleAdd() {
      reset();
      getMenuTreeselect();
      open.value = true;
      title.value = "添加角色";
    }
    /** 修改角色 */
    function handleUpdate(row) {
      reset();
      const roleId = row.roleId || ids.value;
      const roleMenu = getRoleMenuTreeselect(roleId);
      getRole(roleId).then(response => {
        form.value = response.data;
        form.value.roleSort = Number(form.value.roleSort);
        open.value = true;
        nextTick(() => {
          roleMenu.then(res => {
            let checkedKeys = res.checkedKeys;
            checkedKeys.forEach(v => {
              nextTick(() => {
                menuRef.value.setChecked(v, true, false);
              });
            });
          });
        });
        title.value = "修改角色";
      });
    }
    /** 根据角色ID查询菜单树结构 */
    function getRoleMenuTreeselect(roleId) {
      return roleMenuTreeselect(roleId).then(response => {
        menuOptions.value = response.menus;
        return response;
      });
    }
    /** 根据角色ID查询部门树结构 */
    function getRoleDeptTreeselect(roleId) {
      return roleDeptTreeselect(roleId).then(response => {
        deptOptions.value = response.depts;
        return response;
      });
    }
    /** 树权限（展开/折叠）*/
    function handleCheckedTreeExpand(value, type) {
      if (type == "menu") {
        let treeList = menuOptions.value;
        for (let i = 0; i < treeList.length; i++) {
          menuRef.value.store.nodesMap[treeList[i].id].expanded = value;
        }
      } else if (type == "dept") {
        let treeList = deptOptions.value;
        for (let i = 0; i < treeList.length; i++) {
          deptRef.value.store.nodesMap[treeList[i].id].expanded = value;
        }
      }
    }
    /** 树权限（全选/全不选） */
    function handleCheckedTreeNodeAll(value, type) {
      if (type == "menu") {
        menuRef.value.setCheckedNodes(value ? menuOptions.value : []);
      } else if (type == "dept") {
        deptRef.value.setCheckedNodes(value ? deptOptions.value : []);
      }
    }
    /** 树权限（父子联动） */
    function handleCheckedTreeConnect(value, type) {
      if (type == "menu") {
        form.value.menuCheckStrictly = value ? true : false;
      } else if (type == "dept") {
        form.value.deptCheckStrictly = value ? true : false;
      }
    }
    /** 所有菜单节点数据 */
    function getMenuAllCheckedKeys() {
      // 目前被选中的菜单节点
      let checkedKeys = menuRef.value.getCheckedKeys();
      // 半选中的菜单节点
      let halfCheckedKeys = menuRef.value.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    }
    /** 提交按钮 */
    function submitForm() {
      proxy.$refs["roleRef"].validate(valid => {
        if (valid) {
          if (form.value.roleId != undefined) {
            form.value.menuIds = getMenuAllCheckedKeys();
            updateRole(form.value).then(response => {
              proxy.$modal.msgSuccess("修改成功");
              open.value = false;
              getList();
            });
          } else {
            form.value.menuIds = getMenuAllCheckedKeys();
            addRole(form.value).then(response => {
              proxy.$modal.msgSuccess("新增成功");
              open.value = false;
              getList();
            });
          }
        }
      });
    }
    /** 取消按钮 */
    function cancel() {
      open.value = false;
      reset();
    }
    /** 选择角色权限范围触发 */
    function dataScopeSelectChange(value) {
      if (value !== "2") {
        deptRef.value.setCheckedKeys([]);
      }
    }
    /** 分配数据权限操作 */
    function handleDataScope(row) {
      reset();
      const roleDeptTreeselect = getRoleDeptTreeselect(row.roleId);
      getRole(row.roleId).then(response => {
        form.value = response.data;
        openDataScope.value = true;
        nextTick(() => {
          roleDeptTreeselect.then(res => {
            nextTick(() => {
              if (deptRef.value) {
                deptRef.value.setCheckedKeys(res.checkedKeys);
              }
            });
          });
        });
        title.value = "分配数据权限";
      });
    }
    /** 提交按钮（数据权限） */
    function submitDataScope() {
      if (form.value.roleId != undefined) {
        form.value.deptIds = getDeptAllCheckedKeys();
        dataScope(form.value).then(response => {
          proxy.$modal.msgSuccess("修改成功");
          openDataScope.value = false;
          getList();
        });
      }
    }
    /** 取消按钮（数据权限）*/
    function cancelDataScope() {
      openDataScope.value = false;
      reset();
    }
    getList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_date_picker = _resolveComponent("el-date-picker");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_col = _resolveComponent("el-col");
      const _component_right_toolbar = __unplugin_components_0;
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_switch = _resolveComponent("el-switch");
      const _component_el_tooltip = _resolveComponent("el-tooltip");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_1;
      const _component_question_filled = _resolveComponent("question-filled");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_input_number = _resolveComponent("el-input-number");
      const _component_el_radio = _resolveComponent("el-radio");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_tree = _resolveComponent("el-tree");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_hasPermi = _resolveDirective("hasPermi");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_el_form, {
        model: _unref(queryParams),
        ref: "queryRef",
        inline: true
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "角色名称",
          prop: "roleName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).roleName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).roleName = $event),
            placeholder: "请输入角色名称",
            clearable: "",
            style: {
              "width": "240px"
            },
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "权限字符",
          prop: "roleKey"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).roleKey,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).roleKey = $event),
            placeholder: "请输入权限字符",
            clearable: "",
            style: {
              "width": "240px"
            },
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "状态",
          prop: "status"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: _unref(queryParams).status,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(queryParams).status = $event),
            placeholder: "角色状态",
            clearable: "",
            style: {
              "width": "240px"
            }
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sys_normal_disable), dict => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: dict.value,
                label: dict.label,
                value: dict.value
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "创建时间",
          style: {
            "width": "308px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_date_picker, {
            modelValue: _unref(dateRange),
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _isRef(dateRange) ? dateRange.value = $event : null),
            "value-format": "YYYY-MM-DD",
            type: "daterange",
            "range-separator": "-",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            icon: "Search",
            onClick: handleQuery
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          }), _createVNode(_component_el_button, {
            icon: "Refresh",
            onClick: resetQuery
          }, {
            default: _withCtx(() => [_createTextVNode("重置")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), [[_vShow, _unref(showSearch)]]), _createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            plain: "",
            icon: "Plus",
            onClick: handleAdd
          }, {
            default: _withCtx(() => [_createTextVNode("新增")]),
            _: 1
          })), [[_directive_hasPermi, ['system:role:add']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "success",
            plain: "",
            icon: "Edit",
            disabled: _unref(single),
            onClick: handleUpdate
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 1
          }, 8, ["disabled"])), [[_directive_hasPermi, ['system:role:edit']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "danger",
            plain: "",
            icon: "Delete",
            disabled: _unref(multiple),
            onClick: handleDelete
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 1
          }, 8, ["disabled"])), [[_directive_hasPermi, ['system:role:remove']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "warning",
            plain: "",
            icon: "Download",
            onClick: handleExport
          }, {
            default: _withCtx(() => [_createTextVNode("导出")]),
            _: 1
          })), [[_directive_hasPermi, ['system:role:export']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_right_toolbar, {
          showSearch: _unref(showSearch),
          "onUpdate:showSearch": _cache[4] || (_cache[4] = $event => _isRef(showSearch) ? showSearch.value = $event : null),
          onQueryTable: getList
        }, null, 8, ["showSearch"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: _unref(roleList),
        onSelectionChange: handleSelectionChange
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          type: "selection",
          width: "55",
          align: "center"
        }), _createVNode(_component_el_table_column, {
          label: "角色编号",
          prop: "roleId",
          width: "120"
        }), _createVNode(_component_el_table_column, {
          label: "角色名称",
          prop: "roleName",
          "show-overflow-tooltip": true,
          width: "150"
        }), _createVNode(_component_el_table_column, {
          label: "权限字符",
          prop: "roleKey",
          "show-overflow-tooltip": true,
          width: "150"
        }), _createVNode(_component_el_table_column, {
          label: "显示顺序",
          prop: "roleSort",
          width: "100"
        }), _createVNode(_component_el_table_column, {
          label: "状态",
          align: "center",
          width: "100"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_switch, {
            modelValue: scope.row.status,
            "onUpdate:modelValue": $event => scope.row.status = $event,
            "active-value": "0",
            "inactive-value": "1",
            onChange: $event => handleStatusChange(scope.row)
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "创建时间",
          align: "center",
          prop: "createTime"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(_ctx.parseTime(scope.row.createTime)), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "操作",
          align: "center",
          "class-name": "small-padding fixed-width"
        }, {
          default: _withCtx(scope => [scope.row.roleId !== 1 ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 0,
            content: "修改",
            placement: "top"
          }, {
            default: _withCtx(() => [_withDirectives(_createVNode(_component_el_button, {
              type: "primary",
              link: "",
              icon: "Edit",
              onClick: $event => handleUpdate(scope.row)
            }, null, 8, ["onClick"]), [[_directive_hasPermi, ['system:role:edit']]])]),
            _: 2
          }, 1024)) : _createCommentVNode("", true), scope.row.roleId !== 1 ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 1,
            content: "删除",
            placement: "top"
          }, {
            default: _withCtx(() => [_withDirectives(_createVNode(_component_el_button, {
              type: "primary",
              link: "",
              icon: "Delete",
              onClick: $event => handleDelete(scope.row)
            }, null, 8, ["onClick"]), [[_directive_hasPermi, ['system:role:remove']]])]),
            _: 2
          }, 1024)) : _createCommentVNode("", true), scope.row.roleId !== 1 ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 2,
            content: "数据权限",
            placement: "top"
          }, {
            default: _withCtx(() => [_withDirectives(_createVNode(_component_el_button, {
              type: "primary",
              link: "",
              icon: "CircleCheck",
              onClick: $event => handleDataScope(scope.row)
            }, null, 8, ["onClick"]), [[_directive_hasPermi, ['system:role:edit']]])]),
            _: 2
          }, 1024)) : _createCommentVNode("", true), scope.row.roleId !== 1 ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 3,
            content: "分配用户",
            placement: "top"
          }, {
            default: _withCtx(() => [_withDirectives(_createVNode(_component_el_button, {
              type: "primary",
              link: "",
              icon: "User",
              onClick: $event => handleAuthUser(scope.row)
            }, null, 8, ["onClick"]), [[_directive_hasPermi, ['system:role:edit']]])]),
            _: 2
          }, 1024)) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[5] || (_cache[5] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[6] || (_cache[6] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_component_el_dialog, {
        title: _unref(title),
        modelValue: _unref(open),
        "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => _isRef(open) ? open.value = $event : null),
        width: "500px",
        "append-to-body": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: submitForm
        }, {
          default: _withCtx(() => [_createTextVNode("确 定")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: cancel
        }, {
          default: _withCtx(() => [_createTextVNode("取 消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref: "roleRef",
          model: _unref(form),
          rules: _unref(rules),
          "label-width": "100px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "角色名称",
            prop: "roleName"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).roleName,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _unref(form).roleName = $event),
              placeholder: "请输入角色名称"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            prop: "roleKey"
          }, {
            label: _withCtx(() => [_createElementVNode("span", null, [_createVNode(_component_el_tooltip, {
              content: "控制器中定义的权限字符，如：@PreAuthorize(`@ss.hasRole('admin')`)",
              placement: "top"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_icon, null, {
                default: _withCtx(() => [_createVNode(_component_question_filled)]),
                _: 1
              })]),
              _: 1
            }), _createTextVNode(" 权限字符 ")])]),
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).roleKey,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _unref(form).roleKey = $event),
              placeholder: "请输入权限字符"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "角色顺序",
            prop: "roleSort"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input_number, {
              modelValue: _unref(form).roleSort,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _unref(form).roleSort = $event),
              "controls-position": "right",
              min: 0
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "状态"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_radio_group, {
              modelValue: _unref(form).status,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _unref(form).status = $event)
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sys_normal_disable), dict => {
                return _openBlock(), _createBlock(_component_el_radio, {
                  key: dict.value,
                  label: dict.value
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(dict.label), 1)]),
                  _: 2
                }, 1032, ["label"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "菜单权限"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_checkbox, {
              modelValue: _unref(menuExpand),
              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _isRef(menuExpand) ? menuExpand.value = $event : null),
              onChange: _cache[12] || (_cache[12] = $event => handleCheckedTreeExpand($event, 'menu'))
            }, {
              default: _withCtx(() => [_createTextVNode("展开/折叠")]),
              _: 1
            }, 8, ["modelValue"]), _createVNode(_component_el_checkbox, {
              modelValue: _unref(menuNodeAll),
              "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => _isRef(menuNodeAll) ? menuNodeAll.value = $event : null),
              onChange: _cache[14] || (_cache[14] = $event => handleCheckedTreeNodeAll($event, 'menu'))
            }, {
              default: _withCtx(() => [_createTextVNode("全选/全不选")]),
              _: 1
            }, 8, ["modelValue"]), _createVNode(_component_el_checkbox, {
              modelValue: _unref(form).menuCheckStrictly,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => _unref(form).menuCheckStrictly = $event),
              onChange: _cache[16] || (_cache[16] = $event => handleCheckedTreeConnect($event, 'menu'))
            }, {
              default: _withCtx(() => [_createTextVNode("父子联动")]),
              _: 1
            }, 8, ["modelValue"]), _createVNode(_component_el_tree, {
              class: "tree-border",
              data: _unref(menuOptions),
              "show-checkbox": "",
              ref_key: "menuRef",
              ref: menuRef,
              "node-key": "id",
              "check-strictly": !_unref(form).menuCheckStrictly,
              "empty-text": "加载中，请稍候",
              props: {
                label: 'label',
                children: 'children'
              }
            }, null, 8, ["data", "check-strictly"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "备注"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).remark,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => _unref(form).remark = $event),
              type: "textarea",
              placeholder: "请输入内容"
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["title", "modelValue"]), _createVNode(_component_el_dialog, {
        title: _unref(title),
        modelValue: _unref(openDataScope),
        "onUpdate:modelValue": _cache[28] || (_cache[28] = $event => _isRef(openDataScope) ? openDataScope.value = $event : null),
        width: "500px",
        "append-to-body": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: submitDataScope
        }, {
          default: _withCtx(() => [_createTextVNode("确 定")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: cancelDataScope
        }, {
          default: _withCtx(() => [_createTextVNode("取 消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(form),
          "label-width": "80px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "角色名称"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).roleName,
              "onUpdate:modelValue": _cache[19] || (_cache[19] = $event => _unref(form).roleName = $event),
              disabled: true
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "权限字符"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).roleKey,
              "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => _unref(form).roleKey = $event),
              disabled: true
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "权限范围"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: _unref(form).dataScope,
              "onUpdate:modelValue": _cache[21] || (_cache[21] = $event => _unref(form).dataScope = $event),
              onChange: dataScopeSelectChange
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataScopeOptions), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.value,
                  label: item.label,
                  value: item.value
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _withDirectives(_createVNode(_component_el_form_item, {
            label: "数据权限"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_checkbox, {
              modelValue: _unref(deptExpand),
              "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => _isRef(deptExpand) ? deptExpand.value = $event : null),
              onChange: _cache[23] || (_cache[23] = $event => handleCheckedTreeExpand($event, 'dept'))
            }, {
              default: _withCtx(() => [_createTextVNode("展开/折叠")]),
              _: 1
            }, 8, ["modelValue"]), _createVNode(_component_el_checkbox, {
              modelValue: _unref(deptNodeAll),
              "onUpdate:modelValue": _cache[24] || (_cache[24] = $event => _isRef(deptNodeAll) ? deptNodeAll.value = $event : null),
              onChange: _cache[25] || (_cache[25] = $event => handleCheckedTreeNodeAll($event, 'dept'))
            }, {
              default: _withCtx(() => [_createTextVNode("全选/全不选")]),
              _: 1
            }, 8, ["modelValue"]), _createVNode(_component_el_checkbox, {
              modelValue: _unref(form).deptCheckStrictly,
              "onUpdate:modelValue": _cache[26] || (_cache[26] = $event => _unref(form).deptCheckStrictly = $event),
              onChange: _cache[27] || (_cache[27] = $event => handleCheckedTreeConnect($event, 'dept'))
            }, {
              default: _withCtx(() => [_createTextVNode("父子联动")]),
              _: 1
            }, 8, ["modelValue"]), _createVNode(_component_el_tree, {
              class: "tree-border",
              data: _unref(deptOptions),
              "show-checkbox": "",
              "default-expand-all": "",
              ref_key: "deptRef",
              ref: deptRef,
              "node-key": "id",
              "check-strictly": !_unref(form).deptCheckStrictly,
              "empty-text": "加载中，请稍候",
              props: {
                label: 'label',
                children: 'children'
              }
            }, null, 8, ["data", "check-strictly"])]),
            _: 1
          }, 512), [[_vShow, _unref(form).dataScope == 2]])]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["title", "modelValue"])]);
    };
  }
};