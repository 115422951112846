/* unplugin-vue-components disabled */import __unplugin_components_1 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/ImageUpload/index.vue';
import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, isRef as _isRef, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-54e8559a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dialog-footer"
};
import { queryAdvertisePageList, addAdvertise, updateAdvertise, findAdvertise, deleteAdvertise } from "@/api/functionList/index";
export default {
  __name: 'AdvertisingSpaceManagement',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const dialogFormVisible = ref(false);
    const loading = ref(false);
    const btnLoading = ref(false);
    const total = ref(0);
    const formRef = ref();
    const title = ref("新增广告位");
    const data = reactive({
      tableData: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        title: ""
      },
      form: {
        title: "",
        sort: 0,
        remark: "",
        imageUrl: "https://xiaochong-1309915902.cos.ap-guangzhou.myqcloud.com/2023/07/15/ab8e3e23-0639-4b7d-9763-be3306af6496.jpg",
        jumpMethod: "",
        jumpUrl: "",
        position: "",
        status: ""
      },
      optionsJumpMethod: [{
        code: "app",
        name: "应用内跳转"
      }, {
        code: "url",
        name: "超链接跳转"
      }, {
        code: "none",
        name: "无"
      }],
      optionsPosition: [{
        code: "banner",
        name: "轮播推荐"
      }],
      rules: {
        title: [{
          required: true,
          message: "广告位标题不能为空",
          trigger: "blur"
        }],
        sort: [{
          required: true,
          message: "排序不能为空",
          trigger: "blur"
        }],
        imageUrl: [{
          required: true,
          message: "图片不能为空",
          trigger: "blur"
        }],
        position: [{
          required: true,
          message: "推荐位不能为空",
          trigger: "blur"
        }],
        status: [{
          required: true,
          message: "状态不能为空",
          trigger: "blur"
        }],
        jumpUrl: [{
          required: true,
          message: "跳转方式地址不能为空",
          trigger: "blur"
        }],
        jumpMethod: [{
          required: true,
          message: "请选择跳转方式",
          trigger: "blur"
        }]
      }
    });
    const {
      tableData,
      queryParams,
      form,
      rules
    } = toRefs(data);
    const handleAdd = () => {
      dialogFormVisible.value = true;
    };
    const cancel = () => {
      dialogFormVisible.value = false;
      reset();
    };
    const handleQuery = () => {
      queryParams.value.pageNum = 1;
      getList();
    };
    const submit = async formEl => {
      if (!formEl) return;
      await formEl.validate(valid => {
        if (valid) {
          if (form.value.id) {
            // 修改
            editUser();
          } else {
            // 新增
            addUser();
          }
        }
      });
    };
    const switchChange = row => {
      const sendData = {
        code: row.code,
        title: row.title,
        sort: row.sort,
        remark: row.remark,
        imageUrl: row.imageUrl,
        jumpMethod: row.jumpMethod,
        jumpUrl: row.jumpUrl,
        position: row.position,
        status: row.status
      };
      updateAdvertise(sendData).then(res => {
        dialogFormVisible.value = false;
        getList();
        reset();
        proxy.$modal.msgSuccess("修改成功！");
      });
    };

    // 新增员工
    const addUser = () => {
      addAdvertise(form.value).then(res => {
        dialogFormVisible.value = false;
        getList();
        reset();
        proxy.$modal.msgSuccess("新增成功");
      });
    };

    // 修改员工
    const editUser = () => {
      updateAdvertise(form.value).then(res => {
        dialogFormVisible.value = false;
        getList();
        reset();
        proxy.$modal.msgSuccess("修改成功！");
      });
    };

    // 关闭弹窗
    const closeDialog = () => {
      dialogFormVisible.value = false;
      reset();
    };

    // 查询广告位列表
    const getList = () => {
      loading.value = true;
      queryAdvertisePageList(queryParams.value).then(res => {
        try {
          tableData.value = res.data;
          total.value = res.total;
        } catch (error) {}
        loading.value = false;
      });
    };

    // 去修改
    const handleUpdate = rows => {
      getSingleVal(rows.code);
    };

    // 查询单个广告位信息
    const getSingleVal = code => {
      findAdvertise({
        code
      }).then(res => {
        form.value = res.data;
        dialogFormVisible.value = true;
      });
    };
    const handleDelete = rows => {
      const {
        code
      } = rows;
      proxy.$modal.confirm("是否确认删除?").then(function () {
        return deleteAdvertise({
          code
        });
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(err => {
        console.log(err);
      });
    };

    // 重置表单
    const reset = () => {
      formRef.value?.resetFields();
    };
    getList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_switch = _resolveComponent("el-switch");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _component_el_input_number = _resolveComponent("el-input-number");
      const _component_ImageUpload = __unplugin_components_1;
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, {
        gutter: 2
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(queryParams),
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
          inline: true,
          ref: "queryParamsRef"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "广告位标题"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(queryParams).title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).title = $event),
              clearable: "",
              placeholder: "请输入广告位标题"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: "Search",
              plain: "",
              type: "primary",
              onClick: handleQuery
            }, {
              default: _withCtx(() => [_createTextVNode("搜索")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }), _createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            icon: "Plus",
            plain: "",
            type: "primary",
            onClick: handleAdd
          }, {
            default: _withCtx(() => [_createTextVNode("新增广告")]),
            _: 1
          })]),
          _: 1
        }, 8, ["span"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "imageUrl",
          align: "center",
          label: "图片"
        }, {
          default: _withCtx(scope => [scope.row.imageUrl ? (_openBlock(), _createBlock(_component_el_image, {
            key: 0,
            style: {
              "width": "100px",
              "height": "100px"
            },
            src: scope.row.imageUrl,
            "zoom-rate": 1.2,
            "initial-index": 1,
            fit: "cover"
          }, null, 8, ["src", "zoom-rate"])) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "title",
          align: "center",
          label: "标题"
        }), _createVNode(_component_el_table_column, {
          prop: "jumpMethod",
          align: "center",
          label: "跳转方式"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(scope.row.jumpMethod == "url" ? "超链接跳转" : scope.row.jumpMethod == "app" ? "应用内跳转" : "无"), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "jumpUrl",
          align: "center",
          label: "跳转方式地址"
        }), _createVNode(_component_el_table_column, {
          prop: "position",
          align: "center",
          label: "推荐位"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(scope.row.position == "banner" ? "轮播推荐" : scope.row.position), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "sort",
          align: "center",
          label: "排序"
        }), _createVNode(_component_el_table_column, {
          prop: "status",
          align: "center",
          label: "开启状态"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_switch, {
            modelValue: scope.row.status,
            "onUpdate:modelValue": $event => scope.row.status = $event,
            "active-value": 'on',
            "inactive-value": 'off',
            onChange: $event => switchChange(scope.row)
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "remark",
          align: "center",
          label: "描述"
        }), _createVNode(_component_el_table_column, {
          fixed: "right",
          align: "center",
          label: "操作"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleUpdate(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            link: "",
            type: "danger",
            size: "small",
            onClick: $event => handleDelete(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[2] || (_cache[2] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[3] || (_cache[3] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_component_el_dialog, {
        modelValue: _unref(dialogFormVisible),
        "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => _isRef(dialogFormVisible) ? dialogFormVisible.value = $event : null),
        "before-close": closeDialog,
        title: _unref(title)
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          onClick: _cache[12] || (_cache[12] = $event => cancel(_unref(formRef)))
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[13] || (_cache[13] = $event => submit(_unref(formRef))),
          loading: _unref(btnLoading)
        }, {
          default: _withCtx(() => [_createTextVNode("提交")]),
          _: 1
        }, 8, ["loading"])])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(form),
          ref_key: "formRef",
          ref: formRef,
          rules: _unref(rules),
          "label-width": "120"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "广告位标题",
            prop: "title"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).title,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _unref(form).title = $event),
              placeholder: "广告位标题",
              maxlength: "10",
              "show-word-limit": ""
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "排序",
            prop: "sort"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input_number, {
              min: 1,
              modelValue: _unref(form).sort,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _unref(form).sort = $event),
              "controls-position": "right"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "图片",
            prop: "imageUrl"
          }, {
            default: _withCtx(() => [_createVNode(_component_ImageUpload, {
              limit: 1,
              modelValue: _unref(form).imageUrl,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _unref(form).imageUrl = $event),
              fileSize: 35
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "跳转方式",
            prop: "jumpMethod"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: _unref(form).jumpMethod,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _unref(form).jumpMethod = $event),
              style: {
                "width": "100%"
              },
              class: "m-2",
              placeholder: "请选择跳转方式"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data).optionsJumpMethod, item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.code,
                  label: item.name,
                  value: item.code
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "推荐位",
            prop: "position"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: _unref(form).position,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _unref(form).position = $event),
              style: {
                "width": "100%"
              },
              class: "m-2",
              placeholder: "请选择推荐位"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(data).optionsPosition, item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.code,
                  label: item.name,
                  value: item.code
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "跳转方式地址",
            prop: "jumpUrl"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).jumpUrl,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _unref(form).jumpUrl = $event),
              placeholder: "跳转方式地址"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "开启状态",
            prop: "status"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_switch, {
              modelValue: _unref(form).status,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _unref(form).status = $event),
              "active-value": 'on',
              "inactive-value": 'off'
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "描述",
            prop: "remark"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).remark,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _unref(form).remark = $event),
              placeholder: "描述",
              maxlength: "50",
              "show-word-limit": ""
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};