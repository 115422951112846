/* unplugin-vue-components disabled */import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, isRef as _isRef, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-007a4ba2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "el-tree-select"
};
export default {
  __name: 'index',
  props: {
    /* 配置项 */
    objMap: {
      type: Object,
      default: () => {
        return {
          value: 'id',
          // ID字段名
          label: 'label',
          // 显示名称
          children: 'children' // 子级字段名
        };
      }
    },

    /* 自动收起 */
    accordion: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    /**当前双向数据绑定的值 */
    value: {
      type: [String, Number],
      default: ''
    },
    /**当前的数据 */
    options: {
      type: Array,
      default: () => []
    },
    /**输入框内部的文字 */
    placeholder: {
      type: String,
      default: ''
    }
  },
  emits: ['update:value'],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const {
      proxy
    } = getCurrentInstance();
    const valueId = computed({
      get: () => props.value,
      set: val => {
        emit('update:value', val);
      }
    });
    const valueTitle = ref('');
    const defaultExpandedKey = ref([]);
    function initHandle() {
      nextTick(() => {
        const selectedValue = valueId.value;
        if (selectedValue !== null && typeof selectedValue !== 'undefined') {
          const node = proxy.$refs.selectTree.getNode(selectedValue);
          if (node) {
            valueTitle.value = node.data[props.objMap.label];
            proxy.$refs.selectTree.setCurrentKey(selectedValue); // 设置默认选中
            defaultExpandedKey.value = [selectedValue]; // 设置默认展开
          }
        } else {
          clearHandle();
        }
      });
    }
    function handleNodeClick(node) {
      valueTitle.value = node[props.objMap.label];
      valueId.value = node[props.objMap.value];
      defaultExpandedKey.value = [];
      proxy.$refs.treeSelect.blur();
      selectFilterData('');
    }
    function selectFilterData(val) {
      proxy.$refs.selectTree.filter(val);
    }
    function filterNode(value, data) {
      if (!value) return true;
      return data[props.objMap['label']].indexOf(value) !== -1;
    }
    function clearHandle() {
      valueTitle.value = '';
      valueId.value = '';
      defaultExpandedKey.value = [];
      clearSelected();
    }
    function clearSelected() {
      const allNode = document.querySelectorAll('#tree-option .el-tree-node');
      allNode.forEach(element => element.classList.remove('is-current'));
    }
    onMounted(() => {
      initHandle();
    });
    watch(valueId, () => {
      initHandle();
    });
    return (_ctx, _cache) => {
      const _component_el_tree = _resolveComponent("el-tree");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_select, {
        style: {
          "width": "100%"
        },
        modelValue: _unref(valueId),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(valueId) ? valueId.value = $event : null),
        ref: "treeSelect",
        filterable: true,
        clearable: true,
        onClear: clearHandle,
        "filter-method": selectFilterData,
        placeholder: __props.placeholder
      }, {
        default: _withCtx(() => [_createVNode(_component_el_option, {
          value: _unref(valueId),
          label: _unref(valueTitle)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_tree, {
            id: "tree-option",
            ref: "selectTree",
            accordion: __props.accordion,
            data: __props.options,
            props: __props.objMap,
            "node-key": __props.objMap.value,
            "expand-on-click-node": false,
            "default-expanded-keys": _unref(defaultExpandedKey),
            "filter-node-method": filterNode,
            onNodeClick: handleNodeClick
          }, null, 8, ["accordion", "data", "props", "node-key", "default-expanded-keys"])]),
          _: 1
        }, 8, ["value", "label"])]),
        _: 1
      }, 8, ["modelValue", "placeholder"])]);
    };
  }
};