/* unplugin-vue-components disabled */import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow, createElementBlock as _createElementBlock } from "vue";
import { queryNoteSendLog } from "@/api/sms/index";
export default {
  __name: 'Sendingrecord',
  props: {
    list: {
      type: Object
    }
  },
  emits: ["handleClose"],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const loading = ref(false);
    const total = ref(0);
    const data = reactive({
      tableData: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10
      }
    });
    const {
      tableData,
      queryParams
    } = toRefs(data);

    // const tableData = computed({
    //     get(){
    //         return props.list
    //     }
    // })

    const getList = () => {
      console.log(111);
      loading.value = true;
      queryNoteSendLog(Object.assign(queryParams.value, props.list)).then(res => {
        console.log(res);
        tableData.value = res.data.records;
        total.value = res.data.total;
        loading.value = false;
      });
    };
    getList();
    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", null, [_withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData),
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "userCode",
          label: "手机号",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "sendingTimes",
          label: "发送条数",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "messageContent",
          label: "短信内容",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "sendTime",
          label: "发送时间",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "sendStatusDesc",
          label: "状态",
          width: ""
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[0] || (_cache[0] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[1] || (_cache[1] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]])]);
    };
  }
};