import request from '@/utils/request'
import mockData from './mock.js'
// 获取路由
export const getRouters = () => {
  // return request({
  //   url: '/getRouters',
  //   method: 'get'
  // })
  return new Promise((resolve,reject)=>{
    resolve({data:mockData})
  })
}