/* unplugin-vue-components disabled */import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow } from "vue";
const _hoisted_1 = {
  class: "table-inner"
};
const _hoisted_2 = {
  key: 0
};
import { queryNoteRechargeLog } from "@/api/sms/index";
export default {
  __name: 'Rechargerecord',
  props: {
    list: Object
  },
  emits: ["handleClose"],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const total = ref(0);
    const {
      proxy
    } = getCurrentInstance();
    const loading = ref(false);
    const data = reactive({
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      currentList: []
    });
    const {
      queryParams,
      currentList
    } = toRefs(data);

    // const currentList = computed({
    //     get(){
    //         return props.list
    //     }
    // })

    const getList = () => {
      console.log(123);
      loading.value = true;
      queryNoteRechargeLog(Object.assign(queryParams.value, props.list)).then(res => {
        currentList.value = res.data.records;
        total.value = res.data.total;
        loading.value = false;
      }).catch(err => {});
    };
    const sub = () => {
      emit("handleClose", {
        name: 1,
        age: 123
      });
    };
    getList();
    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(currentList),
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "num",
          label: "充值数量",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "num",
          label: "支付金额",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "payMethod",
          label: "支付方式",
          width: ""
        }, {
          default: _withCtx(scope => [(scope.row.payMethod = '') ? (_openBlock(), _createElementBlock("span", _hoisted_2)) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "createdTime",
          label: "充值时间",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "statusDesc",
          label: "状态",
          width: ""
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[0] || (_cache[0] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[1] || (_cache[1] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]])]);
    };
  }
};