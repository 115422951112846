/* unplugin-vue-components disabled */import __unplugin_components_2 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Editor/index.vue';
import __unplugin_components_1 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/ImageUpload/index.vue';
import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-341b61eb"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "mb8"
};
const _hoisted_2 = {
  class: "mall_btn"
};
const _hoisted_3 = {
  class: "dialog-footer"
};
import { appGoodsInfoQueryPage, appGoodsInfoAdd, appGoodsInfoFind, appGoodsInfoUpdate, appGoodsInfoDelete, queryAllMemAppInfoList, queryAllMemUserInfoList, appGoodsExchangeLog } from "@/api/employeeManagement/index";
import { reactive, ref } from "vue";
import { queryPlatMerchantByAll, queryShopInfoByAll } from "@/api/functionList/index";
export default {
  __name: 'PointsMall',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const dialogFormVisible = ref(false);
    const dialogExchangeList = ref(false);
    const loading = ref(false);
    const Exloading = ref(false);
    const formRef = ref();
    const btnLoading = ref(false);
    const total = ref(0);
    const exchangeTotal = ref(0);
    const title = ref("新增商品");
    const memUserInfoList = ref([]);
    const formType = ref(null);
    const memAppInfoList = ref([]);
    const data = reactive({
      tableData: [],
      exchangeData: [],
      ruleForm: {
        score: 1,
        remark: "",
        merchantCode: "",
        shopCode: "",
        detail: "",
        sort: 0,
        mainImgUrl: "",
        name: "",
        description: "",
        rotationImgUrl: "",
        status: "grounding",
        appCode: "pmt_20230727_9"
      },
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: null
      },
      queryParamsExchange: {
        pageNum: 1,
        pageSize: 10,
        userCode: null
      },
      merchantCodePosition: [],
      shopCodePosition: [],
      // 表单校验
      ruleFormRule: {
        appCode: [{
          required: true,
          message: "应用名称不能为空",
          trigger: "blur"
        }],
        name: [{
          required: true,
          message: "请输入商品名称",
          trigger: "blur"
        }],
        merchantCode: [{
          required: true,
          message: "请选择商户",
          trigger: ["blur"]
        }],
        shopCode: [{
          required: true,
          message: "请选择门店",
          trigger: ["blur"]
        }],
        score: [{
          required: true,
          message: "商品值不能为空",
          trigger: "blur"
        }],
        sort: [{
          required: true,
          message: "排序值不能为空",
          trigger: "blur"
        }],
        description: [{
          required: true,
          message: "请输入商品描述",
          trigger: "blur"
        }]
      }
    });
    const {
      tableData,
      exchangeData,
      queryParams,
      queryParamsExchange,
      ruleFormRule,
      ruleForm,
      merchantCodePosition,
      shopCodePosition
    } = toRefs(data);
    // 会员list
    const getUserList = () => {
      queryAllMemUserInfoList({}).then(res => {
        memUserInfoList.value = res.data;
      });
    };
    getUserList();
    const handleAdd = () => {
      title.value = "新增商品";
      formType.value = "add";
      dialogFormVisible.value = true;
      ruleForm.value.detail = "";
      reset();
    };
    // 查询商户列表
    const getMerchantCode = () => {
      queryPlatMerchantByAll({}).then(res => {
        try {
          merchantCodePosition.value = res.data;
        } catch (error) {}
      });
    };
    const exchangeList = () => {
      // if (!dialogExchangeList.value) {
      dialogExchangeList.value = true;
      // }
      getExchangeList();
    };
    const closeExchangeDialog = () => {
      dialogExchangeList.value = false;
      queryParamsExchange.value.pageNum = 1;
    };
    const getExchangeList = () => {
      Exloading.value = true;
      appGoodsExchangeLog(queryParamsExchange.value).then(res => {
        exchangeData.value = res.data;
        exchangeTotal.value = res.total;
        Exloading.value = false;
      });
    };
    const handleDelete = rows => {
      const {
        code
      } = rows;
      proxy.$modal.confirm("是否确认删除?").then(function () {
        return appGoodsInfoDelete({
          code
        });
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(err => {
        console.log(err);
      });
    };
    // 去修改
    const handleUpdate = rows => {
      title.value = "编辑商品";
      formType.value = "updata";
      ruleForm.value.code = rows.code;
      getSingleVal(rows.code);
    };
    // 查询单个商品信息详情
    const getSingleVal = code => {
      appGoodsInfoFind({
        code
      }).then(res => {
        ruleForm.value = Object.assign({}, res.data);
        dialogFormVisible.value = true;
        getShopCode("init");
      });
    };
    // 查询门店列表
    const getShopCode = type => {
      if (type !== "init") ruleForm.value.shopCode = "";
      queryShopInfoByAll({
        merchantCode: ruleForm.value.merchantCode
      }).then(res => {
        try {
          shopCodePosition.value = res.data;
        } catch (error) {}
      });
    };
    const cancel = () => {
      dialogFormVisible.value = false;
      reset();
    };
    const handleQuery = () => {
      queryParams.value.pageNum = 1;
      getList();
    };
    const submit = async formEl => {
      if (!formEl) return;
      await formEl.validate(valid => {
        if (valid) {
          addShop();
        }
      });
    };

    // 新增商品
    const addShop = () => {
      btnLoading.value = true;
      let api = appGoodsInfoAdd;
      if (formType.value == "updata") {
        api = appGoodsInfoUpdate;
      }
      ruleForm.value.shopName = null;
      ruleForm.value.merchantName = null;
      ruleForm.value.appName = null;
      ruleForm.value.detail = encodeURIComponent(ruleForm.value.detail);
      api(ruleForm.value).then(() => {
        dialogFormVisible.value = false;
        getList();
        reset();
        proxy.$modal.msgSuccess("操作成功");
        btnLoading.value = false;
      }).catch(() => {
        btnLoading.value = false;
      });
    };
    // 应用list
    const getAppList = () => {
      queryAllMemAppInfoList({}).then(res => {
        memAppInfoList.value = res.data;
      });
    };

    // 关闭弹窗
    const closeDialog = () => {
      dialogFormVisible.value = false;
      reset();
    };

    // 查询商品列表
    const getList = () => {
      loading.value = true;
      appGoodsInfoQueryPage(queryParams.value).then(res => {
        try {
          tableData.value = res.data;
          total.value = res.total;
        } catch (error) {}
        loading.value = false;
      });
    };

    // 重置表单
    const reset = () => {
      formRef.value?.resetFields();
    };
    getList();
    getAppList();
    getMerchantCode();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_input_number = _resolveComponent("el-input-number");
      const _component_el_switch = _resolveComponent("el-switch");
      const _component_ImageUpload = __unplugin_components_1;
      const _component_Editor = __unplugin_components_2;
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, {
        gutter: 2
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(queryParams),
          ref: "queryForm",
          inline: true
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "商品名称"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(queryParams).name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).name = $event),
              placeholder: "商品名称",
              clearable: ""
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: "Search",
              plain: "",
              type: "primary",
              onClick: handleQuery
            }, {
              default: _withCtx(() => [_createTextVNode("搜索")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
        icon: "Plus",
        plain: "",
        type: "primary",
        onClick: handleAdd
      }, {
        default: _withCtx(() => [_createTextVNode("新增商品")]),
        _: 1
      }), _createVNode(_component_el_button, {
        plain: "",
        type: "primary",
        onClick: exchangeList
      }, {
        default: _withCtx(() => [_createTextVNode("兑换记录")]),
        _: 1
      })])]), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData),
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "mainImgUrl",
          align: "center",
          label: "图片",
          width: ""
        }, {
          default: _withCtx(scope => [scope.row.mainImgUrl ? (_openBlock(), _createBlock(_component_el_image, {
            key: 0,
            style: {
              "width": "100px",
              "height": "100px"
            },
            src: scope.row.mainImgUrl,
            "zoom-rate": 1.2,
            "initial-index": 1,
            fit: "cover"
          }, null, 8, ["src", "zoom-rate"])) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "商品名称",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "name"
        }), _createVNode(_component_el_table_column, {
          label: "积分",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "score"
        }), _createVNode(_component_el_table_column, {
          label: "排序",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "sort"
        }), _createVNode(_component_el_table_column, {
          label: "兑换数",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "exchange"
        }, {
          default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.exchange || 0), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "状态",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "status"
        }, {
          default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.status == "grounding" ? "上架" : "下架"), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "创建时间",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "createdTime"
        }), _createVNode(_component_el_table_column, {
          fixed: "right",
          align: "center",
          label: "操作",
          width: "150"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleUpdate(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleDelete(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, loading.value]]), _withDirectives(_createVNode(_component_pagination, {
        total: total.value,
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[1] || (_cache[1] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[2] || (_cache[2] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, total.value > 0]]), _createVNode(_component_el_dialog, {
        modelValue: dialogFormVisible.value,
        "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => dialogFormVisible.value = $event),
        "before-close": closeDialog,
        title: title.value,
        width: "1000px"
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_3, [_createVNode(_component_el_button, {
          onClick: _cache[14] || (_cache[14] = $event => cancel(formRef.value))
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          loading: btnLoading.value,
          onClick: _cache[15] || (_cache[15] = $event => submit(formRef.value))
        }, {
          default: _withCtx(() => [_createTextVNode("提交")]),
          _: 1
        }, 8, ["loading"])])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "formRef",
          ref: formRef,
          model: _unref(ruleForm),
          rules: _unref(ruleFormRule),
          "label-position": "top"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "商户",
            prop: "merchantCode"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: _unref(ruleForm).merchantCode,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _unref(ruleForm).merchantCode = $event),
              style: {
                "width": "100%"
              },
              class: "m-2",
              placeholder: "请选择商户",
              onChange: getShopCode
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(merchantCodePosition), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.code,
                  label: item.accountName,
                  value: item.code
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "门店",
            prop: "shopCode"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: _unref(ruleForm).shopCode,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _unref(ruleForm).shopCode = $event),
              class: "m-2",
              placeholder: "请选择门店"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(shopCodePosition), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.code,
                  label: item.name,
                  value: item.code
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "商品名称",
            prop: "name"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "text",
              placeholder: "请输入商品名称",
              modelValue: _unref(ruleForm).name,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _unref(ruleForm).name = $event),
              maxlength: "20"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "积分值",
            prop: "score"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input_number, {
              modelValue: _unref(ruleForm).score,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _unref(ruleForm).score = $event),
              modelModifiers: {
                number: true
              },
              "controls-position": "right",
              min: 1,
              max: 9999
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "状态",
            prop: "status"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_switch, {
              modelValue: _unref(ruleForm).status,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _unref(ruleForm).status = $event),
              class: "ml-2",
              "active-value": "grounding",
              "inactive-value": "off_shelf",
              style: {
                "--el-switch-on-color": "#13ce66",
                "--el-switch-off-color": "#ff4949"
              }
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "排序",
            prop: "sort"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input_number, {
              modelValue: _unref(ruleForm).sort,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _unref(ruleForm).sort = $event),
              modelModifiers: {
                number: true
              },
              "controls-position": "right",
              min: 0,
              max: 9999
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "商品图片",
            prop: "mainImgUrl"
          }, {
            default: _withCtx(() => [_createVNode(_component_ImageUpload, {
              limit: 1,
              modelValue: _unref(ruleForm).mainImgUrl,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _unref(ruleForm).mainImgUrl = $event),
              fileSize: 5
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "轮播图片",
            prop: "rotationImgUrl"
          }, {
            default: _withCtx(() => [_createVNode(_component_ImageUpload, {
              limit: 10,
              modelValue: _unref(ruleForm).rotationImgUrl,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _unref(ruleForm).rotationImgUrl = $event),
              fileSize: 5
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "商品描述",
            prop: "description"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "text",
              placeholder: "请输入商品描述",
              modelValue: _unref(ruleForm).description,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _unref(ruleForm).description = $event),
              maxlength: "100"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "详情"
          }, {
            default: _withCtx(() => [dialogFormVisible.value ? (_openBlock(), _createBlock(_component_Editor, {
              key: 0,
              modelValue: _unref(ruleForm).detail,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => _unref(ruleForm).detail = $event),
              child: _unref(ruleForm).detail,
              "onUpdate:child": _cache[13] || (_cache[13] = $event => _unref(ruleForm).detail = $event)
            }, null, 8, ["modelValue", "child"])) : _createCommentVNode("", true)]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"]), _createVNode(_component_el_dialog, {
        modelValue: dialogExchangeList.value,
        "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => dialogExchangeList.value = $event),
        "before-close": closeExchangeDialog,
        title: "兑换记录",
        width: "1000px"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(queryParamsExchange),
          ref: "queryForm",
          inline: true
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "用户名称"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              clearable: "",
              modelValue: _unref(queryParamsExchange).userCode,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => _unref(queryParamsExchange).userCode = $event),
              placeholder: "请选择"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(memUserInfoList.value, item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.code,
                  label: item.nickName,
                  value: item.code
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: "Search",
              plain: "",
              type: "primary",
              onClick: exchangeList
            }, {
              default: _withCtx(() => [_createTextVNode("搜索")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"]), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
          "max-height": 650,
          data: _unref(exchangeData)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            prop: "mainImgUrl",
            align: "center",
            label: "图片",
            width: "130"
          }, {
            default: _withCtx(scope => [scope.row.memAppGoodsInfoVo.mainImgUrl ? (_openBlock(), _createBlock(_component_el_image, {
              key: 0,
              style: {
                "width": "100px",
                "height": "100px"
              },
              src: scope.row.memAppGoodsInfoVo.mainImgUrl,
              "zoom-rate": 1.2,
              "initial-index": 1,
              fit: "cover"
            }, null, 8, ["src", "zoom-rate"])) : _createCommentVNode("", true)]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "商品名称",
            align: "center",
            "show-overflow-tooltip": ""
          }, {
            default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.memAppGoodsInfoVo.name || ""), 1)]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "积分",
            align: "center",
            "show-overflow-tooltip": "",
            prop: "score"
          }), _createVNode(_component_el_table_column, {
            label: "商户名称",
            align: "center",
            "show-overflow-tooltip": "",
            prop: "merchantName"
          }), _createVNode(_component_el_table_column, {
            label: "门店名称",
            align: "center",
            "show-overflow-tooltip": "",
            prop: "shopName"
          }), _createVNode(_component_el_table_column, {
            label: "应用名称",
            align: "center",
            "show-overflow-tooltip": "",
            prop: "appName"
          }), _createVNode(_component_el_table_column, {
            label: "用户名称",
            align: "center",
            "show-overflow-tooltip": "",
            prop: "userName"
          }), _createVNode(_component_el_table_column, {
            label: "兑换数",
            align: "center",
            "show-overflow-tooltip": "",
            prop: "exchange"
          }, {
            default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.exchange || 0), 1)]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "兑换积分",
            align: "center",
            "show-overflow-tooltip": "",
            prop: "exchangeScore"
          }, {
            default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.exchangeScore || 0), 1)]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "创建时间",
            align: "center",
            "show-overflow-tooltip": "",
            prop: "createdTime"
          })]),
          _: 1
        }, 8, ["data"])), [[_directive_loading, Exloading.value]]), _withDirectives(_createVNode(_component_pagination, {
          total: exchangeTotal.value,
          page: _unref(queryParamsExchange).pageNum,
          "onUpdate:page": _cache[18] || (_cache[18] = $event => _unref(queryParamsExchange).pageNum = $event),
          limit: _unref(queryParamsExchange).pageSize,
          "onUpdate:limit": _cache[19] || (_cache[19] = $event => _unref(queryParamsExchange).pageSize = $event),
          onPagination: getExchangeList
        }, null, 8, ["total", "page", "limit"]), [[_vShow, exchangeTotal.value > 0]])]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};