/* unplugin-vue-components disabled */import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, isRef as _isRef, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4cae69ed"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "top-right-btn"
};
export default {
  __name: 'index',
  props: {
    showSearch: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array
    }
  },
  emits: ['update:showSearch', 'queryTable'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;

    // 显隐数据
    const value = ref([]);
    // 弹出层标题
    const title = ref("显示/隐藏");
    // 是否显示弹出层
    const open = ref(false);

    // 搜索
    function toggleSearch() {
      emits("update:showSearch", !props.showSearch);
    }

    // 刷新
    function refresh() {
      emits("queryTable");
    }

    // 右侧列表元素变化
    function dataChange(data) {
      for (let item in props.columns) {
        const key = props.columns[item].key;
        props.columns[item].visible = !data.includes(key);
      }
    }

    // 打开显隐列dialog
    function showColumn() {
      open.value = true;
    }

    // 显隐列初始默认隐藏列
    for (let item in props.columns) {
      if (props.columns[item].visible === false) {
        value.value.push(parseInt(item));
      }
    }
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_tooltip = _resolveComponent("el-tooltip");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_transfer = _resolveComponent("el-transfer");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, null, {
        default: _withCtx(() => [_createVNode(_component_el_tooltip, {
          class: "item",
          effect: "dark",
          content: __props.showSearch ? '隐藏搜索' : '显示搜索',
          placement: "top"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            circle: "",
            icon: "Search",
            onClick: _cache[0] || (_cache[0] = $event => toggleSearch())
          })]),
          _: 1
        }, 8, ["content"]), _createVNode(_component_el_tooltip, {
          class: "item",
          effect: "dark",
          content: "刷新",
          placement: "top"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            circle: "",
            icon: "Refresh",
            onClick: _cache[1] || (_cache[1] = $event => refresh())
          })]),
          _: 1
        }), __props.columns ? (_openBlock(), _createBlock(_component_el_tooltip, {
          key: 0,
          class: "item",
          effect: "dark",
          content: "显隐列",
          placement: "top"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            circle: "",
            icon: "Menu",
            onClick: _cache[2] || (_cache[2] = $event => showColumn())
          })]),
          _: 1
        })) : _createCommentVNode("", true)]),
        _: 1
      }), _createVNode(_component_el_dialog, {
        title: _unref(title),
        modelValue: _unref(open),
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _isRef(open) ? open.value = $event : null),
        "append-to-body": ""
      }, {
        default: _withCtx(() => [_createVNode(_component_el_transfer, {
          titles: ['显示', '隐藏'],
          modelValue: _unref(value),
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _isRef(value) ? value.value = $event : null),
          data: __props.columns,
          onChange: dataChange
        }, null, 8, ["modelValue", "data"])]),
        _: 1
      }, 8, ["title", "modelValue"])]);
    };
  }
};