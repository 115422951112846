import request from "@/utils/request";
// const baseURL = "http://192.168.6.158:20100/";

//广告位管理新增
export function addAdvertise(data) {
  return request({
    url: "/yg-plat-manage/advertise/add",
    method: "post",
    data: data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//广告位管理修改
export function updateAdvertise(data) {
  return request({
    url: "/yg-plat-manage/advertise/update",
    method: "post",
    data: data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//广告位管理删除
export function deleteAdvertise(data) {
  return request({
    url: "/yg-plat-manage/advertise/delete",
    method: "post",
    data: data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//广告位管理分页列表
export function queryAdvertisePageList(data) {
  return request({
    url: "/yg-plat-manage/advertise/queryPage",
    method: "post",
    data: data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//广告位管理列表详情
export function findAdvertise(data) {
  return request({
    url: "/yg-plat-manage/advertise/find",
    method: "get",
    params: data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//应用管理列表详情
export function findMemAppInfo(data) {
  return request({
    url: "/yg-plat-manage/app/find",
    method: "get",
    params: data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//应用管理新增
export function addMemAppInfo(data) {
  return request({
    url: "/yg-plat-manage/app/add",
    method: "post",
    data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//应用管理修改
export function updateMemAppInfo(data) {
  return request({
    url: "/yg-plat-manage/app/update",
    method: "post",
    data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//应用管理删除
export function deleteMemAppInfo(data) {
  return request({
    url: "/yg-plat-manage/app/delete",
    method: "post",
    data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//应用管理全部列表
export function queryAllMemAppInfoList(data) {
  return request({
    url: "/yg-plat-manage/app/queryList",
    method: "post",
    data,
    headers: {
      repeatSubmit: false,
    },
  });
}

//应用管理分页列表
export function queryMemAppInfoPageList(data) {
  return request({
    url: "/yg-plat-manage/app/queryPage",
    method: "post",
    data,
    headers: {
      repeatSubmit: false,
    },
  });
}
//消息列表
export function messageQueryPage(data) {
  return request({
    url: "/yg-plat-manage/message/queryPage",
    method: "post",
    data,
    headers: {
      repeatSubmit: false,
    },
  });
}
//消息新增
export function messageAdd(data) {
  return request({
    url: "/yg-plat-manage/message/add",
    method: "post",
    data,
    headers: {
      repeatSubmit: false,
    },
  });
}
//商户
export function queryPlatMerchantByAll(data) {
  return request({
    url: "/yg-plat/merchant/queryPlatMerchantByAll",
    method: "post",
    data,
    headers: {
      repeatSubmit: false,
    },
  });
}
//门店
export function queryShopInfoByAll(data) {
  return request({
    url: "/yg-plat/shop/queryShopInfoByAll",
    method: "post",
    data,
    headers: {
      repeatSubmit: false,
    },
  });
}
//会员列表
export function staffBindInfoList(data) {
  return request({
    url: "/yg-plat-manage/staffBindInfo/queryList",
    method: "post",
    data,
  });
}
