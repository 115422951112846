/* unplugin-vue-components disabled */import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "version-container"
};
import Employee from "./components/Employee";
import Integral from "./components/Integral";
import Medal from "./components/Medal";
import PointsMall from "./components/PointsMall";
import IncomeClassification from "./components/IncomeClassification";
import PositionAllocation from "./components/PositionAllocation";
import memberTag from "./components/memberTag";
export default {
  __name: 'index',
  setup(__props) {
    const activeName = ref("first");
    return (_ctx, _cache) => {
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");
      const _component_el_tabs = _resolveComponent("el-tabs");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_tabs, {
        type: "border-card",
        modelValue: _unref(activeName),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(activeName) ? activeName.value = $event : null)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
          label: "员工管理",
          name: "first"
        }, {
          default: _withCtx(() => [_unref(activeName) == 'first' ? (_openBlock(), _createBlock(_unref(Employee), {
            key: 0
          })) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_tab_pane, {
          label: "积分管理",
          name: "second"
        }, {
          default: _withCtx(() => [_unref(activeName) == 'second' ? (_openBlock(), _createBlock(_unref(Integral), {
            key: 0
          })) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_tab_pane, {
          label: "积分商城",
          name: "third"
        }, {
          default: _withCtx(() => [_unref(activeName) == 'third' ? (_openBlock(), _createBlock(_unref(PointsMall), {
            key: 0
          })) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_tab_pane, {
          label: "奖章列表",
          name: "four"
        }, {
          default: _withCtx(() => [_unref(activeName) == 'four' ? (_openBlock(), _createBlock(_unref(Medal), {
            key: 0
          })) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_tab_pane, {
          label: "收入分类",
          name: "five"
        }, {
          default: _withCtx(() => [_unref(activeName) == 'five' ? (_openBlock(), _createBlock(_unref(IncomeClassification), {
            key: 0
          })) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_tab_pane, {
          label: "会员标签",
          name: "seven"
        }, {
          default: _withCtx(() => [_unref(activeName) == 'seven' ? (_openBlock(), _createBlock(_unref(memberTag), {
            key: 0
          })) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};