/* unplugin-vue-components disabled */import __unplugin_components_1 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Editor/index.vue';
import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, isRef as _isRef, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4bcfb2af"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dialog-footer"
};
import { queryVersionDefPage, insertVersionDef, findVersionDefByCode, updateVersionDefByCode, delVersionDefByCode } from "@/api/charge/index";
export default {
  __name: 'Paidversion',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const dialogFormVisible = ref(false);
    const loading = ref(false);
    const total = ref(0);
    const formRef = ref();
    const title = ref('新增收费版本');
    const transitionDescription = ref("");
    const data = reactive({
      tableData: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      form: {
        description: null
      },
      rules: {
        name: [{
          required: true,
          message: '版本名称不能为空',
          trigger: 'blur'
        }],
        serverTime: [{
          required: true,
          message: '服务时长不能为空',
          trigger: 'blur'
        }],
        name3: [{
          required: true,
          message: '',
          trigger: 'blur'
        }]
        // name4:[{required:true,message:'',trigger:'blur'}]
      }
    });

    const {
      tableData,
      queryParams,
      form,
      rules
    } = toRefs(data);
    const handleAdd = () => {
      dialogFormVisible.value = true;
    };
    const cancel = () => {
      dialogFormVisible.value = false;
      reset();
    };
    const submit = formEl => {
      console.log(form.value);
      if (!formEl) return;
      formEl.validate(valid => {
        if (valid) {
          if (form.value.id) {
            // 修改
            edit();
          } else {
            // 新增
            add();
          }
        } else {}
      });
    };
    const closeDialog = () => {
      reset();
      dialogFormVisible.value = false;
    };
    const getDataByCode = row => {
      return findVersionDefByCode({
        code: row.code
      });
    };

    // 查询收费版本
    const getList = () => {
      loading.value = true;
      queryVersionDefPage(queryParams.value).then(res => {
        tableData.value = res.data;
        loading.value = false;
        total.value = res.total;
      });
    };

    // 新增收费版本
    const add = () => {
      form.value.description = encodeURIComponent(form.value.transitionDescription);
      insertVersionDef(form.value).then(res => {
        dialogFormVisible.value = false;
        reset();
        getList();
        proxy.$modal.msgSuccess("新增成功");
      });
    };

    // 编辑收费版本
    const edit = () => {
      form.value.description = encodeURIComponent(form.value.transitionDescription);
      updateVersionDefByCode(form.value).then(res => {
        dialogFormVisible.value = false;
        reset();
        getList();
        proxy.$modal.msgSuccess("修改成功");
      });
    };
    const reset = () => {
      form.value = {
        name: null,
        serverTime: null,
        sellPrice: null,
        remark: null,
        description: null,
        remark: null
      };
      proxy.resetForm(formRef);
    };
    const handleQuery = () => {
      queryParams.value.pageNum = 1;
      getList();
    };
    const handleUpdate = async row => {
      const {
        data
      } = await getDataByCode(row);
      console.log(data, 'data');
      // data.description = "<p>"+ data.description+"</p>"
      form.value = data;
      form.value.transitionDescription = form.value.description;
      dialogFormVisible.value = true;
    };
    const handleDelete = row => {
      const {
        code
      } = row;
      proxy.$modal.confirm('是否确认删除?').then(function () {
        return delVersionDefByCode({
          code
        });
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    };
    getList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _component_Editor = __unplugin_components_1;
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, {
        gutter: 2
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(queryParams),
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
          inline: true,
          ref: "queryParamsRef"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "版本名称",
            prop: "name"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(queryParams).name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).name = $event),
              clearable: "",
              placeholder: "请输入收费版本名称"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            prop: "name"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: "Search",
              plain: "",
              type: "primary",
              onClick: handleQuery
            }, {
              default: _withCtx(() => [_createTextVNode("搜索")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }), _createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            icon: "Plus",
            plain: "",
            type: "primary",
            onClick: handleAdd
          }, {
            default: _withCtx(() => [_createTextVNode("新增")]),
            _: 1
          })]),
          _: 1
        }, 8, ["span"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData),
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "id",
          label: "id",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "name",
          label: "版本名称",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "serverTime",
          label: "服务时长(天)",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "sellPrice",
          label: "版本价格",
          width: ""
        }), _createVNode(_component_el_table_column, {
          fixed: "right",
          label: "操作",
          width: "120"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleUpdate(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleDelete(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[2] || (_cache[2] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[3] || (_cache[3] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_component_el_dialog, {
        modelValue: _unref(dialogFormVisible),
        "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => _isRef(dialogFormVisible) ? dialogFormVisible.value = $event : null),
        "before-close": closeDialog,
        title: _unref(title),
        "append-to-body": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          onClick: _cache[10] || (_cache[10] = $event => cancel(_unref(formRef)))
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[11] || (_cache[11] = $event => submit(_unref(formRef)))
        }, {
          default: _withCtx(() => [_createTextVNode("提交")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(form),
          ref_key: "formRef",
          ref: formRef,
          rules: _unref(rules),
          "label-width": "80"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "版本名称",
            prop: "name"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).name,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _unref(form).name = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "服务时长",
            prop: "serverTime"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).serverTime,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _unref(form).serverTime = $event),
              type: "number",
              autocomplete: "off"
            }, {
              append: _withCtx(() => [_createTextVNode("天")]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "版本价格",
            prop: "sellPrice"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).sellPrice,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _unref(form).sellPrice = $event),
              type: "number",
              autocomplete: "off"
            }, {
              append: _withCtx(() => [_createTextVNode("元")]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "说明",
            prop: "remark"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "textarea",
              modelValue: _unref(form).remark,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _unref(form).remark = $event),
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "版本介绍",
            prop: "description"
          }, {
            default: _withCtx(() => [_unref(dialogFormVisible) ? (_openBlock(), _createBlock(_component_Editor, {
              key: 0,
              modelValue: _unref(form).transitionDescription,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _unref(form).transitionDescription = $event),
              child: _unref(form).transitionDescription,
              "onUpdate:child": _cache[9] || (_cache[9] = $event => _unref(form).transitionDescription = $event)
            }, null, 8, ["modelValue", "child"])) : _createCommentVNode("", true)]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};