/* unplugin-vue-components disabled */import __unplugin_components_2 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import __unplugin_components_1 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/DictTag/index.vue';
import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/RightToolbar/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, isRef as _isRef, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "app-container"
};
import { list, delLogininfor, cleanLogininfor } from "@/api/monitor/logininfor";
export default {
  __name: 'index',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const {
      sys_common_status
    } = proxy.useDict("sys_common_status");
    const logininforList = ref([]);
    const loading = ref(true);
    const showSearch = ref(true);
    const ids = ref([]);
    const multiple = ref(true);
    const total = ref(0);
    const dateRange = ref([]);
    const defaultSort = ref({
      prop: "loginTime",
      order: "descending"
    });

    // 查询参数
    const queryParams = ref({
      pageNum: 1,
      pageSize: 10,
      ipaddr: undefined,
      userName: undefined,
      status: undefined,
      orderByColumn: undefined,
      isAsc: undefined
    });

    /** 查询登录日志列表 */
    function getList() {
      loading.value = true;
      list(proxy.addDateRange(queryParams.value, dateRange.value)).then(response => {
        logininforList.value = response.rows;
        total.value = response.total;
        loading.value = false;
      });
    }
    /** 搜索按钮操作 */
    function handleQuery() {
      queryParams.value.pageNum = 1;
      getList();
    }
    /** 重置按钮操作 */
    function resetQuery() {
      dateRange.value = [];
      proxy.resetForm("queryRef");
      proxy.$refs["logininforRef"].sort(defaultSort.value.prop, defaultSort.value.order);
      handleQuery();
    }
    /** 多选框选中数据 */
    function handleSelectionChange(selection) {
      ids.value = selection.map(item => item.infoId);
      multiple.value = !selection.length;
    }
    /** 排序触发事件 */
    function handleSortChange(column, prop, order) {
      queryParams.value.orderByColumn = column.prop;
      queryParams.value.isAsc = column.order;
      getList();
    }
    /** 删除按钮操作 */
    function handleDelete(row) {
      const infoIds = row.infoId || ids.value;
      proxy.$modal.confirm('是否确认删除访问编号为"' + infoIds + '"的数据项?').then(function () {
        return delLogininfor(infoIds);
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    }
    /** 清空按钮操作 */
    function handleClean() {
      proxy.$modal.confirm("是否确认清空所有登录日志数据项?").then(function () {
        return cleanLogininfor();
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("清空成功");
      }).catch(() => {});
    }
    /** 导出按钮操作 */
    function handleExport() {
      proxy.download("monitor/logininfor/export", {
        ...queryParams.value
      }, `config_${new Date().getTime()}.xlsx`);
    }
    getList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_date_picker = _resolveComponent("el-date-picker");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_col = _resolveComponent("el-col");
      const _component_right_toolbar = __unplugin_components_0;
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_dict_tag = __unplugin_components_1;
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_2;
      const _directive_hasPermi = _resolveDirective("hasPermi");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_el_form, {
        model: _unref(queryParams),
        ref: "queryRef",
        inline: true,
        "label-width": "68px"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "登录地址",
          prop: "ipaddr"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).ipaddr,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).ipaddr = $event),
            placeholder: "请输入登录地址",
            clearable: "",
            style: {
              "width": "240px"
            },
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "用户名称",
          prop: "userName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).userName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).userName = $event),
            placeholder: "请输入用户名称",
            clearable: "",
            style: {
              "width": "240px"
            },
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "状态",
          prop: "status"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: _unref(queryParams).status,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(queryParams).status = $event),
            placeholder: "登录状态",
            clearable: "",
            style: {
              "width": "240px"
            }
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sys_common_status), dict => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: dict.value,
                label: dict.label,
                value: dict.value
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "登录时间",
          style: {
            "width": "308px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_date_picker, {
            modelValue: _unref(dateRange),
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _isRef(dateRange) ? dateRange.value = $event : null),
            "value-format": "YYYY-MM-DD",
            type: "daterange",
            "range-separator": "-",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            icon: "Search",
            onClick: handleQuery
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          }), _createVNode(_component_el_button, {
            icon: "Refresh",
            onClick: resetQuery
          }, {
            default: _withCtx(() => [_createTextVNode("重置")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), [[_vShow, _unref(showSearch)]]), _createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "danger",
            plain: "",
            icon: "Delete",
            disabled: _unref(multiple),
            onClick: handleDelete
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 1
          }, 8, ["disabled"])), [[_directive_hasPermi, ['system:logininfor:remove']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "danger",
            plain: "",
            icon: "Delete",
            onClick: handleClean
          }, {
            default: _withCtx(() => [_createTextVNode("清空")]),
            _: 1
          })), [[_directive_hasPermi, ['system:logininfor:remove']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "warning",
            plain: "",
            icon: "Download",
            onClick: handleExport
          }, {
            default: _withCtx(() => [_createTextVNode("导出")]),
            _: 1
          })), [[_directive_hasPermi, ['system:logininfor:export']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_right_toolbar, {
          showSearch: _unref(showSearch),
          "onUpdate:showSearch": _cache[4] || (_cache[4] = $event => _isRef(showSearch) ? showSearch.value = $event : null),
          onQueryTable: getList
        }, null, 8, ["showSearch"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        ref: "logininforRef",
        data: _unref(logininforList),
        onSelectionChange: handleSelectionChange,
        "default-sort": _unref(defaultSort),
        onSortChange: handleSortChange
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          type: "selection",
          width: "55",
          align: "center"
        }), _createVNode(_component_el_table_column, {
          label: "访问编号",
          align: "center",
          prop: "infoId"
        }), _createVNode(_component_el_table_column, {
          label: "用户名称",
          align: "center",
          prop: "userName",
          "show-overflow-tooltip": true,
          sortable: "custom",
          "sort-orders": ['descending', 'ascending']
        }), _createVNode(_component_el_table_column, {
          label: "地址",
          align: "center",
          prop: "ipaddr",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "登录地点",
          align: "center",
          prop: "loginLocation",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "操作系统",
          align: "center",
          prop: "os",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "浏览器",
          align: "center",
          prop: "browser",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "登录状态",
          align: "center",
          prop: "status"
        }, {
          default: _withCtx(scope => [_createVNode(_component_dict_tag, {
            options: _unref(sys_common_status),
            value: scope.row.status
          }, null, 8, ["options", "value"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "描述",
          align: "center",
          prop: "msg"
        }), _createVNode(_component_el_table_column, {
          label: "访问时间",
          align: "center",
          prop: "loginTime",
          sortable: "custom",
          "sort-orders": ['descending', 'ascending'],
          width: "180"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(_ctx.parseTime(scope.row.loginTime)), 1)]),
          _: 1
        })]),
        _: 1
      }, 8, ["data", "default-sort"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[5] || (_cache[5] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[6] || (_cache[6] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]])]);
    };
  }
};