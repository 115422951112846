import request from '@/utils/request'
const base = "/mock-api"
// 发票上传
export function invoiceUpload(data) {
  return request({
    url: '/tool/gen/list',
    method: 'post',
    data
  })
}


// 查询发票列表

export function queryPlatOpenInvoiceDetailPageList(data) {
  return request({
    // url: '/yg-plat-manage/platOpenInvoiceDetail/queryPlatOpenInvoiceDetailPageList',
    url: '/yg-plat-manage/platOpenInvoiceDetail/queryPlatOpenInvoiceDetailPageList',
    method: 'post',
    data,
    // baseURL:`${base}`
  })
}


// 上传发票

export function updatePlatOpenInvoiceDetailById(data) {
  return request({
    // url: '/yg-plat-manage/platOpenInvoiceDetail/updatePlatOpenInvoiceDetailById',
    url: '/yg-plat-manage/platOpenInvoiceDetail/updatePlatOpenInvoiceDetailById',
    method: 'post',
    data,
    // baseURL:`${base}`
  })
}
