/* unplugin-vue-components disabled */import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/SvgIcon/index.vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { constantRoutes } from "@/router";
import { isHttp } from '@/utils/validate';
import useAppStore from '@/store/modules/app';
import useSettingsStore from '@/store/modules/settings';
import usePermissionStore from '@/store/modules/permission';

// 顶部栏初始数

export default {
  __name: 'index',
  setup(__props) {
    const visibleNumber = ref(null);
    // 当前激活菜单的 index
    const currentIndex = ref(null);
    // 隐藏侧边栏路由
    const hideList = ['/index', '/user/profile'];
    const appStore = useAppStore();
    const settingsStore = useSettingsStore();
    const permissionStore = usePermissionStore();
    const route = useRoute();
    const router = useRouter();

    // 主题颜色
    const theme = computed(() => settingsStore.theme);
    // 所有的路由信息
    const routers = computed(() => permissionStore.topbarRouters);

    // 顶部显示菜单
    const topMenus = computed(() => {
      let topMenus = [];
      routers.value.map(menu => {
        if (menu.hidden !== true) {
          // 兼容顶部栏一级菜单内部跳转
          if (menu.path === "/") {
            topMenus.push(menu.children[0]);
          } else {
            topMenus.push(menu);
          }
        }
      });
      return topMenus;
    });

    // 设置子路由
    const childrenMenus = computed(() => {
      let childrenMenus = [];
      routers.value.map(router => {
        for (let item in router.children) {
          if (router.children[item].parentPath === undefined) {
            if (router.path === "/") {
              router.children[item].path = "/" + router.children[item].path;
            } else {
              if (!isHttp(router.children[item].path)) {
                router.children[item].path = router.path + "/" + router.children[item].path;
              }
            }
            router.children[item].parentPath = router.path;
          }
          childrenMenus.push(router.children[item]);
        }
      });
      return constantRoutes.concat(childrenMenus);
    });

    // 默认激活的菜单
    const activeMenu = computed(() => {
      const path = route.path;
      let activePath = path;
      if (path !== undefined && path.lastIndexOf("/") > 0 && hideList.indexOf(path) === -1) {
        const tmpPath = path.substring(1, path.length);
        activePath = "/" + tmpPath.substring(0, tmpPath.indexOf("/"));
        appStore.toggleSideBarHide(false);
      } else if (!route.children) {
        activePath = path;
        appStore.toggleSideBarHide(true);
      }
      activeRoutes(activePath);
      return activePath;
    });
    function setVisibleNumber() {
      const width = document.body.getBoundingClientRect().width / 3;
      visibleNumber.value = parseInt(width / 85);
    }
    function handleSelect(key, keyPath) {
      currentIndex.value = key;
      const route = routers.value.find(item => item.path === key);
      if (isHttp(key)) {
        // http(s):// 路径新窗口打开
        window.open(key, "_blank");
      } else if (!route || !route.children) {
        // 没有子路由路径内部打开
        router.push({
          path: key
        });
        appStore.toggleSideBarHide(true);
      } else {
        // 显示左侧联动菜单
        activeRoutes(key);
        appStore.toggleSideBarHide(false);
      }
    }
    function activeRoutes(key) {
      let routes = [];
      if (childrenMenus.value && childrenMenus.value.length > 0) {
        childrenMenus.value.map(item => {
          if (key == item.parentPath || key == "index" && "" == item.path) {
            routes.push(item);
          }
        });
      }
      if (routes.length > 0) {
        permissionStore.setSidebarRouters(routes);
      }
      return routes;
    }
    onMounted(() => {
      window.addEventListener('resize', setVisibleNumber);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', setVisibleNumber);
    });
    onMounted(() => {
      setVisibleNumber();
    });
    return (_ctx, _cache) => {
      const _component_svg_icon = __unplugin_components_0;
      const _component_el_menu_item = _resolveComponent("el-menu-item");
      const _component_el_sub_menu = _resolveComponent("el-sub-menu");
      const _component_el_menu = _resolveComponent("el-menu");
      return _openBlock(), _createBlock(_component_el_menu, {
        "default-active": _unref(activeMenu),
        mode: "horizontal",
        onSelect: handleSelect
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(topMenus), (item, index) => {
          return _openBlock(), _createElementBlock(_Fragment, null, [index < _unref(visibleNumber) ? (_openBlock(), _createBlock(_component_el_menu_item, {
            style: _normalizeStyle({
              '--theme': _unref(theme)
            }),
            index: item.path,
            key: index
          }, {
            default: _withCtx(() => [_createVNode(_component_svg_icon, {
              "icon-class": item.meta.icon
            }, null, 8, ["icon-class"]), _createTextVNode(" " + _toDisplayString(item.meta.title), 1)]),
            _: 2
          }, 1032, ["style", "index"])) : _createCommentVNode("", true)], 64);
        }), 256)), _unref(topMenus).length > _unref(visibleNumber) ? (_openBlock(), _createBlock(_component_el_sub_menu, {
          key: 0,
          style: _normalizeStyle({
            '--theme': _unref(theme)
          }),
          index: "more"
        }, {
          title: _withCtx(() => [_createTextVNode("更多菜单")]),
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(topMenus), (item, index) => {
            return _openBlock(), _createElementBlock(_Fragment, null, [index >= _unref(visibleNumber) ? (_openBlock(), _createBlock(_component_el_menu_item, {
              index: item.path,
              key: index
            }, {
              default: _withCtx(() => [_createVNode(_component_svg_icon, {
                "icon-class": item.meta.icon
              }, null, 8, ["icon-class"]), _createTextVNode(" " + _toDisplayString(item.meta.title), 1)]),
              _: 2
            }, 1032, ["index"])) : _createCommentVNode("", true)], 64);
          }), 256))]),
          _: 1
        }, 8, ["style"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["default-active"]);
    };
  }
};