/* unplugin-vue-components disabled */import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue";
import { useWindowSize } from '@vueuse/core';
import Sidebar from './components/Sidebar/index.vue';
import { AppMain, Navbar, Settings, TagsView } from './components';
import defaultSettings from '@/settings';
import useAppStore from '@/store/modules/app';
import useSettingsStore from '@/store/modules/settings';
export default {
  __name: 'index',
  setup(__props) {
    const settingsStore = useSettingsStore();
    const theme = computed(() => settingsStore.theme);
    const sideTheme = computed(() => settingsStore.sideTheme);
    const sidebar = computed(() => useAppStore().sidebar);
    const device = computed(() => useAppStore().device);
    const needTagsView = computed(() => settingsStore.tagsView);
    const fixedHeader = computed(() => settingsStore.fixedHeader);
    const classObj = computed(() => ({
      hideSidebar: !sidebar.value.opened,
      openSidebar: sidebar.value.opened,
      withoutAnimation: sidebar.value.withoutAnimation,
      mobile: device.value === 'mobile'
    }));
    const {
      width,
      height
    } = useWindowSize();
    const WIDTH = 992; // refer to Bootstrap's responsive design

    watchEffect(() => {
      if (device.value === 'mobile' && sidebar.value.opened) {
        useAppStore().closeSideBar({
          withoutAnimation: false
        });
      }
      if (width.value - 1 < WIDTH) {
        useAppStore().toggleDevice('mobile');
        useAppStore().closeSideBar({
          withoutAnimation: true
        });
      } else {
        useAppStore().toggleDevice('desktop');
      }
    });
    function handleClickOutside() {
      useAppStore().closeSideBar({
        withoutAnimation: false
      });
    }
    const settingRef = ref(null);
    function setLayout() {
      settingRef.value.openSetting();
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass([_unref(classObj), "app-wrapper"]),
        style: _normalizeStyle({
          '--current-color': _unref(theme)
        })
      }, [_unref(device) === 'mobile' && _unref(sidebar).opened ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "drawer-bg",
        onClick: handleClickOutside
      })) : _createCommentVNode("", true), !_unref(sidebar).hide ? (_openBlock(), _createBlock(Sidebar, {
        key: 1,
        class: "sidebar-container"
      })) : _createCommentVNode("", true), _createElementVNode("div", {
        class: _normalizeClass([{
          hasTagsView: _unref(needTagsView),
          sidebarHide: _unref(sidebar).hide
        }, "main-container"])
      }, [_createElementVNode("div", {
        class: _normalizeClass({
          'fixed-header': _unref(fixedHeader)
        })
      }, [_createVNode(_unref(Navbar), {
        onSetLayout: setLayout
      }), _unref(needTagsView) ? _withDirectives((_openBlock(), _createBlock(_unref(TagsView), {
        key: 0
      }, null, 512)), [[_vShow, false]]) : _createCommentVNode("", true)], 2), _createVNode(_unref(AppMain)), _createVNode(_unref(Settings), {
        ref_key: "settingRef",
        ref: settingRef
      }, null, 512)], 2)], 6);
    };
  }
};