/* unplugin-vue-components disabled */import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-25e88d90"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  id: "tags-view-container",
  class: "tags-view-container"
};
const _hoisted_2 = ["onClick"];
import ScrollPane from './ScrollPane';
import { getNormalPath } from '@/utils/ruoyi';
import useTagsViewStore from '@/store/modules/tagsView';
import useSettingsStore from '@/store/modules/settings';
import usePermissionStore from '@/store/modules/permission';
export default {
  __name: 'index',
  setup(__props) {
    const visible = ref(false);
    const top = ref(0);
    const left = ref(0);
    const selectedTag = ref({});
    const affixTags = ref([]);
    const scrollPaneRef = ref(null);
    const {
      proxy
    } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const visitedViews = computed(() => useTagsViewStore().visitedViews);
    const routes = computed(() => usePermissionStore().routes);
    const theme = computed(() => useSettingsStore().theme);
    watch(route, () => {
      addTags();
      moveToCurrentTag();
    });
    watch(visible, value => {
      if (value) {
        document.body.addEventListener('click', closeMenu);
      } else {
        document.body.removeEventListener('click', closeMenu);
      }
    });
    onMounted(() => {
      initTags();
      addTags();
    });
    function isActive(r) {
      return r.path === route.path;
    }
    function activeStyle(tag) {
      if (!isActive(tag)) return {};
      return {
        "background-color": theme.value,
        "border-color": theme.value
      };
    }
    function isAffix(tag) {
      return tag.meta && tag.meta.affix;
    }
    function isFirstView() {
      try {
        return selectedTag.value.fullPath === visitedViews.value[1].fullPath || selectedTag.value.fullPath === '/index';
      } catch (err) {
        return false;
      }
    }
    function isLastView() {
      try {
        return selectedTag.value.fullPath === visitedViews.value[visitedViews.value.length - 1].fullPath;
      } catch (err) {
        return false;
      }
    }
    function filterAffixTags(routes, basePath = '') {
      let tags = [];
      routes.forEach(route => {
        if (route.meta && route.meta.affix) {
          const tagPath = getNormalPath(basePath + '/' + route.path);
          tags.push({
            fullPath: tagPath,
            path: tagPath,
            name: route.name,
            meta: {
              ...route.meta
            }
          });
        }
        if (route.children) {
          const tempTags = filterAffixTags(route.children, route.path);
          if (tempTags.length >= 1) {
            tags = [...tags, ...tempTags];
          }
        }
      });
      return tags;
    }
    function initTags() {
      const res = filterAffixTags(routes.value);
      affixTags.value = res;
      for (const tag of res) {
        // Must have tag name
        if (tag.name) {
          useTagsViewStore().addVisitedView(tag);
        }
      }
    }
    function addTags() {
      const {
        name
      } = route;
      if (name) {
        useTagsViewStore().addView(route);
      }
      return false;
    }
    function moveToCurrentTag() {
      nextTick(() => {
        for (const r of visitedViews.value) {
          if (r.path === route.path) {
            scrollPaneRef.value.moveToTarget(r);
            // when query is different then update
            if (r.fullPath !== route.fullPath) {
              useTagsViewStore().updateVisitedView(route);
            }
          }
        }
      });
    }
    function refreshSelectedTag(view) {
      proxy.$tab.refreshPage(view);
    }
    function closeSelectedTag(view) {
      proxy.$tab.closePage(view).then(({
        visitedViews
      }) => {
        if (isActive(view)) {
          toLastView(visitedViews, view);
        }
      });
    }
    function closeRightTags() {
      proxy.$tab.closeRightPage(selectedTag.value).then(visitedViews => {
        if (!visitedViews.find(i => i.fullPath === route.fullPath)) {
          toLastView(visitedViews);
        }
      });
    }
    function closeLeftTags() {
      proxy.$tab.closeLeftPage(selectedTag.value).then(visitedViews => {
        if (!visitedViews.find(i => i.fullPath === route.fullPath)) {
          toLastView(visitedViews);
        }
      });
    }
    function closeOthersTags() {
      router.push(selectedTag.value).catch(() => {});
      proxy.$tab.closeOtherPage(selectedTag.value).then(() => {
        moveToCurrentTag();
      });
    }
    function closeAllTags(view) {
      proxy.$tab.closeAllPage().then(({
        visitedViews
      }) => {
        if (affixTags.value.some(tag => tag.path === route.path)) {
          return;
        }
        toLastView(visitedViews, view);
      });
    }
    function toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0];
      if (latestView) {
        router.push(latestView.fullPath);
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === 'Dashboard') {
          // to reload home page
          router.replace({
            path: '/redirect' + view.fullPath
          });
        } else {
          router.push('/');
        }
      }
    }
    function openMenu(tag, e) {
      const menuMinWidth = 105;
      const offsetLeft = proxy.$el.getBoundingClientRect().left; // container margin left
      const offsetWidth = proxy.$el.offsetWidth; // container width
      const maxLeft = offsetWidth - menuMinWidth; // left boundary
      const l = e.clientX - offsetLeft + 15; // 15: margin right

      if (l > maxLeft) {
        left.value = maxLeft;
      } else {
        left.value = l;
      }
      top.value = e.clientY;
      visible.value = true;
      selectedTag.value = tag;
    }
    function closeMenu() {
      visible.value = false;
    }
    function handleScroll() {
      closeMenu();
    }
    return (_ctx, _cache) => {
      const _component_close = _resolveComponent("close");
      const _component_router_link = _resolveComponent("router-link");
      const _component_refresh_right = _resolveComponent("refresh-right");
      const _component_circle_close = _resolveComponent("circle-close");
      const _component_back = _resolveComponent("back");
      const _component_right = _resolveComponent("right");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(ScrollPane), {
        ref_key: "scrollPaneRef",
        ref: scrollPaneRef,
        class: "tags-view-wrapper",
        onScroll: handleScroll
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(visitedViews), tag => {
          return _openBlock(), _createBlock(_component_router_link, {
            key: tag.path,
            "data-path": tag.path,
            class: _normalizeClass([isActive(tag) ? 'active' : '', "tags-view-item"]),
            to: {
              path: tag.path,
              query: tag.query,
              fullPath: tag.fullPath
            },
            style: _normalizeStyle(activeStyle(tag)),
            onMouseup: _withModifiers($event => !isAffix(tag) ? closeSelectedTag(tag) : '', ["middle"]),
            onContextmenu: _withModifiers($event => openMenu(tag, $event), ["prevent"])
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(tag.title) + " ", 1), !isAffix(tag) ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              onClick: _withModifiers($event => closeSelectedTag(tag), ["prevent", "stop"])
            }, [_createVNode(_component_close, {
              class: "el-icon-close",
              style: {
                "width": "1em",
                "height": "1em",
                "vertical-align": "middle"
              }
            })], 8, _hoisted_2)) : _createCommentVNode("", true)]),
            _: 2
          }, 1032, ["data-path", "class", "to", "style", "onMouseup", "onContextmenu"]);
        }), 128))]),
        _: 1
      }, 512), _withDirectives(_createElementVNode("ul", {
        style: _normalizeStyle({
          left: _unref(left) + 'px',
          top: _unref(top) + 'px'
        }),
        class: "contextmenu"
      }, [_createElementVNode("li", {
        onClick: _cache[0] || (_cache[0] = $event => refreshSelectedTag(_unref(selectedTag)))
      }, [_createVNode(_component_refresh_right, {
        style: {
          "width": "1em",
          "height": "1em"
        }
      }), _createTextVNode(" 刷新页面 ")]), !isAffix(_unref(selectedTag)) ? (_openBlock(), _createElementBlock("li", {
        key: 0,
        onClick: _cache[1] || (_cache[1] = $event => closeSelectedTag(_unref(selectedTag)))
      }, [_createVNode(_component_close, {
        style: {
          "width": "1em",
          "height": "1em"
        }
      }), _createTextVNode(" 关闭当前 ")])) : _createCommentVNode("", true), _createElementVNode("li", {
        onClick: closeOthersTags
      }, [_createVNode(_component_circle_close, {
        style: {
          "width": "1em",
          "height": "1em"
        }
      }), _createTextVNode(" 关闭其他 ")]), !isFirstView() ? (_openBlock(), _createElementBlock("li", {
        key: 1,
        onClick: closeLeftTags
      }, [_createVNode(_component_back, {
        style: {
          "width": "1em",
          "height": "1em"
        }
      }), _createTextVNode(" 关闭左侧 ")])) : _createCommentVNode("", true), !isLastView() ? (_openBlock(), _createElementBlock("li", {
        key: 2,
        onClick: closeRightTags
      }, [_createVNode(_component_right, {
        style: {
          "width": "1em",
          "height": "1em"
        }
      }), _createTextVNode(" 关闭右侧 ")])) : _createCommentVNode("", true), _createElementVNode("li", {
        onClick: _cache[2] || (_cache[2] = $event => closeAllTags(_unref(selectedTag)))
      }, [_createVNode(_component_circle_close, {
        style: {
          "width": "1em",
          "height": "1em"
        }
      }), _createTextVNode(" 全部关闭 ")])], 4), [[_vShow, _unref(visible)]])]);
    };
  }
};