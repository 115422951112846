/* unplugin-vue-components disabled */import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow, createElementVNode as _createElementVNode, isRef as _isRef, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
import { queryShopInfoListByPage, updateShopInfoByCode } from "@/api/merchantManagement/index";
export default {
  __name: 'merchant',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const total = ref(0);
    const loading = ref(false);
    const queryParams = ref({
      pageNum: 1,
      pageSize: 10
    });
    const accountVisible = ref(false);
    const accountFormRef = ref();
    const btnLoading = ref(false);
    const tableData = ref([]);
    const accountForm = ref({
      invitationSwitch: "off"
    });
    const handleQuery = () => {
      queryParams.value.pageNum = 1;
      getList();
    };
    const handleClick = row => {
      accountForm.value.code = row.code;
      accountForm.value.invitationSwitch = row.invitationSwitch;
      accountForm.value.merchantCode = row.merchantCode;
      accountForm.value.incrementFollowSwitch = row.incrementFollowSwitch;
      accountVisible.value = true;
    };
    const closeAccountDialog = () => {
      resetForm(accountFormRef.value);
    };
    const resetForm = formEl => {
      if (!formEl) return;
      formEl.resetFields();
      accountVisible.value = false;
    };
    const submitForm = async formEl => {
      if (!formEl) return;
      await formEl.validate(valid => {
        if (valid) {
          btnLoading.value = true;
          updateShopInfoByCode(accountForm.value).then(res => {
            proxy.$modal.msgSuccess("修改成功");
            accountVisible.value = false;
            btnLoading.value = false;
            getList();
          }).catch(err => {
            btnLoading.value = false;
          });
        }
      });
    };
    const getList = () => {
      loading.value = true;
      queryShopInfoListByPage(queryParams.value).then(res => {
        tableData.value = res.data;
        loading.value = false;
        total.value = res.total;
      }).catch(err => {
        loading.value = false;
      });
    };
    getList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _component_el_switch = _resolveComponent("el-switch");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_form, {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
        model: _unref(queryParams),
        ref: "queryRef",
        inline: true
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "商家名称",
          prop: "name"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).name = $event),
            placeholder: "请输入商家名称",
            clearable: "",
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            plain: "",
            icon: "Search",
            onClick: handleQuery
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData),
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "id",
          label: "id",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "name",
          label: "商家名称",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "shopAddr",
          label: "地址",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "shopAddrDetails",
          label: "详细地址",
          width: ""
        }), _createVNode(_component_el_table_column, {
          fixed: "right",
          align: "center",
          label: "操作",
          width: "120"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            class: "operation",
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleClick(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("编辑")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[2] || (_cache[2] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[3] || (_cache[3] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_component_el_dialog, {
        width: "30%",
        modelValue: _unref(accountVisible),
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _isRef(accountVisible) ? accountVisible.value = $event : null),
        "before-close": closeAccountDialog,
        title: "编辑"
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          onClick: _cache[6] || (_cache[6] = $event => resetForm(_unref(accountFormRef)))
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          loading: _unref(btnLoading),
          type: "primary",
          onClick: _cache[7] || (_cache[7] = $event => submitForm(_unref(accountFormRef)))
        }, {
          default: _withCtx(() => [_createTextVNode("提交")]),
          _: 1
        }, 8, ["loading"])])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "accountFormRef",
          ref: accountFormRef,
          model: _unref(accountForm),
          rules: _ctx.accountFormRules,
          "label-width": "80px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "邀约",
            prop: "invitationSwitch"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_switch, {
              modelValue: _unref(accountForm).invitationSwitch,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _unref(accountForm).invitationSwitch = $event),
              "active-text": "开 启",
              "inactive-text": "关 闭",
              "active-value": "on",
              "inactive-value": "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "增值回访",
            prop: "incrementFollowSwitch"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_switch, {
              modelValue: _unref(accountForm).incrementFollowSwitch,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _unref(accountForm).incrementFollowSwitch = $event),
              "active-text": "开 启",
              "inactive-text": "关 闭",
              "active-value": 1,
              "inactive-value": 0
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};