import request from '@/utils/request'

// 查询短信充值套餐数据
export function getSMS(query) {
  return request({
    url: '/yg-plat-manage/noteMealTemplate/queryNoteMealTemplateListByPage',
    method: 'post',
    data: query,
    // baseURL:'http://192.168.6.246:23100'
  })
}


// 新增短信充值套餐
export function addSMS(data) {
  return request({
    url: '/yg-plat-manage/noteMealTemplate/addNoteMealTemplate',
    method: 'post',
    data
  })
}

// 编辑短信充值套餐
export function editSMS(data) {
  return request({
    url: '/yg-plat-manage/noteMealTemplate/updateNoteMealTemplate',
    method: 'post',
    data
  })
}

// 删除短信充值套餐
export function delSMS(data) {
  return request({
    url: '/yg-plat-manage/noteMealTemplate/delNoteMealTemplate',
    method: 'post',
    params:data
  })
}


// 查询门店信息
export function queryShopInfoListByPage(data) {
  return request({
    url: '/yg-plat-manage/shopInfo/queryShopInfoListByPage',
    method: 'post',
    data,
    headers:{
      repeatSubmit:false
    }
  })
}

// 充值记录
export function queryNoteRechargeLog(data) {
  return request({
    url: '/yg-plat-manage/shopInfo/queryNoteRechargeLog',
    method: 'post',
    data,
  })
}

// 发送记录
export function queryNoteSendLog(data) {
  return request({
    url: '/yg-plat-manage/shopInfo/queryNoteSendLog',
    method: 'post',
    data,
  })
}


// 短信调账赠
export function noteNumAdjustAdd(data) {
  return request({
    url: '/yg-plat-manage/shopInfo/noteNumAdjustAdd',
    method: 'post',
    params:data,
  })
}

// 短信调账减
export function noteNumAdjustSub(data) {
  return request({
    url: '/yg-plat-manage/shopInfo/noteNumAdjustSub',
    method: 'post',
    params:data,
  })
}