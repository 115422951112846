import request from '@/utils/request'

// 获取收费版本列表
export function queryVersionDefPage(data) {
  return request({
    url: '/yg-plat-manage/versionDef/queryVersionDefPage',
    method: 'post',
    data: data,
    headers:{
      repeatSubmit:false
    }
    // baseURL:"http://192.168.6.246:20100"
  })
}


// 新增收费版本
export function insertVersionDef(data) {
  return request({
    url: '/yg-plat-manage/versionDef/insertVersionDef',
    method: 'post',
    data,
    // baseURL:"http://192.168.6.243:20100"
  })
}

// 根据code查找收费版本
export function findVersionDefByCode(data) {
  return request({
    url: '/yg-plat-manage/versionDef/findVersionDefByCode',
    method: 'get',
    params:data
  })
}

// 修改收费版本
export function updateVersionDefByCode(data) {
  return request({
    url: '/yg-plat-manage/versionDef/updateVersionDefByCode',
    method: 'post',
    data
  })
}


// 删除收费版本
export function delVersionDefByCode(data) {
  return request({
    url: '/yg-plat-manage/versionDef/delVersionDefByCode',
    method: 'get',
    params:data
  })
}




// 查询收费明细
export function queryVersionPayDetailPage(data) {
  return request({
    url: '/yg-plat-manage/versionPayDetail/queryVersionPayDetailPage',
    method: 'post',
    data
  })
}


// 查看购买记录by code
export function queryVersionPayDetailByCode(data) {
  return request({
    url: '/yg-plat-manage/versionPayDetail/queryVersionPayDetailByCode',
    method: 'get',
    params:data
  })
}


// 服务截止日期调整
export function updateServerEndTime(data) {
  return request({
    url: '/yg-plat-manage/versionPayDetail/updateServerEndTime',
    method: 'post',
    data
  })
}