/* unplugin-vue-components disabled */import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
export default {
  __name: 'Purchaserecord',
  props: {
    list: {
      type: Array,
      default: []
    }
  },
  setup(__props) {
    const props = __props;
    const loading = ref(false);
    const total = ref(0);
    const data = reactive({
      tableData: props.list,
      queryParams: {}
    });
    const {
      tableData,
      queryParams
    } = toRefs(data);
    function getList() {}
    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock(_Fragment, null, [_withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData),
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "id",
          label: "id",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "versionNo",
          label: "版本名称",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "payMoney",
          label: "支付金额",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "payType",
          label: "支付方式",
          width: ""
        }, {
          default: _withCtx(scope => [scope.row.payType == 'wechatPay' ? (_openBlock(), _createElementBlock("span", _hoisted_1, "微信支付")) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "serverStartTime",
          label: "购买时间",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "status",
          label: "状态",
          width: ""
        }, {
          default: _withCtx(scope => [scope.row.status == 'unpaid' ? (_openBlock(), _createElementBlock("span", _hoisted_2, "待支付")) : _createCommentVNode("", true), scope.row.status == 'paid' ? (_openBlock(), _createElementBlock("span", _hoisted_3, "已支付")) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[0] || (_cache[0] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[1] || (_cache[1] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]])], 64);
    };
  }
};