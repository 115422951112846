/* unplugin-vue-components disabled */import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue";
import Logo from './Logo';
import SidebarItem from './SidebarItem';
import variables from '@/assets/styles/variables.module.scss';
import useAppStore from '@/store/modules/app';
import useSettingsStore from '@/store/modules/settings';
import usePermissionStore from '@/store/modules/permission';
export default {
  __name: 'index',
  setup(__props) {
    const route = useRoute();
    const appStore = useAppStore();
    const settingsStore = useSettingsStore();
    const permissionStore = usePermissionStore();
    const sidebarRouters = computed(() => permissionStore.sidebarRouters);
    const showLogo = computed(() => settingsStore.sidebarLogo);
    const sideTheme = computed(() => settingsStore.sideTheme);
    const theme = computed(() => settingsStore.theme);
    const isCollapse = computed(() => !appStore.sidebar.opened);
    const activeMenu = computed(() => {
      const {
        meta,
        path
      } = route;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    });
    return (_ctx, _cache) => {
      const _component_el_menu = _resolveComponent("el-menu");
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass({
          'has-logo': _unref(showLogo)
        }),
        style: _normalizeStyle({
          backgroundColor: _unref(sideTheme) === 'theme-dark' ? _unref(variables).menuBackground : _unref(variables).menuLightBackground
        })
      }, [_unref(showLogo) ? (_openBlock(), _createBlock(_unref(Logo), {
        key: 0,
        collapse: _unref(isCollapse)
      }, null, 8, ["collapse"])) : _createCommentVNode("", true), _createVNode(_component_el_scrollbar, {
        class: _normalizeClass(_unref(sideTheme)),
        "wrap-class": "scrollbar-wrapper"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_menu, {
          "default-active": _unref(activeMenu),
          collapse: _unref(isCollapse),
          "background-color": _unref(sideTheme) === 'theme-dark' ? _unref(variables).menuBackground : _unref(variables).menuLightBackground,
          "text-color": _unref(sideTheme) === 'theme-dark' ? _unref(variables).menuColor : _unref(variables).menuLightColor,
          "unique-opened": true,
          "active-text-color": _unref(theme),
          "collapse-transition": false,
          mode: "vertical"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sidebarRouters), (route, index) => {
            return _openBlock(), _createBlock(_unref(SidebarItem), {
              key: route.path + index,
              item: route,
              "base-path": route.path
            }, null, 8, ["item", "base-path"]);
          }), 128))]),
          _: 1
        }, 8, ["default-active", "collapse", "background-color", "text-color", "active-text-color"])]),
        _: 1
      }, 8, ["class"])], 6);
    };
  }
};