/* unplugin-vue-components disabled */import __unplugin_components_2 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/AreaSelect/index.vue';
import __unplugin_components_1 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/ImageUpload/index.vue';
import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, createElementVNode as _createElementVNode, isRef as _isRef, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-43e5b6b9"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  key: 2
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  key: 0
};
const _hoisted_7 = {
  key: 1
};
const _hoisted_8 = {
  key: 2
};
const _hoisted_9 = {
  class: "dialog-footer"
};
import { queryMemUserInfoPageList, queryAllMemUserInfoList, addMemUserInfo, updateMemUserInfo, resetMemUserPwd, findMemUserInfo, deleteMemUserInfo, queryAppCategorySourceType, medalLogQueryList, queryShopList } from "@/api/employeeManagement/index";
export default {
  __name: 'Employee',
  setup(__props) {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("密码不能为空"));
      } else {
        if (form.value.rpwd !== "") {
          if (!formRef.value) return;
          formRef.value.validateField("rpwd", () => null);
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      console.log(value);
      console.log(form.pwd);
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== form.value.pwd) {
        callback(new Error("请输入相同的密码"));
      } else {
        callback();
      }
    };
    const {
      proxy
    } = getCurrentInstance();
    const dialogFormVisible = ref(false);
    const loading = ref(false);
    const btnLoading = ref(false);
    const dialogExchangeList = ref(false);
    const total = ref(0);
    const formRef = ref();
    const title = ref("新增员工");
    const formType = ref(null);
    const data = reactive({
      tableData: [],
      MedalList: [],
      sourceTypeArr: [],
      shopCodeListArr: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        phone: null,
        sourceType: null
      },
      form: {
        phone: null,
        nickName: null,
        realName: null,
        source: "backstage",
        pwd: null,
        rpwd: null,
        birthday: null,
        sex: "man",
        headImgUrl: null,
        design: null,
        province: null,
        city: null,
        area: null,
        addressMode: null,
        addressDetail: null,
        remark: null,
        sourceType: null
      },
      rules: {
        phone: [{
          required: true,
          message: "员工帐号不能为空",
          trigger: "blur"
        }, {
          pattern: /^1[3-9]\d{9}$/,
          message: "请输入正确的店员帐号(手机号)",
          trigger: "blur"
        }],
        pwd: [{
          validator: validatePass,
          trigger: "blur",
          required: true
        }, {
          min: 6,
          max: 24,
          message: "密码长度至少6位,最多24位",
          trigger: "blur"
        }],
        rpwd: [{
          validator: validatePass2,
          trigger: "blur",
          required: true
        }],
        sourceType: [{
          required: true,
          message: "平台类型不能为空",
          trigger: "blur"
        }],
        shopCodeList: [{
          required: true,
          message: "所属店铺不能为空",
          trigger: "blur"
        }]
      },
      statusOptions: [],
      sexOptions: [{
        key: "man",
        value: "男"
      }, {
        key: "girl",
        value: "女"
      }],
      sourceOptions: [{
        key: "backstage",
        value: "后台注册"
      }, {
        key: "app",
        value: "宠医宝注册"
      }]
    });
    const {
      tableData,
      queryParams,
      sourceTypeArr,
      shopCodeListArr,
      form,
      rules,
      sexOptions,
      MedalList
    } = toRefs(data);
    const handleAdd = () => {
      formType.value = "add";
      dialogFormVisible.value = true;
      reset();
      form.value.code = "";
      form.value.id = null;
    };
    const addressChange = val => {
      form.value.addressMode = val;
    };
    const cancel = () => {
      dialogFormVisible.value = false;
      reset();
    };
    const handleQuery = () => {
      queryParams.value.pageNum = 1;
      getList();
    };
    const handleMedal = () => {
      dialogExchangeList.value = true;
      medalLogQueryList({}).then(res => {
        MedalList.value = res.data;
      });
    };
    const submit = async formEl => {
      if (!formEl) return;
      await formEl.validate(valid => {
        if (valid) {
          if (form.value.id) {
            // 修改
            if (formType.value == "resetPwd") {
              //  重置密码
              resetPwd();
            } else {
              editUser();
            }
          } else {
            // 新增
            addUser();
          }
        }
      });
    };

    // 新增员工
    const addUser = () => {
      formatAddress("add");
      addMemUserInfo(form.value).then(res => {
        dialogFormVisible.value = false;
        getList();
        reset();
        proxy.$modal.msgSuccess("新增成功");
      });
    };

    // 新增或修改的时候处理省市区数据
    const formatAddress = type => {
      if (type == "add") {
        const addrArr = form.value.addressMode?.split("-");
        if (addrArr) {
          form.value.province = addrArr[0];
          form.value.city = addrArr[1];
          form.value.area = addrArr[2];
        }
      }
    };

    // 重置密码
    const handleResetPwd = rows => {
      const {
        phone,
        id
      } = rows;
      title.value = "重置密码";
      formType.value = "resetPwd";
      form.value.phone = phone;
      form.value.id = id;
      dialogFormVisible.value = true;
    };
    const resetPwd = () => {
      const sendData = {
        phone: form.value.phone,
        pwd: form.value.pwd
      };
      resetMemUserPwd(sendData).then(res => {
        dialogFormVisible.value = false;
        proxy.$modal.msgSuccess("重置成功！");
        getList();
        reset();
      });
    };

    // 修改员工
    const editUser = () => {
      const addrArr = form.value.addressMode.split("-");
      if (addrArr) {
        form.value.province = addrArr[0];
        form.value.city = addrArr[1];
        form.value.area = addrArr[2];
      }
      updateMemUserInfo(form.value).then(res => {
        dialogFormVisible.value = false;
        getList();
        reset();
        proxy.$modal.msgSuccess("修改成功！");
      });
    };

    // 关闭弹窗
    const closeDialog = () => {
      dialogFormVisible.value = false;
      reset();
    };

    // 查询员工列表
    const getList = () => {
      loading.value = true;
      console.log(queryParams.value, "queryParams.value");
      queryMemUserInfoPageList(queryParams.value).then(res => {
        try {
          tableData.value = res.data;
          total.value = res.total;
        } catch (error) {}
        loading.value = false;
      });
    };

    // 去修改
    const handleUpdate = rows => {
      formType.value = "updata";
      form.value.code = rows.code;
      getSingleVal(rows);
    };

    // 查询单个员工信息
    const getSingleVal = row => {
      findMemUserInfo({
        phone: row.phone,
        sourceType: row.sourceType
      }).then(res => {
        form.value = res.data;
        form.value.addressMode = form.value.province + "-" + form.value.city + "-" + form.value.area;
        dialogFormVisible.value = true;
      });
    };

    // 查询平台类型
    const sourceType = () => {
      queryAppCategorySourceType().then(res => {
        sourceTypeArr.value = res.data;
      });
    };

    // 查询平台类型
    const shopList = () => {
      queryShopList().then(res => {
        shopCodeListArr.value = res.data;
      });
    };
    const handleDelete = rows => {
      const {
        phone
      } = rows;
      proxy.$modal.confirm("是否确认删除?").then(function () {
        return deleteMemUserInfo({
          phone
        });
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(err => {
        console.log(err);
        // proxy.$modal.msgError(err.msg);
      });
    };

    // 重置表单
    const reset = () => {
      formRef.value?.resetFields();
    };
    getList();
    sourceType();
    shopList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _component_el_date_picker = _resolveComponent("el-date-picker");
      const _component_ImageUpload = __unplugin_components_1;
      const _component_AreaSelect = __unplugin_components_2;
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, {
        gutter: 2
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(queryParams),
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
          inline: true,
          ref: "queryParamsRef"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "员工帐号",
            prop: "phone"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(queryParams).phone,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).phone = $event),
              clearable: "",
              placeholder: "请输入员工帐号"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "平台类型",
            prop: "sourceType"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              clearable: "",
              modelValue: _unref(queryParams).sourceType,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).sourceType = $event),
              placeholder: "请选择平台类型"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sourceTypeArr), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.key,
                  label: item.value,
                  value: item.key
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            prop: "name"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: "Search",
              plain: "",
              type: "primary",
              onClick: handleQuery
            }, {
              default: _withCtx(() => [_createTextVNode("搜索 ")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }), _createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            icon: "Plus",
            plain: "",
            type: "primary",
            onClick: handleAdd
          }, {
            default: _withCtx(() => [_createTextVNode("新增员工 ")]),
            _: 1
          })]),
          _: 1
        }, 8, ["span"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData),
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "headImgUrl",
          align: "center",
          label: "头像",
          width: ""
        }, {
          default: _withCtx(scope => [scope.row.headImgUrl ? (_openBlock(), _createBlock(_component_el_image, {
            key: 0,
            style: {
              "width": "100px",
              "height": "100px"
            },
            src: scope.row.headImgUrl,
            "zoom-rate": 1.2,
            "initial-index": 1,
            fit: "cover"
          }, null, 8, ["src", "zoom-rate"])) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "realName",
          align: "center",
          label: "姓名",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "nickName",
          align: "center",
          label: "昵称",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "sex",
          align: "center",
          label: "性别",
          width: ""
        }, {
          default: _withCtx(scope => [scope.row.sex == 'man' ? (_openBlock(), _createElementBlock("span", _hoisted_1, "男")) : scope.row.sex == 'woman' ? (_openBlock(), _createElementBlock("span", _hoisted_2, "女")) : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(scope.row.sex), 1))]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "",
          align: "center",
          label: "地址",
          width: ""
        }, {
          default: _withCtx(scope => [scope.row.province ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(scope.row.province) + "-" + _toDisplayString(scope.row.city) + "-" + _toDisplayString(scope.row.area), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_5))]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "addressDetail",
          align: "center",
          label: "详细地址",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "phone",
          align: "center",
          label: "员工账号",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "source",
          align: "center",
          label: "注册来源",
          width: ""
        }, {
          default: _withCtx(scope => [scope.row.source == 'app' ? (_openBlock(), _createElementBlock("span", _hoisted_6, "宠医宝注册")) : _createCommentVNode("", true), scope.row.source == 'backstage' ? (_openBlock(), _createElementBlock("span", _hoisted_7, "后台注册")) : _createCommentVNode("", true), scope.row.source == 'pet_housekeep_app' ? (_openBlock(), _createElementBlock("span", _hoisted_8, "宠管家注册")) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "sourceType",
          align: "center",
          label: "平台类型"
        }, {
          default: _withCtx(scope => [_createTextVNode(_toDisplayString(_unref(sourceTypeArr).find(item => item.key === scope.row.sourceType).value), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "design",
          align: "center",
          label: "签名",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "createdTime",
          align: "center",
          label: "添加时间",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "remark",
          align: "center",
          label: "备注",
          width: ""
        }), _createVNode(_component_el_table_column, {
          fixed: "right",
          align: "center",
          label: "操作",
          width: "150"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleUpdate(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("修改 ")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleDelete(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("删除 ")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleResetPwd(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("重置密码 ")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleMedal(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("奖章记录 ")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[3] || (_cache[3] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[4] || (_cache[4] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_component_el_dialog, {
        modelValue: _unref(dialogFormVisible),
        "onUpdate:modelValue": _cache[23] || (_cache[23] = $event => _isRef(dialogFormVisible) ? dialogFormVisible.value = $event : null),
        "before-close": closeDialog,
        title: _unref(title)
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_9, [_createVNode(_component_el_button, {
          onClick: _cache[21] || (_cache[21] = $event => cancel(_unref(formRef)))
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[22] || (_cache[22] = $event => submit(_unref(formRef))),
          loading: _unref(btnLoading)
        }, {
          default: _withCtx(() => [_createTextVNode("提交")]),
          _: 1
        }, 8, ["loading"])])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(form),
          ref_key: "formRef",
          ref: formRef,
          rules: _unref(rules),
          "label-width": "80"
        }, {
          default: _withCtx(() => [_unref(formType) !== 'resetPwd' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "员工账号",
            prop: "phone"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).phone,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _unref(form).phone = $event),
              disabled: !!_unref(form).id
            }, null, 8, ["modelValue", "disabled"])]),
            _: 1
          })) : _createCommentVNode("", true), _unref(formType) !== 'resetPwd' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            label: "昵称",
            prop: "nickName"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).nickName,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _unref(form).nickName = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true), _unref(formType) !== 'resetPwd' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 2,
            label: "姓名",
            prop: "realName"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).realName,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _unref(form).realName = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true), !_unref(form).id || _unref(formType) == 'resetPwd' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 3,
            label: "登录密码",
            prop: "pwd"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "password",
              modelValue: _unref(form).pwd,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _unref(form).pwd = $event),
              "show-password": ""
            }, null, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true), !_unref(form).id || _unref(formType) == 'resetPwd' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 4,
            label: "确认密码",
            prop: "rpwd"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "password",
              modelValue: _unref(form).rpwd,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _unref(form).rpwd = $event),
              "show-password": ""
            }, null, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true), _unref(formType) !== 'resetPwd' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 5,
            label: "生日",
            prop: "birthday"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_date_picker, {
              style: {
                "width": "100%"
              },
              modelValue: _unref(form).birthday,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _unref(form).birthday = $event),
              type: "date",
              "value-format": "YYYY-MM-DD",
              placeholder: "请选择生日"
            }, null, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true), _unref(formType) !== 'resetPwd' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 6,
            label: "性别",
            prop: "sex"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: _unref(form).sex,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _unref(form).sex = $event),
              style: {
                "width": "100%"
              },
              class: "m-2",
              placeholder: "请选择性别"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sexOptions), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.key,
                  label: item.value,
                  value: item.key
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true), _unref(formType) !== 'resetPwd' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 7,
            label: "头像",
            prop: "headImgUrl"
          }, {
            default: _withCtx(() => [_createVNode(_component_ImageUpload, {
              limit: 1,
              modelValue: _unref(form).headImgUrl,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => _unref(form).headImgUrl = $event),
              fileSize: 35
            }, null, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true), _unref(formType) !== 'resetPwd' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 8,
            label: "签名",
            prop: "design"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "textarea",
              modelValue: _unref(form).design,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => _unref(form).design = $event),
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true), _unref(formType) !== 'resetPwd' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 9,
            label: "地址",
            prop: "addressMode"
          }, {
            default: _withCtx(() => [_unref(dialogFormVisible) ? (_openBlock(), _createBlock(_component_AreaSelect, {
              key: 0,
              addressMode: _unref(form).addressMode,
              "onUpdate:addressMode": _cache[14] || (_cache[14] = $event => _unref(form).addressMode = $event),
              modelValue: _unref(form).addressMode,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => _unref(form).addressMode = $event),
              onAddressChange: addressChange
            }, null, 8, ["addressMode", "modelValue"])) : _createCommentVNode("", true)]),
            _: 1
          })) : _createCommentVNode("", true), _unref(formType) !== 'resetPwd' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 10,
            label: "详细地址",
            prop: "addressDetail"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "text",
              modelValue: _unref(form).addressDetail,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => _unref(form).addressDetail = $event),
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true), _unref(formType) !== 'resetPwd' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 11,
            label: "备注",
            prop: "remark"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "textarea",
              modelValue: _unref(form).remark,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => _unref(form).remark = $event),
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true), _unref(formType) === 'add' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 12,
            label: "平台类型",
            prop: "sourceType"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              clearable: "",
              modelValue: _unref(form).sourceType,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => _unref(form).sourceType = $event),
              placeholder: "请选择平台类型"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sourceTypeArr), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.key,
                  label: item.value,
                  value: item.key
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true), _unref(formType) === 'updata' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 13,
            label: "平台类型",
            prop: "sourceType"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              clearable: "",
              disabled: "",
              modelValue: _unref(form).sourceType,
              "onUpdate:modelValue": _cache[19] || (_cache[19] = $event => _unref(form).sourceType = $event),
              placeholder: "请选择平台类型"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sourceTypeArr), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.key,
                  label: item.value,
                  value: item.key
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true), _unref(formType) !== 'resetPwd' && _unref(form).sourceType === 'cgj' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 14,
            label: "所属店铺",
            prop: "shopCodeList"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              clearable: "",
              "collapse-tags": "",
              multiple: "",
              modelValue: _unref(form).shopCodeList,
              "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => _unref(form).shopCodeList = $event),
              placeholder: "请选择所属店铺"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(shopCodeListArr), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.code,
                  label: item.name,
                  value: item.code
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"]), _createVNode(_component_el_dialog, {
        modelValue: _unref(dialogExchangeList),
        "onUpdate:modelValue": _cache[24] || (_cache[24] = $event => _isRef(dialogExchangeList) ? dialogExchangeList.value = $event : null),
        title: "奖章记录",
        width: "1000px"
      }, {
        default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_table, {
          "max-height": 650,
          data: _unref(MedalList)
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            prop: "icon",
            align: "center",
            label: "奖章图片",
            width: "130"
          }, {
            default: _withCtx(scope => [scope.row.appMedalConfig.icon ? (_openBlock(), _createBlock(_component_el_image, {
              key: 0,
              style: {
                "width": "100px",
                "height": "100px"
              },
              src: scope.row.appMedalConfig.icon,
              "zoom-rate": 1.2,
              "initial-index": 1,
              fit: "cover"
            }, null, 8, ["src", "zoom-rate"])) : _createCommentVNode("", true)]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "奖章名称",
            align: "center",
            "show-overflow-tooltip": ""
          }, {
            default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.appMedalConfig.name), 1)]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "应用名称",
            align: "center",
            "show-overflow-tooltip": ""
          }, {
            default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.appMedalConfig.appName), 1)]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "奖章类型",
            align: "center",
            "show-overflow-tooltip": ""
          }, {
            default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.appMedalConfig.type == "common" ? "通用奖章" : "应用定制奖章" || 0), 1)]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "状态",
            align: "center",
            "show-overflow-tooltip": ""
          }, {
            default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.appMedalConfig.status == "on" ? "开启" : "关闭"), 1)]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "创建时间",
            align: "center",
            "show-overflow-tooltip": "",
            prop: "createdTime"
          }, {
            default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.appMedalConfig.createdTime), 1)]),
            _: 1
          })]),
          _: 1
        }, 8, ["data"])), [[_directive_loading, _ctx.Exloading]])]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};