/* unplugin-vue-components disabled */import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c16fe0e0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "app-container home"
};
export default {
  __name: 'index',
  setup(__props) {
    const version = ref('3.8.3');
    function goTarget(url) {
      window.open(url, '__blank');
    }
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1);
    };
  }
};