/* unplugin-vue-components disabled */import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/ImageUpload/index.vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, isRef as _isRef, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8d752a68"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dialog-footer"
};
import { queryAllMemAppInfoList, addMemAppInfo, updateMemAppInfo, findMemAppInfo, deleteMemAppInfo, queryAllMemAppCategoryInfoList } from "@/api/applicationManagement/index";
import { queryAppCategorySourceType } from "@/api/employeeManagement/index";
export default {
  __name: 'Application',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const dialogFormVisible = ref(false);
    const loading = ref(false);
    const btnLoading = ref(false);
    const total = ref(0);
    const formRef = ref();
    const title = ref("新增应用");
    const parentName = ref("");
    const data = reactive({
      tableData: [],
      sourceTypeArr: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        sourceType: null,
        name: null
      },
      form: {
        sourceType: null,
        categoryCode: "",
        status: "off",
        name: "",
        sort: 1,
        level: 1,
        icon: "",
        parentCode: null,
        type: ""
      },
      rules: {
        sourceType: [{
          required: true,
          message: "平台类型不能为空",
          trigger: "blur"
        }],
        categoryCode: [{
          required: true,
          message: "应用分类不能为空",
          trigger: "change"
        }],
        status: [{
          required: true,
          message: "应用状态不能为空",
          trigger: "change"
        }],
        name: [{
          required: true,
          message: "应用名称不能为空",
          trigger: "change"
        }],
        sort: [{
          required: true,
          message: "排序不能为空",
          trigger: "change"
        }],
        icon: [{
          required: true,
          message: "图标不能为空",
          trigger: "change"
        }],
        type: [{
          required: true,
          message: "应用类型不能为空",
          trigger: "change"
        }]
      },
      optionsClassify: [],
      optionsClass: [{
        name: "公共应用",
        code: "public_app"
      }, {
        name: "专有应用",
        code: "private_app"
      }]
    });
    const {
      tableData,
      sourceTypeArr,
      queryParams,
      form,
      rules,
      optionsClassify,
      optionsClass
    } = toRefs(data);
    const handleAdd = (level, row) => {
      form.value.level = "level_" + level;
      dialogFormVisible.value = true;
      if (row) {
        parentName.value = row.name;
        form.value.parentCode = level == 1 ? null : row?.code;
        form.value.categoryCode = row?.memAppCategoryInfoVo.code;
      }
      title.value = "新增应用";
    };
    const optionsClassifys = async () => {
      const res = await queryAllMemAppCategoryInfoList({});
      try {
        optionsClassify.value = res.data;
      } catch (error) {}
    };
    const cancel = () => {
      dialogFormVisible.value = false;
      reset();
    };
    const handleQuery = () => {
      queryParams.value.pageNum = 1;
      getList();
    };
    const submit = async formEl => {
      if (!formEl) return;
      await formEl.validate(valid => {
        if (valid) {
          if (form.value.code) {
            // 修改
            editUser();
          } else {
            // 新增
            addUser();
          }
        }
      });
    };

    // 新增应用
    const addUser = () => {
      addMemAppInfo(form.value).then(res => {
        dialogFormVisible.value = false;
        getList();
        proxy.$modal.msgSuccess("新增成功");
        reset();
      });
    };
    const switchChange = row => {
      const sendData = {
        code: row.code,
        categoryCode: row.categoryCode,
        status: row.status,
        name: row.name,
        sort: row.sort,
        parentCode: row.parentCode,
        level: row.level,
        icon: row.icon,
        type: ""
      };
      updateMemAppInfo(sendData).then(res => {
        dialogFormVisible.value = false;
        getList();
        reset();
        proxy.$modal.msgSuccess("修改成功！");
      });
    };

    // 修改应用
    const editUser = async () => {
      const sendData = {
        code: form.value.code,
        categoryCode: form.value.categoryCode,
        sourceType: form.value.sourceType,
        status: form.value.status,
        name: form.value.name,
        sort: form.value.sort,
        parentCode: form.value.parentCode,
        level: form.value.level,
        icon: form.value.icon,
        type: form.value.type
      };
      try {
        await updateMemAppInfo(sendData);
        dialogFormVisible.value = false;
        getList();
        proxy.$modal.msgSuccess("修改成功！");
        reset();
      } catch (error) {
        console.error(error);
      }
    };

    // 关闭弹窗
    const closeDialog = () => {
      dialogFormVisible.value = false;
      reset();
    };
    const buildTree = (data, parentCode) => {
      const tree = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].parentCode === parentCode) {
          const children = buildTree(data, data[i].code);
          if (children.length > 0) {
            data[i].children = children;
          }
          tree.push(data[i]);
        }
      }
      return tree;
    };

    // 查询应用列表
    const getList = () => {
      loading.value = true;
      queryAllMemAppInfoList(queryParams.value).then(res => {
        try {
          tableData.value = buildTree(res.data, "");
          total.value = res.total;
        } catch (error) {}
        loading.value = false;
      });
    };

    // 去修改
    const handleUpdate = rows => {
      const findName = tableData.value.find(item => item.code == rows.parentCode);
      parentName.value = findName?.name;
      getSingleVal(rows.code);
      title.value = "修改应用";
    };

    // 查询单个应用信息
    const getSingleVal = code => {
      findMemAppInfo({
        code
      }).then(({
        data
      }) => {
        const {
          categoryCode,
          sourceType,
          status,
          name,
          type,
          sort,
          level,
          parentCode,
          code,
          icon
        } = data;
        Object.assign(form.value, {
          code,
          categoryCode,
          sourceType,
          status,
          name,
          sort,
          level,
          parentCode,
          icon,
          type
        });
        dialogFormVisible.value = true;
      });
    };
    const handleDelete = rows => {
      const {
        code
      } = rows;
      proxy.$modal.confirm("是否确认删除?").then(function () {
        return deleteMemAppInfo({
          code
        });
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(err => {
        console.log(err);
        // proxy.$modal.msgError(err.msg);
      });
    };

    // 重置表单
    const reset = () => {
      proxy.$refs.formRef?.resetFields();
      form.value.code = "";
      form.value = {
        categoryCode: "",
        status: "off",
        name: "",
        sort: 1,
        level: 1,
        icon: "",
        parentCode: null,
        type: ""
      };
    };

    // 查询平台类型
    const sourceType = () => {
      queryAppCategorySourceType().then(res => {
        sourceTypeArr.value = res.data;
      });
    };

    // 选择平台类型后查询应用分类
    function change(val) {
      queryAllMemAppCategoryInfoList({
        sourceType: val
      }).then(res => {
        optionsClassify.value = res.data;
        form.value.categoryCode = "";
      });
    }
    getList();
    optionsClassifys();
    sourceType();
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_switch = _resolveComponent("el-switch");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_input_number = _resolveComponent("el-input-number");
      const _component_ImageUpload = __unplugin_components_0;
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, {
        gutter: 2
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(queryParams),
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
          inline: true,
          ref: "queryParamsRef"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "平台类型",
            prop: "sourceType"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              clearable: "",
              modelValue: _unref(queryParams).sourceType,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).sourceType = $event),
              placeholder: "请选择平台类型"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sourceTypeArr), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.key,
                  label: item.value,
                  value: item.key
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "应用名称",
            prop: "name"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(queryParams).name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).name = $event),
              clearable: "",
              placeholder: "请输入应用名称"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: "Search",
              plain: "",
              type: "primary",
              onClick: handleQuery
            }, {
              default: _withCtx(() => [_createTextVNode("搜索 ")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }), _createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            icon: "Plus",
            plain: "",
            type: "primary",
            onClick: _cache[3] || (_cache[3] = $event => handleAdd(1, null))
          }, {
            default: _withCtx(() => [_createTextVNode("新增应用 ")]),
            _: 1
          })]),
          _: 1
        }, 8, ["span"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData),
        style: {
          "width": "100%"
        },
        "default-expand-all": "",
        "row-key": "id",
        "tree-props": {
          children: 'children',
          hasChildren: 'hasChildren'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "icon",
          align: "center",
          label: "图标"
        }, {
          default: _withCtx(scope => [scope.row.icon ? (_openBlock(), _createBlock(_component_el_image, {
            key: 0,
            style: {
              "width": "100px",
              "height": "100px"
            },
            src: scope.row.icon,
            "zoom-rate": 1.2,
            "initial-index": 1,
            fit: "cover"
          }, null, 8, ["src", "zoom-rate"])) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "name",
          label: "应用名称"
        }), _createVNode(_component_el_table_column, {
          prop: "nickName",
          align: "center",
          label: "应用分类名称"
        }, {
          default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.memAppCategoryInfoVo?.name), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "nickName",
          align: "center",
          label: "应用类型"
        }, {
          default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.type == "private_app" ? "专有应用" : "公共应用"), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "sourceType",
          align: "center",
          label: "平台类型"
        }, {
          default: _withCtx(scope => [_createTextVNode(_toDisplayString(_unref(sourceTypeArr).find(item => item.key === scope.row.sourceType).value), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "status",
          align: "center",
          label: "开启状态"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_switch, {
            modelValue: scope.row.status,
            "onUpdate:modelValue": $event => scope.row.status = $event,
            "active-value": 'on',
            "inactive-value": 'off',
            onChange: $event => switchChange(scope.row)
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "sort",
          align: "center",
          label: "排序"
        }), _createVNode(_component_el_table_column, {
          fixed: "right",
          align: "center",
          label: "操作",
          width: "150"
        }, {
          default: _withCtx(scope => [_createElementVNode("div", null, [!scope.row.parentCode ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            link: "",
            size: "small",
            type: "primary",
            onClick: $event => handleAdd(2, scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("新增 ")]),
            _: 2
          }, 1032, ["onClick"])) : _createCommentVNode("", true), _createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleUpdate(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("修改 ")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            link: "",
            type: "danger",
            size: "small",
            onClick: $event => handleDelete(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("删除 ")]),
            _: 2
          }, 1032, ["onClick"])])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _createVNode(_component_el_dialog, {
        modelValue: _unref(dialogFormVisible),
        "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => _isRef(dialogFormVisible) ? dialogFormVisible.value = $event : null),
        "before-close": closeDialog,
        title: _unref(title)
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          onClick: _cache[12] || (_cache[12] = $event => cancel(_unref(formRef)))
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[13] || (_cache[13] = $event => submit(_unref(formRef))),
          loading: _unref(btnLoading)
        }, {
          default: _withCtx(() => [_createTextVNode("提交")]),
          _: 1
        }, 8, ["loading"])])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(form),
          ref_key: "formRef",
          ref: formRef,
          rules: _unref(rules),
          "label-width": "120"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "平台类型",
            prop: "sourceType"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              clearable: "",
              modelValue: _unref(form).sourceType,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _unref(form).sourceType = $event),
              placeholder: "请选择平台类型",
              onChange: change
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sourceTypeArr), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.key,
                  label: item.value,
                  value: item.key
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _unref(form).level == 'level_2' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "父级应用名称"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(parentName),
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _isRef(parentName) ? parentName.value = $event : null),
              disabled: "",
              maxlength: "20",
              "show-word-limit": ""
            }, null, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true), _createVNode(_component_el_form_item, {
            label: "应用名称",
            prop: "name"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).name,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _unref(form).name = $event),
              placeholder: "请输入应用名称",
              maxlength: "20",
              "show-word-limit": ""
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "应用分类",
            prop: "categoryCode"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: _unref(form).categoryCode,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _unref(form).categoryCode = $event),
              style: {
                "width": "100%"
              },
              class: "m-2",
              disabled: _unref(form).level == 'level_2',
              placeholder: "请选择应用分类"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(optionsClassify), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.code,
                  label: item.name,
                  value: item.code
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue", "disabled"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "应用类型",
            prop: "type"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: _unref(form).type,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _unref(form).type = $event),
              style: {
                "width": "100%"
              },
              class: "m-2",
              placeholder: "请选择应用类型"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(optionsClass), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.code,
                  label: item.name,
                  value: item.code
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "应用状态",
            prop: "status"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_switch, {
              modelValue: _unref(form).status,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _unref(form).status = $event),
              "active-value": 'on',
              "inactive-value": 'off'
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "排序",
            prop: "sort"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input_number, {
              min: 1,
              modelValue: _unref(form).sort,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _unref(form).sort = $event),
              "controls-position": "right"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _ctx.formType !== 'resetPwd' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 1,
            label: "图标",
            prop: "icon"
          }, {
            default: _withCtx(() => [_createVNode(_component_ImageUpload, {
              limit: 1,
              modelValue: _unref(form).icon,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _unref(form).icon = $event),
              fileSize: 35
            }, null, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};