/* unplugin-vue-components disabled */import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "width": "100%"
  }
};
import { pcaTextArr } from "element-china-area-data";
export default {
  __name: 'index',
  props: {
    addressMode: {
      type: String,
      default: ""
    }
  },
  emits: ["addressChange"],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const options = ref(pcaTextArr);
    const selectedOptions = computed({
      get() {
        const arr = props.addressMode?.split("-");
        return arr;
      },
      set(arr) {
        // emit("update:addressMode", arr);
        emit("addressChange", arr.join("-"));
      }
    });
    const addressChange = arr => {
      //   emit("update:addressMode", arr.join("-"));
      emit("addressChange", arr.join("-"));
    };
    return (_ctx, _cache) => {
      const _component_el_cascader = _resolveComponent("el-cascader");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_cascader, {
        options: _unref(options),
        modelValue: _unref(selectedOptions),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(selectedOptions) ? selectedOptions.value = $event : null),
        style: {
          "width": "100%"
        },
        onChange: addressChange
      }, null, 8, ["options", "modelValue"])]);
    };
  }
};