/* unplugin-vue-components disabled */import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default {
  __name: 'basicInfoForm',
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  setup(__props) {
    // 表单校验
    const rules = ref({
      tableName: [{
        required: true,
        message: "请输入表名称",
        trigger: "blur"
      }],
      tableComment: [{
        required: true,
        message: "请输入表描述",
        trigger: "blur"
      }],
      className: [{
        required: true,
        message: "请输入实体类名称",
        trigger: "blur"
      }],
      functionAuthor: [{
        required: true,
        message: "请输入作者",
        trigger: "blur"
      }]
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createBlock(_component_el_form, {
        ref: "basicInfoForm",
        model: __props.info,
        rules: _unref(rules),
        "label-width": "150px"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_row, null, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 12
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "表名称",
              prop: "tableName"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                placeholder: "请输入仓库名称",
                modelValue: __props.info.tableName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => __props.info.tableName = $event)
              }, null, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 12
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "表描述",
              prop: "tableComment"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                placeholder: "请输入",
                modelValue: __props.info.tableComment,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => __props.info.tableComment = $event)
              }, null, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 12
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "实体类名称",
              prop: "className"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                placeholder: "请输入",
                modelValue: __props.info.className,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => __props.info.className = $event)
              }, null, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 12
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "作者",
              prop: "functionAuthor"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                placeholder: "请输入",
                modelValue: __props.info.functionAuthor,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => __props.info.functionAuthor = $event)
              }, null, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_col, {
            span: 24
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "备注",
              prop: "remark"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                type: "textarea",
                rows: 3,
                modelValue: __props.info.remark,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => __props.info.remark = $event)
              }, null, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model", "rules"]);
    };
  }
};