/* unplugin-vue-components disabled */import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-71f3e86d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "mb8"
};
const _hoisted_2 = {
  class: "dialog-footer"
};
import { appIncomeCategoryInfoList, appIncomeCategoryInfoAdd, appIncomeCategoryInfoFind, appIncomeCategoryInfoUpdata, appIncomeCategoryInfoDelete } from "@/api/employeeManagement/index";
import { reactive, ref } from "vue";
export default {
  __name: 'IncomeClassification',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const dialogFormVisible = ref(false);
    const loading = ref(false);
    const formRef = ref();
    const btnLoading = ref(false);
    const total = ref(0);
    const title = ref("新增收入分类");
    const formType = ref(null);
    const memAppInfoList = ref([]);
    const data = reactive({
      tableData: [],
      exchangeData: [],
      ruleForm: {
        name: "",
        parentCode: "",
        code: "",
        mallCode: ""
      },
      type: [{
        name: "通用收入分类",
        code: "common"
      }, {
        name: "应用定制收入分类",
        code: "app"
      }],
      queryParams: {
        pageNum: 1,
        pageSize: 100,
        name: null
      },
      // 表单校验
      ruleFormRule: {
        appCode: [{
          required: true,
          message: "应用名称不能为空",
          trigger: "blur"
        }],
        type: [{
          required: true,
          message: "收入分类类型",
          trigger: "blur"
        }],
        name: [{
          required: true,
          message: "请输入收入分类名称",
          trigger: "blur"
        }],
        icon: [{
          required: true,
          message: "请选择图片",
          trigger: ["blur"]
        }]
      }
    });
    const {
      tableData,
      queryParams,
      ruleFormRule,
      ruleForm
    } = toRefs(data);
    const handleAdd = () => {
      title.value = "新增收入分类";
      formType.value = "add";
      dialogFormVisible.value = true;
      ruleForm.value.detail = "";
      reset();
      if (queryParams.value.name) {
        queryParams.value.name = "";
        getList();
      }
    };
    const visibleChange = key => {};
    const handleDelete = rows => {
      const {
        code
      } = rows;
      proxy.$modal.confirm("是否确认删除?").then(function () {
        return appIncomeCategoryInfoDelete({
          code
        });
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(err => {
        console.log(err);
      });
    };
    // 去修改
    const handleUpdate = rows => {
      title.value = "编辑收入分类";
      formType.value = "updata";
      ruleForm.value.code = rows.code;
      getSingleVal(rows.code);
      if (queryParams.value.name) {
        queryParams.value.name = "";
        getList();
      }
    };
    // 查询单个收入分类信息详情
    const getSingleVal = code => {
      appIncomeCategoryInfoFind({
        code
      }).then(res => {
        ruleForm.value = Object.assign({}, res.data);
        dialogFormVisible.value = true;
        getShopCode("init");
      });
    };
    const cancel = () => {
      dialogFormVisible.value = false;
      reset();
    };
    const handleQuery = () => {
      queryParams.value.pageNum = 1;
      getList();
    };
    const submit = async formEl => {
      if (!formEl) return;
      await formEl.validate(valid => {
        if (valid) {
          addShop();
        }
      });
    };

    // 新增收入分类
    const addShop = () => {
      btnLoading.value = true;
      let api = appIncomeCategoryInfoAdd;
      if (formType.value == "updata") {
        api = appIncomeCategoryInfoUpdata;
      }
      api(ruleForm.value).then(() => {
        dialogFormVisible.value = false;
        getList();
        reset();
        proxy.$modal.msgSuccess("操作成功");
        btnLoading.value = false;
      }).catch(() => {
        btnLoading.value = false;
      });
    };

    // 关闭弹窗
    const closeDialog = () => {
      dialogFormVisible.value = false;
      reset();
    };

    // 查询收入分类列表
    const getList = () => {
      loading.value = true;
      appIncomeCategoryInfoList(queryParams.value).then(res => {
        try {
          if (queryParams.value.name) {
            tableData.value = res.data;
          } else {
            tableData.value = buildTree(res.data, "zero_1001");
          }
          total.value = res.total;
        } catch (error) {}
        loading.value = false;
      });
    };
    const buildTree = (data, parentCode) => {
      const tree = [];
      for (let i = 0; i < data.length; i++) {
        const currentItem = data[i];
        if (currentItem.parentCode === parentCode) {
          const children = buildTree(data, currentItem.code);
          if (children.length > 0) {
            currentItem.children = children;
          }
          tree.push({
            ...currentItem,
            label: currentItem.name,
            value: currentItem.code
          });
        }
      }
      return tree;
    };

    // 重置表单
    const reset = () => {
      formRef.value?.resetFields();
      ruleForm.value = {
        name: "",
        parentCode: "",
        code: "",
        mallCode: ""
      };
    };
    getList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_cascader = _resolveComponent("el-cascader");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, {
        gutter: 2
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(queryParams),
          ref: "queryForm",
          inline: true
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "收入分类名称"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(queryParams).name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).name = $event),
              placeholder: "收入分类名称",
              clearable: ""
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: "Search",
              plain: "",
              type: "primary",
              onClick: handleQuery
            }, {
              default: _withCtx(() => [_createTextVNode("搜索")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_createVNode(_component_el_button, {
        icon: "Plus",
        plain: "",
        type: "primary",
        onClick: handleAdd
      }, {
        default: _withCtx(() => [_createTextVNode("新增收入分类")]),
        _: 1
      })])]), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData),
        style: {
          "width": "100%"
        },
        "default-expand-all": "",
        "row-key": "id",
        "tree-props": {
          children: 'children',
          hasChildren: 'hasChildren'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          label: "收入分类名称",
          width: "200",
          "show-overflow-tooltip": "",
          prop: "name"
        }), _createVNode(_component_el_table_column, {
          label: "创建时间",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "createdTime"
        }), _createVNode(_component_el_table_column, {
          fixed: "right",
          align: "center",
          label: "操作",
          width: "150"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleUpdate(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleDelete(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, loading.value]]), _createVNode(_component_el_dialog, {
        modelValue: dialogFormVisible.value,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => dialogFormVisible.value = $event),
        "before-close": closeDialog,
        title: title.value,
        width: "1000px"
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_2, [_createVNode(_component_el_button, {
          onClick: _cache[4] || (_cache[4] = $event => cancel(formRef.value))
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          loading: btnLoading.value,
          onClick: _cache[5] || (_cache[5] = $event => submit(formRef.value))
        }, {
          default: _withCtx(() => [_createTextVNode("提交")]),
          _: 1
        }, 8, ["loading"])])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "formRef",
          ref: formRef,
          model: _unref(ruleForm),
          rules: _unref(ruleFormRule),
          "label-position": "top"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "父级分类",
            prop: "parentCode"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_cascader, {
              modelValue: _unref(ruleForm).parentCode,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(ruleForm).parentCode = $event),
              options: _unref(tableData),
              "show-all-levels": false,
              props: {
                checkStrictly: true,
                emitPath: false
              },
              onVisibleChange: _cache[2] || (_cache[2] = $event => visibleChange(_ctx.key))
            }, null, 8, ["modelValue", "options"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "收入分类名称",
            prop: "name"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "text",
              placeholder: "请输入收入分类名称",
              modelValue: _unref(ruleForm).name,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _unref(ruleForm).name = $event),
              maxlength: "20"
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};