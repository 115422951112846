/* unplugin-vue-components disabled */import __unplugin_components_1 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/FileUpload/index.vue';
import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, isRef as _isRef, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3bc0ebcc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dialog-footer"
};
import QrcodeVue from "qrcode.vue";
import axios from "axios";
import { getAndroidVersions, addAndroidVersion, editAndroidVersion, delAndroidVersion, queryAndroidType, queryAndroidVersionIsForceUpdate, queryAndroidVersionPublishStatus } from "@/api/versionManagemen/index";
export default {
  __name: 'AndroidVersion',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const dialogFormVisible = ref(false);
    const loading = ref(false);
    const btnLoading = ref(false);
    const total = ref(0);
    const formRef = ref();
    const title = ref('新增安卓版本');
    const data = reactive({
      tableData: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      form: {
        versionCode: null,
        title: null,
        publishTime: null,
        type: null,
        isForceUpdate: null,
        url: null,
        detail: null,
        publishStatus: null,
        remark: null
      },
      rules: {
        versionCode: [{
          required: true,
          message: '版本号不能为空',
          trigger: 'blur'
        }],
        title: [{
          required: true,
          message: '版本标题不能为空',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '类型不能为空',
          trigger: 'blur'
        }],
        detail: [{
          required: true,
          message: '版本更新日志不能为空',
          trigger: 'blur'
        }],
        url: [{
          required: true,
          message: '安装包不能为空',
          trigger: 'blur'
        }],
        isForceUpdate: [{
          required: true,
          message: '是否强制更新不能为空',
          trigger: 'blur'
        }],
        publishTime: [{
          required: true,
          message: '发布时间不能为空',
          trigger: 'blur'
        }]
        // name4:[{required:true,message:'',trigger:'blur'}]
      },

      typeOptions: [],
      forceUpdateOptions: [],
      statusOptions: []
    });
    const {
      tableData,
      queryParams,
      form,
      rules,
      typeOptions,
      forceUpdateOptions,
      statusOptions
    } = toRefs(data);
    const handleAdd = () => {
      form.value.type = typeOptions.value[0].key;
      form.value.isForceUpdate = forceUpdateOptions.value[0].key;
      form.value.publishStatus = statusOptions.value[0].key;
      dialogFormVisible.value = true;
    };
    const cancel = () => {
      dialogFormVisible.value = false;
      reset();
    };
    const queryType = () => {
      queryAndroidType({}).then(res => {
        console.log(res);
        typeOptions.value = res.data;
      });
    };
    const queryForceUpdate = () => {
      queryAndroidVersionIsForceUpdate({}).then(res => {
        console.log(res);
        forceUpdateOptions.value = res.data;
      });
    };
    const queryStatus = () => {
      queryAndroidVersionPublishStatus({}).then(res => {
        console.log(res);
        statusOptions.value = res.data;
      });
    };
    const handleQuery = () => {
      queryParams.value.pageNum = 1;
      getList();
    };
    const submit = async formEl => {
      console.log(formEl);
      console.log(form.value);
      if (!formEl) return;
      await formEl.validate(valid => {
        console.log(valid);
        if (valid) {
          console.log('submit');
          if (form.value.id) {
            // 修改
            editVersion();
          } else {
            // 新增
            addVersion();
          }
        } else {}
      });
    };

    // 新增版本
    const addVersion = () => {
      addAndroidVersion(form.value).then(res => {
        dialogFormVisible.value = false;
        getList();
        reset();
        proxy.$modal.msgSuccess("新增成功");
      });
    };
    const linkUrl = row => {
      return row.url.replace("https", 'http');
    };

    // 修改版本
    const editVersion = () => {
      editAndroidVersion(form.value).then(res => {
        dialogFormVisible.value = false;
        getList();
        reset();
        proxy.$modal.msgSuccess("修改成功");
      });
    };
    const closeDialog = () => {
      dialogFormVisible.value = false;
      reset();
    };

    // 查询安卓版本列表
    const getList = () => {
      loading.value = true;
      getAndroidVersions(queryParams.value).then(res => {
        try {
          tableData.value = res.data;
          total.value = res.total;
        } catch (error) {
          console.log(error);
        }
        loading.value = false;
      });
    };
    const handleUpdate = rows => {
      form.value = rows;
      dialogFormVisible.value = true;
    };
    const handleDelete = rows => {
      const {
        id
      } = rows;
      proxy.$modal.confirm('是否确认删除?').then(function () {
        return delAndroidVersion({
          id
        });
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    };

    // 重置表单
    const reset = () => {
      form.value = {
        versionCode: null,
        title: null,
        publishTime: null,
        type: null,
        isForceUpdate: null,
        url: null,
        detail: null,
        publishStatus: null,
        remark: null
      };
      proxy.resetForm(formRef);
    };

    // 下载
    const handleDownload = rows => {
      const {
        url
      } = rows;
      console.log(rows, 'rows');
      const data = url;
      const fileName = "text.apk";
      const fileType = "text/plain";
      console.log(url);
      // console.log(decodeURIComponent(url),'XXX')
      window.open(decodeURIComponent(url), '_blank');
      // downloadFile(data, fileName, fileType);

      // axios.get(url, { responseType: "blob" }).then(response => {
      //     const blob = new Blob([response.data]);
      //     const link = document.createElement("a");
      //     link.href = URL.createObjectURL(blob);
      //     link.download = item.fileName;
      //     link.click();
      //     URL.revokeObjectURL(link.href);
      //   }).catch(console.error);

      // downloadFile(data,fileName)
    };
    // function downloadFile(downloadUrl) {
    //     console.log(downloadUrl)
    //     let url = decodeURIComponent(downloadUrl);
    //     let filename = "test.apk"
    //     console.log(url,'url')
    //     fetch(url).then((res) => {
    //         res.blob().then((blob) => {
    //             const blobUrl = window.URL.createObjectURL(blob);
    //             const a = document.createElement('a');
    //             a.href = blobUrl;
    //             // 这里的文件名根据实际情况从响应头或者url里获取
    //             a.download = filename;
    //             a.click();
    //         });
    //     });
    // }

    // function downloadFile(url,fileName){
    //     var x = new XMLHttpRequest();
    //     x.open("GET",url,true);
    //     x.responseType ="blob";
    //     x.onload = function(e){
    //         var url = window.URL.createObjectURL(x.response);
    //         var a = document.createElement("a");
    //         a.href = url;
    //         a.download = fileName;
    //         a.click()
    //     }

    //     x.send()
    // }

    getList();
    queryType();
    queryForceUpdate();
    queryStatus();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _component_el_date_picker = _resolveComponent("el-date-picker");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_FileUpload = __unplugin_components_1;
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, {
        gutter: 2
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(queryParams),
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
          inline: true,
          ref: "queryParamsRef"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "版本标题",
            prop: "title"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(queryParams).title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).title = $event),
              clearable: "",
              placeholder: "请输入版本标题"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            prop: "name"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: "Search",
              plain: "",
              type: "primary",
              onClick: handleQuery
            }, {
              default: _withCtx(() => [_createTextVNode("搜索")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }), _createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            icon: "Plus",
            plain: "",
            type: "primary",
            onClick: handleAdd
          }, {
            default: _withCtx(() => [_createTextVNode("新增")]),
            _: 1
          })]),
          _: 1
        }, 8, ["span"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData),
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "id",
          label: "id",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "versionCode",
          align: "center",
          label: "版本号",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "title",
          align: "center",
          label: "版本标题",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "typeDesc",
          align: "center",
          label: "类型",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "isForceUpdateDesc",
          align: "center",
          label: "是否强制更新",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "publishStatusDesc",
          align: "center",
          label: "发布状态",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "publishTime",
          align: "center",
          label: "发布时间",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "detail",
          align: "center",
          label: "版本更新日志",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "detail",
          align: "center",
          label: "下载二维码",
          width: ""
        }, {
          default: _withCtx(scope => [_createVNode(QrcodeVue, {
            value: scope.row.url,
            "size:300": ""
          }, null, 8, ["value"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "remark",
          align: "center",
          label: "备注",
          width: ""
        }), _createVNode(_component_el_table_column, {
          fixed: "right",
          align: "center",
          label: "操作",
          width: "150"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            link: "",
            type: "primary",
            onClick: $event => handleDownload(scope.row),
            size: "small"
          }, {
            default: _withCtx(() => [_createTextVNode(" 下载 ")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleUpdate(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleDelete(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[2] || (_cache[2] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[3] || (_cache[3] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_component_el_dialog, {
        modelValue: _unref(dialogFormVisible),
        "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => _isRef(dialogFormVisible) ? dialogFormVisible.value = $event : null),
        "before-close": closeDialog,
        title: _unref(title)
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          onClick: _cache[13] || (_cache[13] = $event => cancel(_unref(formRef)))
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[14] || (_cache[14] = $event => submit(_unref(formRef))),
          loading: _unref(btnLoading)
        }, {
          default: _withCtx(() => [_createTextVNode("提交")]),
          _: 1
        }, 8, ["loading"])])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(form),
          ref_key: "formRef",
          ref: formRef,
          rules: _unref(rules),
          "label-width": "120"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "版本号",
            prop: "versionCode"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).versionCode,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _unref(form).versionCode = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "版本标题",
            prop: "title"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).title,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _unref(form).title = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "发布时间",
            prop: "publishTime"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_date_picker, {
              style: {
                "width": "100%"
              },
              modelValue: _unref(form).publishTime,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _unref(form).publishTime = $event),
              type: "datetime",
              "value-format": "YYYY-MM-DD HH:mm:ss",
              placeholder: "请选择发布时间"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "类型",
            prop: "type"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: _unref(form).type,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _unref(form).type = $event),
              style: {
                "width": "100%"
              },
              class: "m-2",
              placeholder: "Select"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(typeOptions), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.key,
                  label: item.value,
                  value: item.key
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "是否强制更新",
            prop: "isForceUpdate"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: _unref(form).isForceUpdate,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _unref(form).isForceUpdate = $event),
              style: {
                "width": "100%"
              },
              class: "m-2",
              placeholder: "Select"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(forceUpdateOptions), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.key,
                  label: item.value,
                  value: item.key
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "安装包",
            prop: "url"
          }, {
            default: _withCtx(() => [_createVNode(_component_FileUpload, {
              limit: 1,
              modelValue: _unref(form).url,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _unref(form).url = $event),
              fileSize: 100,
              fileType: ['apk']
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "版本更新日志",
            prop: "detail"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "textarea",
              modelValue: _unref(form).detail,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _unref(form).detail = $event),
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "发布状态",
            prop: "publishStatus"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: _unref(form).publishStatus,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _unref(form).publishStatus = $event),
              style: {
                "width": "100%"
              },
              class: "m-2",
              placeholder: "Select"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(statusOptions), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.key,
                  label: item.value,
                  value: item.key
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "备注",
            prop: "remark"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "textarea",
              modelValue: _unref(form).remark,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => _unref(form).remark = $event),
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};