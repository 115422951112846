import request from "@/utils/request";
// 查询门店信息
export function queryShopInfoListByPage(data) {
  return request({
    url: "/yg-plat-manage/shopInfo/queryShopInfoListByPage",
    method: "post",
    data,
    headers: {
      repeatSubmit: false,
    },
  });
}
// 邀约开关
export function updateShopInfoByCode(data) {
  return request({
    url: "/yg-plat-manage/shopInfo/updateShopInfoByCode",
    method: "post",
    data,
    headers: {
      repeatSubmit: false,
    },
  });
}
