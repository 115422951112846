/* unplugin-vue-components disabled */import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, createElementVNode as _createElementVNode, isRef as _isRef, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-34729630"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dialog-footer"
};
import { queryMemAppCategoryInfoPageList, addMemAppCategoryInfo, updateMemAppCategoryInfo, findMemAppCategoryInfo, deleteMemAppCategoryInfo } from "@/api/applicationManagement/index";
import { queryAppCategorySourceType } from "@/api/employeeManagement/index";
export default {
  __name: 'ApplicationClassification',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const dialogFormVisible = ref(false);
    const loading = ref(false);
    const btnLoading = ref(false);
    const total = ref(0);
    const formRef = ref();
    const title = ref("新增分类");
    const data = reactive({
      tableData: [],
      sourceTypeArr: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        sourceType: null,
        name: ""
      },
      form: {
        sourceType: null,
        name: "",
        sort: 0
      },
      rules: {
        sourceType: [{
          required: true,
          message: "平台类型不能为空",
          trigger: "blur"
        }],
        name: [{
          required: true,
          message: "分类名称不能为空",
          trigger: "blur"
        }],
        sort: [{
          required: true,
          message: "排序不能为空",
          trigger: "blur"
        }]
      }
    });
    const {
      tableData,
      sourceTypeArr,
      queryParams,
      form,
      rules
    } = toRefs(data);
    const handleAdd = () => {
      dialogFormVisible.value = true;
    };
    const cancel = () => {
      dialogFormVisible.value = false;
      reset();
    };
    const handleQuery = () => {
      queryParams.value.pageNum = 1;
      getList();
    };
    const submit = async formEl => {
      if (!formEl) return;
      await formEl.validate(valid => {
        if (valid) {
          if (form.value.id) {
            // 修改
            editUser();
          } else {
            // 新增
            addUser();
          }
        }
      });
    };

    // 新增员工
    const addUser = () => {
      addMemAppCategoryInfo(form.value).then(res => {
        dialogFormVisible.value = false;
        getList();
        reset();
        proxy.$modal.msgSuccess("新增成功");
      });
    };

    // 修改员工
    const editUser = () => {
      updateMemAppCategoryInfo(form.value).then(res => {
        dialogFormVisible.value = false;
        getList();
        reset();
        proxy.$modal.msgSuccess("修改成功！");
      });
    };

    // 关闭弹窗
    const closeDialog = () => {
      dialogFormVisible.value = false;
      reset();
    };

    // 查询分类列表
    const getList = () => {
      loading.value = true;
      queryMemAppCategoryInfoPageList(queryParams.value).then(res => {
        try {
          tableData.value = res.data;
          total.value = res.total;
        } catch (error) {}
        loading.value = false;
      });
    };

    // 去修改
    const handleUpdate = rows => {
      getSingleVal(rows.code);
    };

    // 查询单个分类信息
    const getSingleVal = code => {
      findMemAppCategoryInfo({
        code
      }).then(res => {
        form.value = res.data;
        dialogFormVisible.value = true;
      });
    };
    const handleDelete = rows => {
      const {
        code
      } = rows;
      proxy.$modal.confirm("是否确认删除?").then(function () {
        return deleteMemAppCategoryInfo({
          code
        });
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(err => {
        console.log(err);
        // proxy.$modal.msgError(err.msg);
      });
    };

    // 重置表单
    const reset = () => {
      formRef.value?.resetFields();
    };

    // 查询平台类型
    const sourceType = () => {
      queryAppCategorySourceType().then(res => {
        sourceTypeArr.value = res.data;
      });
    };
    getList();
    sourceType();
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _component_el_input_number = _resolveComponent("el-input-number");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, {
        gutter: 2
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(queryParams),
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
          inline: true,
          ref: "queryParamsRef"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "平台类型",
            prop: "sourceType"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              clearable: "",
              modelValue: _unref(queryParams).sourceType,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).sourceType = $event),
              placeholder: "请选择平台类型"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sourceTypeArr), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.key,
                  label: item.value,
                  value: item.key
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "分类名称",
            prop: "name"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(queryParams).name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).name = $event),
              clearable: "",
              placeholder: "请输入分类名称"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: "Search",
              plain: "",
              type: "primary",
              onClick: handleQuery
            }, {
              default: _withCtx(() => [_createTextVNode("搜索 ")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }), _createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            icon: "Plus",
            plain: "",
            type: "primary",
            onClick: handleAdd
          }, {
            default: _withCtx(() => [_createTextVNode("新增分类 ")]),
            _: 1
          })]),
          _: 1
        }, 8, ["span"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "name",
          align: "center",
          label: "分类名称"
        }), _createVNode(_component_el_table_column, {
          prop: "sourceType",
          align: "center",
          label: "平台类型"
        }, {
          default: _withCtx(scope => [_createTextVNode(_toDisplayString(_unref(sourceTypeArr).find(item => item.key === scope.row.sourceType).value), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "sort",
          align: "center",
          label: "排序"
        }), _createVNode(_component_el_table_column, {
          fixed: "right",
          align: "center",
          label: "操作"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleUpdate(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("修改 ")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            link: "",
            type: "danger",
            size: "small",
            onClick: $event => handleDelete(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("删除 ")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[3] || (_cache[3] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[4] || (_cache[4] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_component_el_dialog, {
        modelValue: _unref(dialogFormVisible),
        "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _isRef(dialogFormVisible) ? dialogFormVisible.value = $event : null),
        "before-close": closeDialog,
        title: _unref(title)
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          onClick: _cache[8] || (_cache[8] = $event => cancel(_unref(formRef)))
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[9] || (_cache[9] = $event => submit(_unref(formRef))),
          loading: _unref(btnLoading)
        }, {
          default: _withCtx(() => [_createTextVNode("提交")]),
          _: 1
        }, 8, ["loading"])])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(form),
          ref_key: "formRef",
          ref: formRef,
          rules: _unref(rules),
          "label-width": "80"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "平台类型",
            prop: "sourceType"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              clearable: "",
              modelValue: _unref(form).sourceType,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _unref(form).sourceType = $event),
              placeholder: "请选择平台类型"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sourceTypeArr), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.key,
                  label: item.value,
                  value: item.key
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "分类名称",
            prop: "name"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).name,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _unref(form).name = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "排序",
            prop: "sort"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input_number, {
              min: 1,
              modelValue: _unref(form).sort,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _unref(form).sort = $event),
              "controls-position": "right"
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};