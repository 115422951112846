import request from '@/utils/request'

// 查询菜单列表
export function listMenu(query) {
  return request({
    // url: '/system/menu/list',
    url:"/yg-plat-manage/menu/querySysMenuByList",
    // method: 'get',
    method: 'post',
    // params: query
    data:query
  })
}

// 查询菜单详细
export function getMenu(menuId) {
  return request({
    url:"/yg-plat-manage/menu/querySysMenuById/"+menuId,
    // url: '/system/menu/' + menuId,
    // method: 'get',
    method: 'post'
  })
}

// 查询菜单下拉树结构
export function treeselect() {
  return request({
    url: '/system/menu/treeselect',
    method: 'get'
  })
}

// 根据角色ID查询菜单下拉树结构
export function roleMenuTreeselect(roleId) {
  return request({
    url: '/system/menu/roleMenuTreeselect/' + roleId,
    method: 'get'
  })
}

// 新增菜单
export function addMenu(data) {
  return request({
    // url: '/system/menu',
    url: '/yg-plat-manage/menu/insertSysMenu',
    method: 'post',
    data: data
  })
}

// 修改菜单
export function updateMenu(data) {
  return request({
    url:"/yg-plat-manage/menu/updateSysMenu",
    // url: '/system/menu',
    // method: 'put',
    method: 'post',
    data: data
  })
}

// 删除菜单
export function delMenu(menuId) {
  return request({
    url: '/system/menu/' + menuId,
    method: 'delete'
  })
}