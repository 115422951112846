/* unplugin-vue-components disabled */import __unplugin_components_1 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/ImageUpload/index.vue';
import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow, createElementVNode as _createElementVNode, isRef as _isRef, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
const _hoisted_2 = {
  class: "dialog-footer"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 1
};
import { deepClone } from "@/utils/index";
import { queryPlatOpenInvoiceDetailPageList, updatePlatOpenInvoiceDetailById } from "@/api/invoice/index";
export default {
  __name: 'PendingInvoic',
  setup(__props) {
    const loading = ref(false);
    const dialogFormVisible = ref(false);
    const uploadInvoiceFormVisible = ref(false);
    const total = ref(0);
    const title = ref("开票信息");
    const {
      proxy
    } = getCurrentInstance();
    const btnLoading = ref(false);
    const data = reactive({
      tableData: [],
      queryParams: {
        status: "no_invoiced",
        pageNum: 1,
        pageSize: 10
      },
      form: {},
      rules: {},
      uploadInvoiceForm: {
        invoiceUrl: "https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg",
        id: null
      },
      uploadInvoiceFormRules: {
        invoiceUrl: [{
          required: true,
          message: '发票不能为空',
          trigger: 'blur'
        }]
      }
    });
    const {
      tableData,
      queryParams,
      form,
      rules,
      uploadInvoiceForm,
      uploadInvoiceFormRules
    } = toRefs(data);

    // 点击开票信息按钮
    const billingInformation = row => {
      const {
        platInvoiceDetail
      } = row;
      form.value = deepClone(platInvoiceDetail);
      dialogFormVisible.value = true;
    };
    // 查询待开票信息列表
    const getList = () => {
      console.log(queryParams.value, 'queryParams.value');
      queryPlatOpenInvoiceDetailPageList(queryParams.value).then(res => {
        tableData.value = res.data;
        total.value = res.total;
      });
    };

    // 关闭开票信息弹窗回调
    const closeDialogForm = () => {
      dialogFormVisible.value = false;
    };

    // 关闭上传发票信息弹窗回调
    const closeDialogUploadInvoiceForm = () => {
      resetUploadInvoiceForm();
      uploadInvoiceFormVisible.value = false;
    };

    // 点击上传发票按钮
    const uploadInvoice = row => {
      const {
        id
      } = row;
      console.log(uploadInvoiceForm.value);
      uploadInvoiceForm.value.id = id;
      console.log(uploadInvoiceForm.value);
      title.value = "上传发票";
      uploadInvoiceFormVisible.value = true;
    };

    // 重置上传发票表单
    const resetUploadInvoiceForm = () => {
      uploadInvoiceForm.value = {
        id: null,
        invoiceUrl: null
      };
      proxy.resetForm('uploadInvoiceFormRef');
    };

    // 上传发票
    const submitForm = () => {
      proxy.$refs.uploadInvoiceFormRef.validate(valid => {
        if (valid) {
          btnLoading.value = true;
          console.log(uploadInvoiceForm.value, "uploadInvoiceForm.value");
          updatePlatOpenInvoiceDetailById(uploadInvoiceForm.value).then(res => {
            proxy.$modal.msgSuccess("上传成功");
            btnLoading.value = false;
            uploadInvoiceFormVisible.value = false;
            getList();
          });
        }
      });
    };

    // 上传发票取消
    const uploadInvoiceFormCance = res => {
      resetUploadInvoiceForm();
      uploadInvoiceFormVisible.value = false;
    };
    getList();
    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _component_Image_Upload = __unplugin_components_1;
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", null, [_withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData),
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "id",
          label: "id",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "shopName",
          label: "商家名称",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "invoicedAmount",
          label: "开票金额",
          width: ""
        }), _createVNode(_component_el_table_column, {
          prop: "openInvoiceTime",
          label: "申请日期",
          width: ""
        }), _createVNode(_component_el_table_column, {
          fixed: "right",
          align: "center",
          label: "操作",
          width: "200"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => billingInformation(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("开票信息")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => uploadInvoice(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("上传发票")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[0] || (_cache[0] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[1] || (_cache[1] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_component_el_dialog, {
        modelValue: _unref(dialogFormVisible),
        "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _isRef(dialogFormVisible) ? dialogFormVisible.value = $event : null),
        "before-close": closeDialogForm,
        title: _unref(title)
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[8] || (_cache[8] = $event => dialogFormVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("确定")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(form),
          ref: "formRef",
          rules: _unref(rules),
          "label-width": "96"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "发票抬头",
            prop: "headerName"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).headerName,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(form).headerName = $event),
              disabled: true
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "纳税人识别号",
            prop: "taxpayerIdNumber"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).taxpayerIdNumber,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _unref(form).taxpayerIdNumber = $event),
              disabled: true,
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "基本开户银行",
            prop: "bankName"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).bankName,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _unref(form).bankName = $event),
              disabled: true,
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "基本开户账号",
            prop: "bankNo"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).bankNo,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _unref(form).bankNo = $event),
              disabled: true,
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "企业注册地址",
            prop: "addressOfEnterprise"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).addressOfEnterprise,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _unref(form).addressOfEnterprise = $event),
              disabled: true,
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "企业注册电话",
            prop: "phoneOfEnterprise"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(form).phoneOfEnterprise,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _unref(form).phoneOfEnterprise = $event),
              disabled: true,
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"]), _createVNode(_component_el_dialog, {
        modelValue: _unref(uploadInvoiceFormVisible),
        "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _isRef(uploadInvoiceFormVisible) ? uploadInvoiceFormVisible.value = $event : null),
        "before-close": closeDialogUploadInvoiceForm,
        title: _unref(title)
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_2, [_createVNode(_component_el_button, {
          onClick: uploadInvoiceFormCance
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          loading: _unref(btnLoading),
          type: "primary",
          onClick: submitForm
        }, {
          default: _withCtx(() => [!_unref(btnLoading) ? (_openBlock(), _createElementBlock("span", _hoisted_3, "提交")) : (_openBlock(), _createElementBlock("span", _hoisted_4, "加载中"))]),
          _: 1
        }, 8, ["loading"])])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(uploadInvoiceForm),
          ref: "uploadInvoiceFormRef",
          rules: _unref(uploadInvoiceFormRules),
          "label-width": "96"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "发票",
            prop: "invoiceUrl"
          }, {
            default: _withCtx(() => [_createVNode(_component_Image_Upload, {
              limit: 1,
              modelValue: _unref(uploadInvoiceForm).invoiceUrl,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _unref(uploadInvoiceForm).invoiceUrl = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};