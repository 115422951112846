/* unplugin-vue-components disabled */import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, isRef as _isRef, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-a7ab11ae"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dialog-footer"
};
import { messageQueryPage, messageAdd, queryPlatMerchantByAll, queryShopInfoByAll, staffBindInfoList } from "@/api/functionList/index";
import { queryAllMemUserInfoList } from "@/api/employeeManagement/index";
export default {
  __name: 'MessageReminder',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const dialogFormVisible = ref(false);
    const loading = ref(false);
    const btnLoading = ref(false);
    const total = ref(0);
    const formRef = ref();
    const title = ref("新增消息");
    const messageOption = {
      pmt_system_message: {
        key: "pmt_system_message",
        value: "宠医宝系统消息"
      },
      pmt_achieve_message: {
        key: "pmt_achieve_message",
        value: "宠医宝成就消息"
      },
      pmt_learning_garden: {
        key: "pmt_learning_garden",
        value: "宠医宝学习园地消息"
      },
      pmt_game_center: {
        key: "pmt_game_center",
        value: "宠医宝游戏中心消息"
      },
      pmt_work: {
        key: "pmt_work",
        value: "宠医宝我的工作消息"
      },
      points_mall: {
        key: "points_mall",
        value: "宠医宝积分商城消息"
      },
      pmt_activity_publicity: {
        key: "pmt_activity_publicity",
        value: "宠医宝活动公示消息"
      },
      pmt_corporate_commendation: {
        key: "pmt_activity_publicity",
        value: "宠医宝企业表彰公示消息"
      }
    };
    const data = reactive({
      tableData: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        messageContent: ""
      },
      form: {
        message: "",
        userCodes: "",
        type: "",
        merchantCode: "",
        shopCode: ""
      },
      optionsPosition: [],
      merchantCodePosition: [],
      shopCodePosition: [],
      optionstype: [{
        code: "pmt_system_message",
        name: "宠医宝系统消息"
      }],
      rules: {
        userCodes: [{
          required: true,
          message: "请选择接收人",
          trigger: ["blur"]
        }],
        merchantCode: [{
          required: true,
          message: "请选择商户",
          trigger: ["blur"]
        }],
        shopCode: [{
          required: true,
          message: "请选择门店",
          trigger: ["blur"]
        }],
        type: [{
          required: true,
          message: "请选择消息类型",
          trigger: ["blur", "change"]
        }],
        message: [{
          required: true,
          message: "请输入消息内容",
          trigger: ["blur", "change"]
        }]
      }
    });
    const {
      tableData,
      queryParams,
      form,
      rules,
      optionsPosition,
      merchantCodePosition,
      shopCodePosition,
      optionstype
    } = toRefs(data);
    const handleAdd = () => {
      dialogFormVisible.value = true;
    };
    const cancel = () => {
      dialogFormVisible.value = false;
      reset();
    };
    const handleQuery = () => {
      queryParams.value.pageNum = 1;
      getList();
    };
    const submit = async formEl => {
      if (!formEl) return;
      await formEl.validate(valid => {
        if (valid) {
          // 新增
          addUser();
        }
      });
    };

    // 新增
    const addUser = () => {
      const formData = new FormData();
      formData.append("message", form.value.message);
      formData.append("type", form.value.type);
      formData.append("userCodes", form.value.userCodes);
      formData.append("shopCode", form.value.shopCode);
      formData.append("merchantCode", form.value.merchantCode);
      messageAdd(formData).then(res => {
        dialogFormVisible.value = false;
        getList();
        reset();
        proxy.$modal.msgSuccess("新增成功");
      });
    };

    // 关闭弹窗
    const closeDialog = () => {
      dialogFormVisible.value = false;
      reset();
    };

    // 查询消息列表
    const getList = () => {
      loading.value = true;
      messageQueryPage(queryParams.value).then(res => {
        try {
          tableData.value = res.data;
          total.value = res.total;
        } catch (error) {}
        loading.value = false;
      });
    };
    // 会员信息列表
    const getPeople = () => {
      staffBindInfoList({
        merchantCode: form.value.merchantCode,
        shopCode: form.value.shopCode
      }).then(res => {
        try {
          optionsPosition.value = res.data.filter(item => item.userInfoVo).map(i => {
            return {
              name: i.userInfoVo.nickName,
              code: i.userInfoVo.code
            };
          });
        } catch (error) {}
      });
    };

    // 查询商户列表
    const getMerchantCode = () => {
      queryPlatMerchantByAll({}).then(res => {
        try {
          merchantCodePosition.value = res.data;
        } catch (error) {}
      });
    };
    // 查询门店列表
    const getShopCode = () => {
      form.value.shopCode = "";
      form.value.userCodes = "";
      queryShopInfoByAll({
        merchantCode: form.value.merchantCode
      }).then(res => {
        try {
          shopCodePosition.value = res.data;
        } catch (error) {}
      });
    };

    // 重置表单
    const reset = () => {
      formRef.value?.resetFields();
    };
    getList();
    getMerchantCode();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(queryParams),
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
          inline: true,
          ref: "queryParamsRef"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "消息内容"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(queryParams).messageContent,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).messageContent = $event),
              clearable: "",
              placeholder: "请输入消息内容"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: "Search",
              plain: "",
              type: "primary",
              onClick: handleQuery
            }, {
              default: _withCtx(() => [_createTextVNode("搜索")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            icon: "Plus",
            plain: "",
            type: "primary",
            onClick: handleAdd
          }, {
            default: _withCtx(() => [_createTextVNode("新增消息")]),
            _: 1
          })]),
          _: 1
        }, 8, ["span"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "messageType",
          align: "center",
          label: "消息类型"
        }, {
          default: _withCtx(scope => [_createTextVNode(_toDisplayString(messageOption[scope.row.messageType]?.value || scope.row.messageType), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "messageContent",
          align: "center",
          label: "发送内容",
          "show-overflow-tooltip": ""
        }), _createVNode(_component_el_table_column, {
          prop: "sendTime",
          align: "center",
          label: "发送时间"
        }), _createVNode(_component_el_table_column, {
          prop: "sendStatus",
          align: "center",
          label: "发送状态"
        }, {
          default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.sendStatus == 0 ? "成功" : "失败"), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          prop: "sendingTimes",
          align: "center",
          label: "发送次数"
        }), _createVNode(_component_el_table_column, {
          align: "center",
          label: "阅读状态"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(scope.row.readStatus == 0 ? "未读 " : "已读"), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          align: "center",
          label: "平台来源"
        }, {
          default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.platSource == "cyt" ? "宠医通 " : "宠医宝"), 1)]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[2] || (_cache[2] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[3] || (_cache[3] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_component_el_dialog, {
        modelValue: _unref(dialogFormVisible),
        "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => _isRef(dialogFormVisible) ? dialogFormVisible.value = $event : null),
        "before-close": closeDialog,
        title: _unref(title)
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          onClick: _cache[9] || (_cache[9] = $event => cancel(_unref(formRef)))
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[10] || (_cache[10] = $event => submit(_unref(formRef))),
          loading: _unref(btnLoading)
        }, {
          default: _withCtx(() => [_createTextVNode("提交")]),
          _: 1
        }, 8, ["loading"])])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(form),
          ref_key: "formRef",
          ref: formRef,
          rules: _unref(rules),
          "label-width": "120"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "商户",
            prop: "merchantCode"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: _unref(form).merchantCode,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _unref(form).merchantCode = $event),
              style: {
                "width": "100%"
              },
              class: "m-2",
              placeholder: "请选择商户",
              onChange: getShopCode
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(merchantCodePosition), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.code,
                  label: item.accountName,
                  value: item.code
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "门店",
            prop: "shopCode"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: _unref(form).shopCode,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _unref(form).shopCode = $event),
              style: {
                "width": "100%"
              },
              class: "m-2",
              placeholder: "请选择门店",
              onChange: getPeople
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(shopCodePosition), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.code,
                  label: item.name,
                  value: item.code
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "接收人",
            prop: "userCodes"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: _unref(form).userCodes,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _unref(form).userCodes = $event),
              style: {
                "width": "100%"
              },
              class: "m-2",
              multiple: "",
              "collapse-tags": "",
              placeholder: "请选择接收人"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(optionsPosition), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.code,
                  label: item.name,
                  value: item.code
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "消息类型",
            prop: "type"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: _unref(form).type,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _unref(form).type = $event),
              style: {
                "width": "100%"
              },
              class: "m-2",
              placeholder: "请选择消息类型"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(optionstype), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.code,
                  label: item.name,
                  value: item.code
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "消息内容",
            prop: "message"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "textarea",
              rows: 4,
              modelValue: _unref(form).message,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _unref(form).message = $event),
              placeholder: "消息内容",
              maxlength: "100",
              "show-word-limit": ""
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};