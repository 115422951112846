/* unplugin-vue-components disabled */import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1d691b3e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "image-slot"
};
import { isExternal } from "@/utils/validate";
export default {
  __name: 'index',
  props: {
    src: {
      type: String,
      required: true
    },
    width: {
      type: [Number, String],
      default: ""
    },
    height: {
      type: [Number, String],
      default: ""
    }
  },
  setup(__props) {
    const props = __props;
    const realSrc = computed(() => {
      let real_src = props.src.split(",")[0];
      if (isExternal(real_src)) {
        return real_src;
      }
      return process.env.VUE_APP_BASE_API + real_src;
    });
    const realSrcList = computed(() => {
      let real_src_list = props.src.split(",");
      let srcList = [];
      real_src_list.forEach(item => {
        if (isExternal(item)) {
          return srcList.push(item);
        }
        return srcList.push(process.env.VUE_APP_BASE_API + item);
      });
      return srcList;
    });
    const realWidth = computed(() => typeof props.width == "string" ? props.width : `${props.width}px`);
    const realHeight = computed(() => typeof props.height == "string" ? props.height : `${props.height}px`);
    return (_ctx, _cache) => {
      const _component_picture_filled = _resolveComponent("picture-filled");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_image = _resolveComponent("el-image");
      return _openBlock(), _createBlock(_component_el_image, {
        "preview-src-list": _unref(realSrcList),
        src: `${_unref(realSrc)}`,
        style: _normalizeStyle(`width:${_unref(realWidth)};height:${_unref(realHeight)};`),
        "append-to-body": "true",
        fit: "cover"
      }, {
        error: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_icon, null, {
          default: _withCtx(() => [_createVNode(_component_picture_filled)]),
          _: 1
        })])]),
        _: 1
      }, 8, ["preview-src-list", "src", "style"]);
    };
  }
};