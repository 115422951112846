/* unplugin-vue-components disabled */import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, Transition as _Transition, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6579f9ea"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
const _hoisted_2 = ["src"];
import variables from '@/assets/styles/variables.module.scss';
import logo from '@/assets/images/logo/logo.jpg';
import useSettingsStore from '@/store/modules/settings';
export default {
  __name: 'Logo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  setup(__props) {
    const title = ref('管理系统');
    const settingsStore = useSettingsStore();
    const sideTheme = computed(() => settingsStore.sideTheme);
    return (_ctx, _cache) => {
      const _component_router_link = _resolveComponent("router-link");
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["sidebar-logo-container", {
          'collapse': __props.collapse
        }]),
        style: _normalizeStyle({
          backgroundColor: _unref(sideTheme) === 'theme-dark' ? _unref(variables).menuBackground : _unref(variables).menuLightBackground
        })
      }, [_createVNode(_Transition, {
        name: "sidebarLogoFade"
      }, {
        default: _withCtx(() => [__props.collapse ? (_openBlock(), _createBlock(_component_router_link, {
          key: "collapse",
          class: "sidebar-logo-link",
          to: "/"
        }, {
          default: _withCtx(() => [_unref(logo) ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _unref(logo),
            class: "sidebar-logo"
          }, null, 8, _hoisted_1)) : (_openBlock(), _createElementBlock("h1", {
            key: 1,
            class: "sidebar-title",
            style: _normalizeStyle({
              color: _unref(sideTheme) === 'theme-dark' ? _unref(variables).logoTitleColor : _unref(variables).logoLightTitleColor
            })
          }, _toDisplayString(_unref(title)), 5))]),
          _: 1
        })) : (_openBlock(), _createBlock(_component_router_link, {
          key: "expand",
          class: "sidebar-logo-link",
          to: "/"
        }, {
          default: _withCtx(() => [_unref(logo) ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _unref(logo),
            class: "sidebar-logo"
          }, null, 8, _hoisted_2)) : _createCommentVNode("", true), _createElementVNode("h1", {
            class: "sidebar-title",
            style: _normalizeStyle({
              color: _unref(sideTheme) === 'theme-dark' ? _unref(variables).logoTitleColor : _unref(variables).logoLightTitleColor
            })
          }, _toDisplayString(_unref(title)), 5)]),
          _: 1
        }))]),
        _: 1
      })], 6);
    };
  }
};