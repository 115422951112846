/* unplugin-vue-components disabled */import __unplugin_components_2 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import __unplugin_components_1 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/DictTag/index.vue';
import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/RightToolbar/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, isRef as _isRef, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "app-container"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  class: "dialog-footer"
};
import { list, delOperlog, cleanOperlog } from "@/api/monitor/operlog";
export default {
  __name: 'index',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const {
      sys_oper_type,
      sys_common_status
    } = proxy.useDict("sys_oper_type", "sys_common_status");
    const operlogList = ref([]);
    const open = ref(false);
    const loading = ref(true);
    const showSearch = ref(true);
    const ids = ref([]);
    const single = ref(true);
    const multiple = ref(true);
    const total = ref(0);
    const title = ref("");
    const dateRange = ref([]);
    const defaultSort = ref({
      prop: "operTime",
      order: "descending"
    });
    const data = reactive({
      form: {},
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        title: undefined,
        operName: undefined,
        businessType: undefined,
        status: undefined
      }
    });
    const {
      queryParams,
      form
    } = toRefs(data);

    /** 查询登录日志 */
    function getList() {
      loading.value = true;
      list(proxy.addDateRange(queryParams.value, dateRange.value)).then(response => {
        operlogList.value = response.rows;
        total.value = response.total;
        loading.value = false;
      });
    }
    /** 操作日志类型字典翻译 */
    function typeFormat(row, column) {
      return proxy.selectDictLabel(sys_oper_type.value, row.businessType);
    }
    /** 搜索按钮操作 */
    function handleQuery() {
      queryParams.value.pageNum = 1;
      getList();
    }
    /** 重置按钮操作 */
    function resetQuery() {
      dateRange.value = [];
      proxy.resetForm("queryRef");
      proxy.$refs["operlogRef"].sort(defaultSort.value.prop, defaultSort.value.order);
      handleQuery();
    }
    /** 多选框选中数据 */
    function handleSelectionChange(selection) {
      ids.value = selection.map(item => item.operId);
      multiple.value = !selection.length;
    }
    /** 排序触发事件 */
    function handleSortChange(column, prop, order) {
      queryParams.value.orderByColumn = column.prop;
      queryParams.value.isAsc = column.order;
      getList();
    }
    /** 详细按钮操作 */
    function handleView(row) {
      open.value = true;
      form.value = row;
    }
    /** 删除按钮操作 */
    function handleDelete(row) {
      const operIds = row.operId || ids.value;
      proxy.$modal.confirm('是否确认删除日志编号为"' + operIds + '"的数据项?').then(function () {
        return delOperlog(operIds);
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    }
    /** 清空按钮操作 */
    function handleClean() {
      proxy.$modal.confirm("是否确认清空所有操作日志数据项?").then(function () {
        return cleanOperlog();
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("清空成功");
      }).catch(() => {});
    }
    /** 导出按钮操作 */
    function handleExport() {
      proxy.download("monitor/operlog/export", {
        ...queryParams.value
      }, `config_${new Date().getTime()}.xlsx`);
    }
    getList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_date_picker = _resolveComponent("el-date-picker");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_col = _resolveComponent("el-col");
      const _component_right_toolbar = __unplugin_components_0;
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_dict_tag = __unplugin_components_1;
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_2;
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_hasPermi = _resolveDirective("hasPermi");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_el_form, {
        model: _unref(queryParams),
        ref: "queryRef",
        inline: true,
        "label-width": "68px"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "系统模块",
          prop: "title"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).title,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).title = $event),
            placeholder: "请输入系统模块",
            clearable: "",
            style: {
              "width": "240px"
            },
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "操作人员",
          prop: "operName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).operName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).operName = $event),
            placeholder: "请输入操作人员",
            clearable: "",
            style: {
              "width": "240px"
            },
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "类型",
          prop: "businessType"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: _unref(queryParams).businessType,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _unref(queryParams).businessType = $event),
            placeholder: "操作类型",
            clearable: "",
            style: {
              "width": "240px"
            }
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sys_oper_type), dict => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: dict.value,
                label: dict.label,
                value: dict.value
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "状态",
          prop: "status"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: _unref(queryParams).status,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _unref(queryParams).status = $event),
            placeholder: "操作状态",
            clearable: "",
            style: {
              "width": "240px"
            }
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sys_common_status), dict => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: dict.value,
                label: dict.label,
                value: dict.value
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "操作时间",
          style: {
            "width": "308px"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_date_picker, {
            modelValue: _unref(dateRange),
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _isRef(dateRange) ? dateRange.value = $event : null),
            "value-format": "YYYY-MM-DD",
            type: "daterange",
            "range-separator": "-",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期"
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            icon: "Search",
            onClick: handleQuery
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          }), _createVNode(_component_el_button, {
            icon: "Refresh",
            onClick: resetQuery
          }, {
            default: _withCtx(() => [_createTextVNode("重置")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), [[_vShow, _unref(showSearch)]]), _createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "danger",
            plain: "",
            icon: "Delete",
            disabled: _unref(multiple),
            onClick: handleDelete
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 1
          }, 8, ["disabled"])), [[_directive_hasPermi, ['system:operlog:remove']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "danger",
            plain: "",
            icon: "Delete",
            onClick: handleClean
          }, {
            default: _withCtx(() => [_createTextVNode("清空")]),
            _: 1
          })), [[_directive_hasPermi, ['system:operlog:remove']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "warning",
            plain: "",
            icon: "Download",
            onClick: handleExport
          }, {
            default: _withCtx(() => [_createTextVNode("导出")]),
            _: 1
          })), [[_directive_hasPermi, ['system:operlog:export']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_right_toolbar, {
          showSearch: _unref(showSearch),
          "onUpdate:showSearch": _cache[5] || (_cache[5] = $event => _isRef(showSearch) ? showSearch.value = $event : null),
          onQueryTable: getList
        }, null, 8, ["showSearch"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        ref: "operlogRef",
        data: _unref(operlogList),
        onSelectionChange: handleSelectionChange,
        "default-sort": _unref(defaultSort),
        onSortChange: handleSortChange
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          type: "selection",
          width: "55",
          align: "center"
        }), _createVNode(_component_el_table_column, {
          label: "日志编号",
          align: "center",
          prop: "operId"
        }), _createVNode(_component_el_table_column, {
          label: "系统模块",
          align: "center",
          prop: "title"
        }), _createVNode(_component_el_table_column, {
          label: "操作类型",
          align: "center",
          prop: "businessType"
        }, {
          default: _withCtx(scope => [_createVNode(_component_dict_tag, {
            options: _unref(sys_oper_type),
            value: scope.row.businessType
          }, null, 8, ["options", "value"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "请求方式",
          align: "center",
          prop: "requestMethod"
        }), _createVNode(_component_el_table_column, {
          label: "操作人员",
          align: "center",
          prop: "operName",
          "show-overflow-tooltip": true,
          sortable: "custom",
          "sort-orders": ['descending', 'ascending'],
          width: "100"
        }), _createVNode(_component_el_table_column, {
          label: "主机",
          align: "center",
          prop: "operIp",
          width: "130",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "操作状态",
          align: "center",
          prop: "status"
        }, {
          default: _withCtx(scope => [_createVNode(_component_dict_tag, {
            options: _unref(sys_common_status),
            value: scope.row.status
          }, null, 8, ["options", "value"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "操作日期",
          align: "center",
          prop: "operTime",
          sortable: "custom",
          "sort-orders": ['descending', 'ascending'],
          width: "180"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(_ctx.parseTime(scope.row.operTime)), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "操作",
          align: "center",
          "class-name": "small-padding fixed-width"
        }, {
          default: _withCtx(scope => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            link: "",
            icon: "View",
            onClick: $event => handleView(scope.row, scope.index)
          }, {
            default: _withCtx(() => [_createTextVNode("详细")]),
            _: 2
          }, 1032, ["onClick"])), [[_directive_hasPermi, ['system:operlog:query']]])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data", "default-sort"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[6] || (_cache[6] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[7] || (_cache[7] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_component_el_dialog, {
        title: "操作日志详细",
        modelValue: _unref(open),
        "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _isRef(open) ? open.value = $event : null),
        width: "700px",
        "append-to-body": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_button, {
          onClick: _cache[8] || (_cache[8] = $event => open.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("关 闭")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(form),
          "label-width": "100px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "操作模块："
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(form).title) + " / " + _toDisplayString(typeFormat(_unref(form))), 1)]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "登录信息："
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(form).operName) + " / " + _toDisplayString(_unref(form).operIp) + " / " + _toDisplayString(_unref(form).operLocation), 1)]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "请求地址："
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(form).operUrl), 1)]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "请求方式："
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(form).requestMethod), 1)]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "操作方法："
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(form).method), 1)]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "请求参数："
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(form).operParam), 1)]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "返回参数："
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(form).jsonResult), 1)]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "操作状态："
              }, {
                default: _withCtx(() => [_unref(form).status === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2, "正常")) : _unref(form).status === 1 ? (_openBlock(), _createElementBlock("div", _hoisted_3, "失败")) : _createCommentVNode("", true)]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "操作时间："
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.parseTime(_unref(form).operTime)), 1)]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_unref(form).status === 1 ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                label: "异常信息："
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(form).errorMsg), 1)]),
                _: 1
              })) : _createCommentVNode("", true)]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};