/* unplugin-vue-components disabled */import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "version-container"
};
import AndroidVersion from './components/AndroidVersion.vue';
import SaasVersion from "./components/SaasVersion.vue";
export default {
  __name: 'index',
  setup(__props) {
    const activeName = ref('first');
    return (_ctx, _cache) => {
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");
      const _component_el_tabs = _resolveComponent("el-tabs");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_tabs, {
        type: "border-card",
        modelValue: _unref(activeName),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(activeName) ? activeName.value = $event : null)
      }, {
        default: _withCtx(() => [_createVNode(_component_el_tab_pane, {
          label: "安卓版本管理",
          name: "first"
        }, {
          default: _withCtx(() => [_unref(activeName) == 'first' ? (_openBlock(), _createBlock(AndroidVersion, {
            key: 0
          })) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};