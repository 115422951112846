import { login, logout, getInfo } from '@/api/login';
import { getToken, setToken, removeToken } from '@/utils/auth';
import defAva from '@/assets/images/profile.jpg';
const useUserStore = defineStore('user', {
  state: () => ({
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    permissions: []
  }),
  actions: {
    // 登录
    login(userInfo) {
      console.log(userInfo, 'userInfo');
      const accountName = userInfo.accountName.trim();
      const pwd = userInfo.pwd;
      const code = userInfo.code;
      const uuid = userInfo.uuid;
      return new Promise((resolve, reject) => {
        console.log(123);
        console.log(accountName, 'username');
        console.log(pwd, 'pwd');
        login(accountName, pwd, code, uuid).then(res => {
          setToken(res.data.token);
          this.token = res.data.token;
          resolve();
        }).catch(error => {
          reject(error);
        });

        // try {
        //   setToken('res.token')
        //   this.token = 'res.token'
        //   resolve()
        // } catch (error) {
        //   reject(error)
        // }
      });
    },

    // 获取用户信息
    getInfo() {
      return new Promise((resolve, reject) => {
        try {
          resolve({
            permissions: [],
            roles: [123],
            sysUser: {}
          });
          this.roles = [123];
        } catch (error) {
          reject({});
        }
        // getInfo().then(res => {
        //   const user = res.user
        //   const avatar = (user.avatar == "" || user.avatar == null) ? defAva : import.meta.env.VITE_APP_BASE_API + user.avatar;

        //   if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
        //     this.roles = res.roles
        //     this.permissions = res.permissions
        //   } else {
        //     this.roles = ['ROLE_DEFAULT']
        //   }
        //   this.name = user.userName
        //   this.avatar = avatar;
        //   resolve(res)
        // }).catch(error => {
        //   reject(error)
        // })
      });
    },

    // 退出系统
    logOut() {
      return new Promise((resolve, reject) => {
        logout(this.token).then(() => {
          this.token = '';
          this.roles = [];
          this.permissions = [];
          removeToken();
          resolve();
        }).catch(error => {
          reject(error);
        });
        // removeToken()
        // resolve()
      });
    }
  }
});

export default useUserStore;