/* unplugin-vue-components disabled */import __unplugin_components_2 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import __unplugin_components_1 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/DictTag/index.vue';
import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/RightToolbar/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, isRef as _isRef, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "app-container"
};
import selectUser from "./selectUser";
import { allocatedUserList, authUserCancel, authUserCancelAll } from "@/api/system/role";
export default {
  __name: 'authUser',
  setup(__props) {
    const route = useRoute();
    const {
      proxy
    } = getCurrentInstance();
    const {
      sys_normal_disable
    } = proxy.useDict("sys_normal_disable");
    const userList = ref([]);
    const loading = ref(true);
    const showSearch = ref(true);
    const multiple = ref(true);
    const total = ref(0);
    const userIds = ref([]);
    const queryParams = reactive({
      pageNum: 1,
      pageSize: 10,
      roleId: route.params.roleId,
      userName: undefined,
      phonenumber: undefined
    });

    /** 查询授权用户列表 */
    function getList() {
      loading.value = true;
      allocatedUserList(queryParams).then(response => {
        userList.value = response.rows;
        total.value = response.total;
        loading.value = false;
      });
    }
    // 返回按钮
    function handleClose() {
      const obj = {
        path: "/system/role"
      };
      proxy.$tab.closeOpenPage(obj);
    }
    /** 搜索按钮操作 */
    function handleQuery() {
      queryParams.pageNum = 1;
      getList();
    }
    /** 重置按钮操作 */
    function resetQuery() {
      proxy.resetForm("queryRef");
      handleQuery();
    }
    // 多选框选中数据
    function handleSelectionChange(selection) {
      userIds.value = selection.map(item => item.userId);
      multiple.value = !selection.length;
    }
    /** 打开授权用户表弹窗 */
    function openSelectUser() {
      proxy.$refs["selectRef"].show();
    }
    /** 取消授权按钮操作 */
    function cancelAuthUser(row) {
      proxy.$modal.confirm('确认要取消该用户"' + row.userName + '"角色吗？').then(function () {
        return authUserCancel({
          userId: row.userId,
          roleId: queryParams.roleId
        });
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("取消授权成功");
      }).catch(() => {});
    }
    /** 批量取消授权按钮操作 */
    function cancelAuthUserAll(row) {
      const roleId = queryParams.roleId;
      const uIds = userIds.value.join(",");
      proxy.$modal.confirm("是否取消选中用户授权数据项?").then(function () {
        return authUserCancelAll({
          roleId: roleId,
          userIds: uIds
        });
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("取消授权成功");
      }).catch(() => {});
    }
    getList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_col = _resolveComponent("el-col");
      const _component_right_toolbar = __unplugin_components_0;
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_dict_tag = __unplugin_components_1;
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_2;
      const _directive_hasPermi = _resolveDirective("hasPermi");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_el_form, {
        model: _unref(queryParams),
        ref: "queryRef",
        inline: true
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "用户名称",
          prop: "userName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).userName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).userName = $event),
            placeholder: "请输入用户名称",
            clearable: "",
            style: {
              "width": "240px"
            },
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "手机号码",
          prop: "phonenumber"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).phonenumber,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).phonenumber = $event),
            placeholder: "请输入手机号码",
            clearable: "",
            style: {
              "width": "240px"
            },
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            icon: "Search",
            onClick: handleQuery
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          }), _createVNode(_component_el_button, {
            icon: "Refresh",
            onClick: resetQuery
          }, {
            default: _withCtx(() => [_createTextVNode("重置")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), [[_vShow, _unref(showSearch)]]), _createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            plain: "",
            icon: "Plus",
            onClick: openSelectUser
          }, {
            default: _withCtx(() => [_createTextVNode("添加用户")]),
            _: 1
          })), [[_directive_hasPermi, ['system:role:add']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "danger",
            plain: "",
            icon: "CircleClose",
            disabled: _unref(multiple),
            onClick: cancelAuthUserAll
          }, {
            default: _withCtx(() => [_createTextVNode("批量取消授权")]),
            _: 1
          }, 8, ["disabled"])), [[_directive_hasPermi, ['system:role:remove']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "warning",
            plain: "",
            icon: "Close",
            onClick: handleClose
          }, {
            default: _withCtx(() => [_createTextVNode("关闭")]),
            _: 1
          })]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_right_toolbar, {
          showSearch: _unref(showSearch),
          "onUpdate:showSearch": _cache[2] || (_cache[2] = $event => _isRef(showSearch) ? showSearch.value = $event : null),
          onQueryTable: getList
        }, null, 8, ["showSearch"])]),
        _: 1
      }), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: _unref(userList),
        onSelectionChange: handleSelectionChange
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          type: "selection",
          width: "55",
          align: "center"
        }), _createVNode(_component_el_table_column, {
          label: "用户名称",
          prop: "userName",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "用户昵称",
          prop: "nickName",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "邮箱",
          prop: "email",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "手机",
          prop: "phonenumber",
          "show-overflow-tooltip": true
        }), _createVNode(_component_el_table_column, {
          label: "状态",
          align: "center",
          prop: "status"
        }, {
          default: _withCtx(scope => [_createVNode(_component_dict_tag, {
            options: _unref(sys_normal_disable),
            value: scope.row.status
          }, null, 8, ["options", "value"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "创建时间",
          align: "center",
          prop: "createTime",
          width: "180"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(_ctx.parseTime(scope.row.createTime)), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "操作",
          align: "center",
          "class-name": "small-padding fixed-width"
        }, {
          default: _withCtx(scope => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            link: "",
            icon: "CircleClose",
            onClick: $event => cancelAuthUser(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("取消授权")]),
            _: 2
          }, 1032, ["onClick"])), [[_directive_hasPermi, ['system:role:remove']]])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, _unref(loading)]]), _withDirectives(_createVNode(_component_pagination, {
        total: _unref(total),
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[3] || (_cache[3] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[4] || (_cache[4] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, _unref(total) > 0]]), _createVNode(_unref(selectUser), {
        ref: "selectRef",
        roleId: _unref(queryParams).roleId,
        onOk: handleQuery
      }, null, 8, ["roleId"])]);
    };
  }
};