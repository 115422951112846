/* unplugin-vue-components disabled */import __unplugin_components_1 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/ImageUpload/index.vue';
import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/Pagination/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b1eb756a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "mb8"
};
const _hoisted_2 = {
  class: "dialog-footer"
};
import { userTagList, queryAppList, userTagAdd, userTagFind, userTagUpdata, userTagDelete } from "@/api/employeeManagement/index";
import { reactive, ref } from "vue";
export default {
  __name: 'memberTag',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const dialogFormVisible = ref(false);
    const loading = ref(false);
    const formRef = ref();
    const btnLoading = ref(false);
    const total = ref(0);
    const title = ref("新增会员标签");
    const formType = ref(null);
    const memAppInfoList = ref([]);
    const data = reactive({
      tableData: [],
      ruleForm: {
        type: "personality_traits",
        //类型 personality_traits-性格特点 interest-兴趣爱好
        icon: "",
        //图标
        name: ""
      },
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: null,
        type: null
      },
      userTagType: [{
        value: "性格特点",
        key: "personality_traits"
      }, {
        value: "兴趣爱好",
        key: "interest"
      }],
      // 表单校验
      ruleFormRule: {
        type: [{
          required: true,
          message: "会员标签类型",
          trigger: "blur"
        }],
        name: [{
          required: true,
          message: "请输入会员标签名称",
          trigger: "blur"
        }],
        icon: [{
          required: true,
          message: "请选择图片",
          trigger: ["blur"]
        }]
      }
    });
    const {
      tableData,
      queryParams,
      ruleFormRule,
      ruleForm,
      userTagType
    } = toRefs(data);
    const handleAdd = () => {
      title.value = "新增会员标签";
      formType.value = "add";
      dialogFormVisible.value = true;
      ruleForm.value.detail = "";
      reset();
    };
    const handleDelete = rows => {
      const {
        code
      } = rows;
      proxy.$modal.confirm("是否确认删除?").then(function () {
        return userTagDelete({
          code
        });
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(err => {
        console.log(err);
      });
    };
    // 去修改
    const handleUpdate = rows => {
      title.value = "编辑会员标签";
      formType.value = "updata";
      ruleForm.value.code = rows.code;
      getSingleVal(rows.code);
    };
    // 查询单个会员标签信息详情
    const getSingleVal = code => {
      userTagFind({
        code
      }).then(res => {
        ruleForm.value = Object.assign({}, res.data);
        dialogFormVisible.value = true;
        getShopCode("init");
      });
    };
    const cancel = () => {
      dialogFormVisible.value = false;
      reset();
    };
    const handleQuery = () => {
      queryParams.value.pageNum = 1;
      getList();
    };
    const submit = async formEl => {
      if (!formEl) return;
      await formEl.validate(valid => {
        if (valid) {
          addShop();
        }
      });
    };
    const changeRa = () => {
      ruleForm.value.appCode = null;
      ruleForm.value.appName = null;
    };

    // 新增会员标签
    const addShop = () => {
      btnLoading.value = true;
      let api = userTagAdd;
      if (formType.value == "updata") {
        api = userTagUpdata;
      }
      api(ruleForm.value).then(() => {
        dialogFormVisible.value = false;
        getList();
        reset();
        proxy.$modal.msgSuccess("操作成功");
        btnLoading.value = false;
      }).catch(() => {
        btnLoading.value = false;
      });
    };
    // 应用list
    const getAppList = () => {
      queryAppList({}).then(res => {
        memAppInfoList.value = res.data;
      });
    };

    // 关闭弹窗
    const closeDialog = () => {
      dialogFormVisible.value = false;
      reset();
    };

    // 查询会员标签列表
    const getList = () => {
      loading.value = true;
      userTagList(queryParams.value).then(res => {
        try {
          tableData.value = res.data;
          total.value = res.total;
        } catch (error) {}
        loading.value = false;
      });
    };

    // 重置表单
    const reset = () => {
      formRef.value?.resetFields();
      ruleForm.value = {
        type: "personality_traits",
        name: "",
        icon: "" //图标
      };
    };

    getList();
    getAppList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_pagination = __unplugin_components_0;
      const _component_el_radio = _resolveComponent("el-radio");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_ImageUpload = __unplugin_components_1;
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, {
        gutter: 2
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: _unref(queryParams),
          ref: "queryForm",
          inline: true
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "会员标签名称"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: _unref(queryParams).name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).name = $event),
              placeholder: "会员标签名称",
              clearable: ""
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "会员标签类型"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              clearable: "",
              modelValue: _unref(queryParams).type,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).type = $event),
              placeholder: "请选择"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(userTagType), item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.key,
                  label: item.value,
                  value: item.key
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: "Search",
              plain: "",
              type: "primary",
              onClick: handleQuery
            }, {
              default: _withCtx(() => [_createTextVNode("搜索")]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_createVNode(_component_el_button, {
        icon: "Plus",
        plain: "",
        type: "primary",
        onClick: handleAdd
      }, {
        default: _withCtx(() => [_createTextVNode("新增会员标签")]),
        _: 1
      })])]), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        "max-height": 650,
        data: _unref(tableData),
        style: {
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "icon",
          align: "center",
          label: "会员标签图片",
          width: ""
        }, {
          default: _withCtx(scope => [scope.row.icon ? (_openBlock(), _createBlock(_component_el_image, {
            key: 0,
            style: {
              "width": "100px",
              "height": "100px"
            },
            src: scope.row.icon,
            "zoom-rate": 1.2,
            "initial-index": 1,
            fit: "cover"
          }, null, 8, ["src", "zoom-rate"])) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "会员标签名称",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "name"
        }), _createVNode(_component_el_table_column, {
          label: "会员标签类型",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "type"
        }, {
          default: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.row.type == "personality_traits" ? "性格特点" : "兴趣爱好"), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "创建时间",
          align: "center",
          "show-overflow-tooltip": "",
          prop: "createdTime"
        }), _createVNode(_component_el_table_column, {
          fixed: "right",
          align: "center",
          label: "操作",
          width: "150"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleUpdate(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 2
          }, 1032, ["onClick"]), _createVNode(_component_el_button, {
            link: "",
            type: "primary",
            size: "small",
            onClick: $event => handleDelete(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, loading.value]]), _withDirectives(_createVNode(_component_pagination, {
        total: total.value,
        page: _unref(queryParams).pageNum,
        "onUpdate:page": _cache[2] || (_cache[2] = $event => _unref(queryParams).pageNum = $event),
        limit: _unref(queryParams).pageSize,
        "onUpdate:limit": _cache[3] || (_cache[3] = $event => _unref(queryParams).pageSize = $event),
        onPagination: getList
      }, null, 8, ["total", "page", "limit"]), [[_vShow, total.value > 0]]), _createVNode(_component_el_dialog, {
        modelValue: dialogFormVisible.value,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => dialogFormVisible.value = $event),
        "before-close": closeDialog,
        title: title.value,
        width: "1000px"
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_2, [_createVNode(_component_el_button, {
          onClick: _cache[7] || (_cache[7] = $event => cancel(formRef.value))
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          loading: btnLoading.value,
          onClick: _cache[8] || (_cache[8] = $event => submit(formRef.value))
        }, {
          default: _withCtx(() => [_createTextVNode("提交")]),
          _: 1
        }, 8, ["loading"])])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "formRef",
          ref: formRef,
          model: _unref(ruleForm),
          rules: _unref(ruleFormRule),
          "label-position": "top"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "会员标签类型",
            prop: "type"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_radio_group, {
              modelValue: _unref(ruleForm).type,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _unref(ruleForm).type = $event),
              onChange: changeRa
            }, {
              default: _withCtx(() => [_createVNode(_component_el_radio, {
                label: "personality_traits"
              }, {
                default: _withCtx(() => [_createTextVNode("性格特点")]),
                _: 1
              }), _createVNode(_component_el_radio, {
                label: "interest"
              }, {
                default: _withCtx(() => [_createTextVNode("兴趣爱好")]),
                _: 1
              })]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "会员标签名称",
            prop: "name"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              type: "text",
              placeholder: "请输入会员标签名称",
              modelValue: _unref(ruleForm).name,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _unref(ruleForm).name = $event),
              maxlength: "20"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _unref(ruleForm).type == 'interest' ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "会员标签图片",
            prop: "icon"
          }, {
            default: _withCtx(() => [_createVNode(_component_ImageUpload, {
              limit: 1,
              modelValue: _unref(ruleForm).icon,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _unref(ruleForm).icon = $event),
              fileSize: 5
            }, null, 8, ["modelValue"])]),
            _: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};