import request from '@/utils/request'

// 查询安卓版本列表
export function getAndroidVersions(data) {
  return request({
    url: '/yg-plat-manage/platAndroidVersion/queryPlatAndroidVersionPage',
    method: 'post',
    data: data,
    headers:{
      repeatSubmit:false
    }
  })
}

// 查询类型
export function queryAndroidType(data) {
  return request({
    url: '/yg-plat-manage/platAndroidVersion/queryAndroidType',
    method: 'post',
    data: data
  })
}

// 查询是否强制更新
export function queryAndroidVersionIsForceUpdate(data) {
  return request({
    url: '/yg-plat-manage/platAndroidVersion/queryAndroidVersionIsForceUpdate',
    method: 'post',
    data: data
  })
}


// 查询发布状态
export function queryAndroidVersionPublishStatus(data) {
  return request({
    url: '/yg-plat-manage/platAndroidVersion/queryAndroidVersionPublishStatus',
    method: 'post',
    data: data
  })
}


// 新增安卓版本
export function addAndroidVersion(data) {
  return request({
    url: '/yg-plat-manage/platAndroidVersion/addPlatAndroidVersion',
    method: 'post',
    data
  })
}

// 编辑安卓版本
export function editAndroidVersion(data) {
  return request({
    url: '/yg-plat-manage/platAndroidVersion/updatePlatAndroidVersionById',
    method: 'post',
    data
  })
}

// 删除安卓版本
export function delAndroidVersion(data) {
  return request({
    url: '/yg-plat-manage/platAndroidVersion/delPlatAndroidVersionById',
    method: 'post',
    params:data
  })
}