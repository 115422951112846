/* unplugin-vue-components disabled */import __unplugin_components_1 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/DictTag/index.vue';
import __unplugin_components_0 from 'E:/new-admin/3.0/ruoyi-vue3-cli/src/components/RightToolbar/index.vue';
import { unref as _unref, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, isRef as _isRef, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "app-container"
};
const _hoisted_2 = {
  class: "dialog-footer"
};
import { listDept, getDept, delDept, addDept, updateDept, listDeptExcludeChild } from "@/api/system/dept";
export default {
  __name: 'index',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const {
      sys_normal_disable
    } = proxy.useDict("sys_normal_disable");
    const deptList = ref([]);
    const open = ref(false);
    const loading = ref(true);
    const showSearch = ref(true);
    const title = ref("");
    const deptOptions = ref([]);
    const isExpandAll = ref(true);
    const refreshTable = ref(true);
    const data = reactive({
      form: {},
      queryParams: {
        deptName: undefined,
        status: undefined
      },
      rules: {
        parentId: [{
          required: true,
          message: "上级部门不能为空",
          trigger: "blur"
        }],
        deptName: [{
          required: true,
          message: "部门名称不能为空",
          trigger: "blur"
        }],
        orderNum: [{
          required: true,
          message: "显示排序不能为空",
          trigger: "blur"
        }],
        email: [{
          type: "email",
          message: "请输入正确的邮箱地址",
          trigger: ["blur", "change"]
        }],
        phone: [{
          pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
          message: "请输入正确的手机号码",
          trigger: "blur"
        }]
      }
    });
    const {
      queryParams,
      form,
      rules
    } = toRefs(data);

    /** 查询部门列表 */
    function getList() {
      loading.value = true;
      listDept(queryParams.value).then(response => {
        deptList.value = proxy.handleTree(response.data, "deptId");
        loading.value = false;
      });
    }
    /** 取消按钮 */
    function cancel() {
      open.value = false;
      reset();
    }
    /** 表单重置 */
    function reset() {
      form.value = {
        deptId: undefined,
        parentId: undefined,
        deptName: undefined,
        orderNum: 0,
        leader: undefined,
        phone: undefined,
        email: undefined,
        status: "0"
      };
      proxy.resetForm("deptRef");
    }
    /** 搜索按钮操作 */
    function handleQuery() {
      getList();
    }
    /** 重置按钮操作 */
    function resetQuery() {
      proxy.resetForm("queryRef");
      handleQuery();
    }
    /** 新增按钮操作 */
    function handleAdd(row) {
      reset();
      listDept().then(response => {
        deptOptions.value = proxy.handleTree(response.data, "deptId");
      });
      if (row != undefined) {
        form.value.parentId = row.deptId;
      }
      open.value = true;
      title.value = "添加部门";
    }
    /** 展开/折叠操作 */
    function toggleExpandAll() {
      refreshTable.value = false;
      isExpandAll.value = !isExpandAll.value;
      nextTick(() => {
        refreshTable.value = true;
      });
    }
    /** 修改按钮操作 */
    function handleUpdate(row) {
      reset();
      listDeptExcludeChild(row.deptId).then(response => {
        deptOptions.value = proxy.handleTree(response.data, "deptId");
      });
      getDept(row.deptId).then(response => {
        form.value = response.data;
        open.value = true;
        title.value = "修改部门";
      });
    }
    /** 提交按钮 */
    function submitForm() {
      proxy.$refs["deptRef"].validate(valid => {
        if (valid) {
          if (form.value.deptId != undefined) {
            updateDept(form.value).then(response => {
              proxy.$modal.msgSuccess("修改成功");
              open.value = false;
              getList();
            });
          } else {
            addDept(form.value).then(response => {
              proxy.$modal.msgSuccess("新增成功");
              open.value = false;
              getList();
            });
          }
        }
      });
    }
    /** 删除按钮操作 */
    function handleDelete(row) {
      proxy.$modal.confirm('是否确认删除名称为"' + row.deptName + '"的数据项?').then(function () {
        return delDept(row.deptId);
      }).then(() => {
        getList();
        proxy.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    }
    getList();
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      const _component_el_col = _resolveComponent("el-col");
      const _component_right_toolbar = __unplugin_components_0;
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_dict_tag = __unplugin_components_1;
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_tree_select = _resolveComponent("el-tree-select");
      const _component_el_input_number = _resolveComponent("el-input-number");
      const _component_el_radio = _resolveComponent("el-radio");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_hasPermi = _resolveDirective("hasPermi");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_el_form, {
        model: _unref(queryParams),
        ref: "queryRef",
        inline: true
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: "部门名称",
          prop: "deptName"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: _unref(queryParams).deptName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _unref(queryParams).deptName = $event),
            placeholder: "请输入部门名称",
            clearable: "",
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          label: "状态",
          prop: "status"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: _unref(queryParams).status,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _unref(queryParams).status = $event),
            placeholder: "部门状态",
            clearable: ""
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sys_normal_disable), dict => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: dict.value,
                label: dict.label,
                value: dict.value
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            icon: "Search",
            onClick: handleQuery
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          }), _createVNode(_component_el_button, {
            icon: "Refresh",
            onClick: resetQuery
          }, {
            default: _withCtx(() => [_createTextVNode("重置")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }, 8, ["model"]), [[_vShow, _unref(showSearch)]]), _createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            plain: "",
            icon: "Plus",
            onClick: handleAdd
          }, {
            default: _withCtx(() => [_createTextVNode("新增")]),
            _: 1
          })), [[_directive_hasPermi, ['system:dept:add']]])]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_el_col, {
          span: 1.5
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "info",
            plain: "",
            icon: "Sort",
            onClick: toggleExpandAll
          }, {
            default: _withCtx(() => [_createTextVNode("展开/折叠")]),
            _: 1
          })]),
          _: 1
        }, 8, ["span"]), _createVNode(_component_right_toolbar, {
          showSearch: _unref(showSearch),
          "onUpdate:showSearch": _cache[2] || (_cache[2] = $event => _isRef(showSearch) ? showSearch.value = $event : null),
          onQueryTable: getList
        }, null, 8, ["showSearch"])]),
        _: 1
      }), _unref(refreshTable) ? _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        key: 0,
        data: _unref(deptList),
        "row-key": "deptId",
        "default-expand-all": _unref(isExpandAll),
        "tree-props": {
          children: 'children',
          hasChildren: 'hasChildren'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          prop: "deptName",
          label: "部门名称",
          width: "260"
        }), _createVNode(_component_el_table_column, {
          prop: "orderNum",
          label: "排序",
          width: "200"
        }), _createVNode(_component_el_table_column, {
          prop: "status",
          label: "状态",
          width: "100"
        }, {
          default: _withCtx(scope => [_createVNode(_component_dict_tag, {
            options: _unref(sys_normal_disable),
            value: scope.row.status
          }, null, 8, ["options", "value"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "创建时间",
          align: "center",
          prop: "createTime",
          width: "200"
        }, {
          default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(_ctx.parseTime(scope.row.createTime)), 1)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "操作",
          align: "center",
          "class-name": "small-padding fixed-width"
        }, {
          default: _withCtx(scope => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            link: "",
            icon: "Edit",
            onClick: $event => handleUpdate(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("修改")]),
            _: 2
          }, 1032, ["onClick"])), [[_directive_hasPermi, ['system:dept:edit']]]), _withDirectives((_openBlock(), _createBlock(_component_el_button, {
            type: "primary",
            link: "",
            icon: "Plus",
            onClick: $event => handleAdd(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("新增")]),
            _: 2
          }, 1032, ["onClick"])), [[_directive_hasPermi, ['system:dept:add']]]), scope.row.parentId != 0 ? _withDirectives((_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            type: "primary",
            link: "",
            icon: "Delete",
            onClick: $event => handleDelete(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("删除")]),
            _: 2
          }, 1032, ["onClick"])), [[_directive_hasPermi, ['system:dept:remove']]]) : _createCommentVNode("", true)]),
          _: 1
        })]),
        _: 1
      }, 8, ["data", "default-expand-all"])), [[_directive_loading, _unref(loading)]]) : _createCommentVNode("", true), _createVNode(_component_el_dialog, {
        title: _unref(title),
        modelValue: _unref(open),
        "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => _isRef(open) ? open.value = $event : null),
        width: "600px",
        "append-to-body": ""
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: submitForm
        }, {
          default: _withCtx(() => [_createTextVNode("确 定")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: cancel
        }, {
          default: _withCtx(() => [_createTextVNode("取 消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref: "deptRef",
          model: _unref(form),
          rules: _unref(rules),
          "label-width": "80px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_row, null, {
            default: _withCtx(() => [_unref(form).parentId !== 0 ? (_openBlock(), _createBlock(_component_el_col, {
              key: 0,
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "上级部门",
                prop: "parentId"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_tree_select, {
                  modelValue: _unref(form).parentId,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _unref(form).parentId = $event),
                  data: _unref(deptOptions),
                  props: {
                    value: 'deptId',
                    label: 'deptName',
                    children: 'children'
                  },
                  "value-key": "deptId",
                  placeholder: "选择上级部门",
                  "check-strictly": ""
                }, null, 8, ["modelValue", "data"])]),
                _: 1
              })]),
              _: 1
            })) : _createCommentVNode("", true), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "部门名称",
                prop: "deptName"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: _unref(form).deptName,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _unref(form).deptName = $event),
                  placeholder: "请输入部门名称"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "显示排序",
                prop: "orderNum"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input_number, {
                  modelValue: _unref(form).orderNum,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _unref(form).orderNum = $event),
                  "controls-position": "right",
                  min: 0
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "负责人",
                prop: "leader"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: _unref(form).leader,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _unref(form).leader = $event),
                  placeholder: "请输入负责人",
                  maxlength: "20"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "联系电话",
                prop: "phone"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: _unref(form).phone,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _unref(form).phone = $event),
                  placeholder: "请输入联系电话",
                  maxlength: "11"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "邮箱",
                prop: "email"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: _unref(form).email,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _unref(form).email = $event),
                  placeholder: "请输入邮箱",
                  maxlength: "50"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "部门状态"
              }, {
                default: _withCtx(() => [_createVNode(_component_el_radio_group, {
                  modelValue: _unref(form).status,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => _unref(form).status = $event)
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sys_normal_disable), dict => {
                    return _openBlock(), _createBlock(_component_el_radio, {
                      key: dict.value,
                      label: dict.value
                    }, {
                      default: _withCtx(() => [_createTextVNode(_toDisplayString(dict.label), 1)]),
                      _: 2
                    }, 1032, ["label"]);
                  }), 128))]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["title", "modelValue"])]);
    };
  }
};